import React, {useEffect, useState} from "react";
import GridTable from "./UI/base/GridTable/components/GridTable";
import {config} from "../config";
import {axiosRun} from "../utils/axiosWrap";

export const GroupsList = ()=>{

    const [isLoad,setIsLoad] = useState(true);
    const [facultiesGroupsList, setFacultiesGroupsList] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnsForQuick, setColumnsForQuick] = useState([]);
    useEffect( ()=>{
        const loadAllReq = async ()=> {
            const _facultiesGroupsList = await axiosRun(config.apiLinks.getFacultiesgroupList, 'GET');
            setFacultiesGroupsList(_facultiesGroupsList);
            setIsLoad(false);
        };
        if (isLoad===true){
            console.log("1", isLoad);
            loadAllReq();
        }
    }, [isLoad])

    useEffect( () => {
        if (!isLoad && Object.keys(facultiesGroupsList).length>0 ){
            const _columns = [
                { id: 'name', numeric: false, disablePadding: false, label: 'Назва групи', isLinkItem: true },
                { id: 'faculty.name', numeric: false, disablePadding: false, label: 'Факультет', isLinkItem: true },
                { id: 'speciality.name', numeric: false, disablePadding: false, label: 'Спеціальність', isLinkItem: true },
                { id: 'specialization.name', numeric: false, disablePadding: false, label: 'Спеціалізація', isLinkItem: true },
            ];
            const _columnsFormQuick = [];
            setColumns(_columns);
            setColumnsForQuick(_columnsFormQuick)
        }
    }, [isLoad,facultiesGroupsList])

    const  triggerLinks = {
        createLinkSave: config.apiLinks.createTemplate,
        editLinkSave: config.apiLinks.editTemplatesItem,
        linkItem: '/administration/group/list/%itemId%',
        deleteLink: 'products/remove',
        redirectAfterAdd: '/administration/group/list/%itemId%'
    }

    return <>
        {!isLoad&&<GridTable
            linkGetData={config.apiLinks.getGroupsList}
            columns={columns}
            triggerLinks={triggerLinks}
            columnsFormQuick={columnsForQuick}
            tableHeaderText={"Групи"}
            rows4page={10}
            addLink={"/administration/groups/new"}
            isAddLink={true}
            quickViewNotAllowed={true}
            filtersList={[
                { name: "faculty_sp_spec_group", label: "Факультет/спеціальність/спеціалізація/група", type: 'autocomplete', options: facultiesGroupsList }
            ]}
        />}
        </>

}