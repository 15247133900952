import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {shallowEqual, useSelector} from "react-redux";

const MyComponent = ({ userId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [emailType, setEmailType] = useState('welcome'); // Default radio button value
    const [emailText, setEmailText] = useState('');
    const [emailSendResult, setEmailSendResult] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const roles = useSelector((state) => state.users.user, shallowEqual).user.roles;


    const handleRadioChange = event => {
        setEmailType(event.target.value);
    };

    const handleTextareaChange = (event, editor) => {
        const data = editor.getData();
        setEmailText(data);
    };

    const handleClick = () => {
        setIsLoading(true);
        const __run = async ()=>{
            const files = {};
            selectedFiles.forEach((file, index) => {
                files[file.name] = file;
            });
            const obj = Object.assign({}, {files:selectedFiles}, {userId,emailType, emailText});
            const link = roles !== 'ADMIN_GROUP_LEAD'?config.apiLinks.sendEmailToUser:config.apiLinks.sendEmailToUserGroupLead;
            const result = await axiosRun(link, "POST", obj);
            if (result.isSend){
                setIsLoading(false);
                setDialogContent('Відправлено'); // Set the success message
                setDialogOpen(true); // Open the dialog
            }else{
                setIsLoading(false);
                console.log("result ", result);
                setDialogContent(`невдало: помилка: ${JSON.stringify(result)}`);
                setDialogOpen(true);
            }
            setSelectedFiles([]);
            setEmailText('');
            console.log("document.getElementsByName('file')", document.getElementsByName('file'));
            document.getElementsByName('file')[0].value='';
        }
        __run();
    };

    const handleFileChange = event => {
        const files = event.target.files;
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    };

    const handleFileRemove = fileIndex => {
        setSelectedFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles.splice(fileIndex, 1);
            return updatedFiles;
        });
    };

    const renderSelectedFiles = () => {
        return selectedFiles.map((file, index) => (
            <Box key={index} display="flex" alignItems="center"  mt={1}>
                <span>{file.name}</span>
                <Button
                    variant="contained"
                    color="error"
                    size="small"
                    
                    onClick={() => handleFileRemove(index)}
                    sx={{ml: 3}}
                    title='Видалити'
                >
                    <DeleteIcon fontSize='small' />
                </Button>
            </Box>
        ));
    };


    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <RadioGroup value={emailType} onChange={handleRadioChange}>
                <FormControlLabel value="welcome" control={<Radio />} label="Welcome Email" />
                <FormControlLabel value="free-text" control={<Radio />} label="Free Text Email" />
            </RadioGroup>

            {emailType === 'free-text' && (
                <CKEditor editor={DecoupledEditor}
                          onReady={ editor => {
                              console.log( 'Editor is ready to use!', editor );

                              // Insert the toolbar before the editable area.
                              editor.ui.getEditableElement().parentElement.insertBefore(
                                  editor.ui.view.toolbar.element,
                                  editor.ui.getEditableElement()
                              );

                              this.editor = editor;
                          } }
                          data={emailText} onChange={handleTextareaChange} />
            )}
            {emailType === 'free-text' &&<Box sx={{mt: 3}}>
            <Button variant="outlined" component="label">
                        <input hidden type='file' multiple onChange={handleFileChange} />
                        <AttachFileIcon sx={{ mr: 2 }} />
                        Додати файл
                    </Button>
                    <Box mt={2}>

                    {renderSelectedFiles()}
                </Box> 
                {/* <Input name={'file'} type="file" multiple onChange={handleFileChange} />
                */}

            </Box>}
            <Box component="div" sx={{mt: 3,display: 'flex'}}>
                <Button variant="contained" sx={{ minWidth: '300px'}} onClick={handleClick} endIcon={<SendIcon />} disabled={isLoading}>
                    {isLoading ? <CircularProgress size={24} /> : 'SEND'}
                </Button>
            </Box>
            
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Результат</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogContent}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MyComponent;
