import React, {useState, useEffect} from 'react';
import {getPropByString} from "../../helper/utils";

export const useFormItem = ({formData,keyForm,itemWithData,itemColumn,lockedProps, validationError}) => {

    const isLazyEntityList = itemColumn.type === 'entityList' && !itemColumn.trigger;

    const pushingFormData = ( name, value )=>{
        let data = {};
        if (formData.current[keyForm]!==undefined){
            data[keyForm] = Object.assign({}, formData.current[keyForm], {[name]: value})
            data = Object.assign({},formData, data);
        }else{
            data[keyForm] = Object.assign({}, {[name]:value});
            data = Object.assign( {}, formData, data );
        }
        formData.current = data;
    }



    let data = null;

    console.log("locked props ", lockedProps, itemColumn.entityName);
    if ( lockedProps !== undefined && lockedProps.entityName === itemColumn.entityName ){
        data = lockedProps.entityValue;
        pushingFormData(lockedProps.entityName,data);
    }else if (itemColumn.type=='bool') {
        data = itemWithData[itemColumn.id];
    }else if ( itemColumn.type=='entityListManyValues' ){
        data = itemWithData[itemColumn.id];
    }else{
        data = !isLazyEntityList?(Object.keys(itemWithData).length>0?getPropByString(itemWithData,itemColumn):''):itemWithData[itemColumn.id];
    }

    let dataToSet = '';
    if ( formData?.current[itemWithData.id] ){
        console.log("formData current ", formData.current);
        dataToSet = formData?.current[itemWithData.id][itemColumn.id]!==undefined?formData.current[itemWithData.id][itemColumn.id]:data;
    }else{
        dataToSet = data;
    }
    const [value, setValue] = useState(dataToSet);

    const externalFormSetter = (name,data)=>{
        setValue(data);
        pushingFormData(name,data)
    }

    const onChangeCheckBox = (event) =>{
        setValue(event.target.checked);
        pushingFormData(event.target.name, event.target.checked);
    }

    const onChangeSelect = (event)=>{
        setValue(event.target.value);
        pushingFormData(event.target.name,event.target.value);
    }
    const onChangeRadio = (event)=>{
        setValue(parseInt(event.target.value));
        pushingFormData(event.target.name,event.target.value);
    }
    const onChangeText = (event) =>{
        setValue(event.target.value);
        pushingFormData(event.target.name,event.target.value);
    }

    const onChangePhone = (propname,phone)=>{
        setValue(phone);
        pushingFormData(propname, phone);
    }

    const onChangeDate = (propname,value)=>{
        const date = new Date(value);
        console.log("value date", date);
        setValue(value);
        pushingFormData(propname,date);
    }

    const onChooseMultipleSelect = (propname,value)=>{
        console.log("propname", propname,value);
        setValue(value);
        pushingFormData(propname, value);
    }

    const onChangeCK = (propname,value) =>{
        setValue(value);
        pushingFormData(propname,value);
    }

    return {onChangeText,onChangeSelect,onChangeRadio,onChangeCheckBox,value,onChangePhone,onChangeDate,onChooseMultipleSelect,onChangeCK,externalFormSetter}
}