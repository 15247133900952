import React, {useEffect, useState} from "react";
import { makeStyles  } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import fileDownload from 'js-file-download';
import Axios from 'axios';

const useStyles = makeStyles((theme: Theme) =>
    ({
        messageRow: {
            display: "flex"
        },
        messageRowRight: {
            display: "flex",
            justifyContent: "flex-end"
        },
        messageBlue: {
            position: "relative",
            marginLeft: "20px",
            marginBottom: "10px",
            padding: "10px",
            backgroundColor: "#A8DDFD",
            //height: "50px",
            textAlign: "left",
            font: "400 .9em 'Open Sans', sans-serif",
            border: "1px solid #97C6E3",
            borderRadius: "10px",
            "&:after": {
                content: "''",
                position: "absolute",
                width: "0",
                height: "0",
                borderTop: "15px solid #A8DDFD",
                borderLeft: "15px solid transparent",
                borderRight: "15px solid transparent",
                top: "0",
                left: "-15px"
            },
            "&:before": {
                content: "''",
                position: "absolute",
                width: "0",
                height: "0",
                borderTop: "17px solid #97C6E3",
                borderLeft: "16px solid transparent",
                borderRight: "16px solid transparent",
                top: "-1px",
                left: "-17px"
            }
        },
        messageOrange: {
            position: "relative",
            marginRight: "20px",
            marginBottom: "10px",
            padding: "10px",
            backgroundColor: "#f8e896",
            maxWidth: "60%",
            //height: "50px",
            textAlign: "left",
            font: "400 .9em 'Open Sans', sans-serif",
            border: "1px solid #dfd087",
            borderRadius: "10px",
            "&:after": {
                content: "''",
                position: "absolute",
                width: "0",
                height: "0",
                borderTop: "15px solid #f8e896",
                borderLeft: "15px solid transparent",
                borderRight: "15px solid transparent",
                top: "0",
                right: "-15px"
            },
            "&:before": {
                content: "''",
                position: "absolute",
                width: "0",
                height: "0",
                borderTop: "17px solid #dfd087",
                borderLeft: "16px solid transparent",
                borderRight: "16px solid transparent",
                top: "-1px",
                right: "-17px"
            }
        },

        messageContent: {
            padding: 0,
            margin: 0,
            fontWeight: "normal",
            fontSize: "0.775rem",
            wordWrap: 'break-word',
            '@media (min-width: 992px)':{
                fontSize: "1rem",
            }
        },
        messageContentFile:{
            fontSize: "0.775rem",
          paddingTop: "2px",
          textDecoration: "underline",
          wordBreak: 'break-all',
          '@media (min-width: 992px)':{
            fontSize: "1rem",
        }
        },
        messageTimeStampRight: {
            fontSize: ".7em",
            fontWeight: "300",
            marginTop: "10px",
            bottom: "-3px",
            minWidth: "45hv",
            right: "5px"
        },

        orange: {
            color: theme.palette.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
            width: theme.spacing(4),
            height: theme.spacing(4)
        },
        avatarNothing: {
            color: "transparent",
            backgroundColor: "transparent",
            width: theme.spacing(4),
            height: theme.spacing(4)
        },
        displayName: {
            marginLeft: "1px",
            paddingTop: "1px",
            paddingBottom: "7px",
            fontWeight: "bold",
            fontSize: "1em"
        }
    })
);


export const MessageRight = (props) => {
    const classes = useStyles();
    const message = props.message ? props.message : "";
    const timestamp = props.timestamp ? props.timestamp : "";
    const fileName = props.fileName??null;
    const displayName = props.displayName ? props.displayName : "";

    const download = async ( event ) =>{
        props.seterLoader(true);
        setTimeout(async ()=>{
            const result = await axiosRun(config.apiLinks.downloadFile, 'POST', {requestId:props.requestId,messageId:props.messageId}, true );
            fileDownload(result, props.fileName);
            props.seterLoader(false);
            }, 200)
    }

    return (<>
        {props.messageType==='right'?<>
            <div className={classes.messageRowRight}>
                <div className={classes.messageOrange}>
                    <p className={classes.messageContent}><div dangerouslySetInnerHTML={{__html:message}} /></p>
                    {fileName&&<p className={classes.messageContentFile} onClick={download}>{fileName}</p>}
                    <div className={classes.messageTimeStampRight}>{timestamp}</div>
                    <div className={classes.messageTimeStampRight}>{props.isViewByStudent&&!props.isStudent&&"✔переглянуто студентом"}</div>
                </div>
                <Avatar
                    className={classes.orange}
                    src={props.photoURL}
                ></Avatar>
            </div>
        </>:<>
            <div className={classes.messageRow}>
                <Avatar
                    alt={displayName}
                    className={classes.orange}
                    src={props.photoURL}
                ></Avatar>
                <div>
                    <div className={classes.messageBlue}>
                        <div className={classes.displayName}>{displayName}</div>
                        <div>
                            <p className={classes.messageContent}><div dangerouslySetInnerHTML={{__html:message}} /></p>
                            {}
                            {fileName&&<p className={classes.messageContentFile} onClick={download}>{fileName}</p>}
                        </div>
                        <div className={classes.messageTimeStampRight}>{timestamp}</div>
                    </div>
                </div>
            </div>
        </>}
    </>);
};
