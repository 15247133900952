import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";


const theme = createTheme({
  palette: {
    primary: {
      main: "#00A43C",
      text: "#000",
      secondary_text: "#121828",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "1.1rem",
          fontWeight: 600,
          textTransform: "inherit",
          "@media (max-width: 640px)": {
            fontSize: "0.777rem",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          "@media (max-width: 640px)": {
            fontSize: "0.777rem",
          },
        },
        secondary: {
          "@media (max-width: 640px)": {
            fontSize: "0.777rem",
          },
        },
      },
    },
  },
});

const GeneralListInfo = (props) => {
  const dateBirthday = props.userData?.birthday?.split("T");
  console.log("date birthday ", dateBirthday);
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <List>
        {props.userData.firstName && (
          <ListItem divider>
            <ListItemText
              sx={{
                width: {
                  xs: "40%",
                  md: "25%",
                },
                color: "primary.text",
              }}
              primary={t("profile.name")}
            />
            <ListItemText
              sx={{
                width: {
                  xs: "60%",
                  md: "75%",
                },
                color: "primary.secondary_text",
              }}
              secondary={props.userData.firstName}
            />
          </ListItem>
        )}
        {props.userData.lastName && (
          <ListItem divider>
            <ListItemText
              sx={{
                width: {
                  xs: "40%",
                  md: "25%",
                },
                color: "primary.text",
              }}
              primary={t("profile.lastName")}
            />
            <ListItemText
              sx={{
                width: {
                  xs: "60%",
                  md: "75%",
                },
                color: "primary.secondary_text",
              }}
              secondary={props.userData.lastName}
            />
          </ListItem>
        )}

        {props.userData.middleName && (
          <>
            <ListItem divider>
              <ListItemText
                sx={{
                  width: {
                    xs: "40%",
                    md: "25%",
                  },
                  color: "primary.text",
                }}
                primary={t("profile.middleName")}
              />
              <ListItemText
                sx={{
                  width: {
                    xs: "60%",
                    md: "75%",
                  },
                  color: "primary.secondary_text",
                }}
                secondary={props.userData.middleName}
              />
            </ListItem>
          </>
        )}
        {dateBirthday && (
          <ListItem divider>
            <ListItemText
              sx={{
                width: {
                  xs: "40%",
                  md: "25%",
                },
                color: "primary.text",
              }}
              primary={t("profile.Birthday")}
            />
            <ListItemText
              sx={{
                width: {
                  xs: "60%",
                  md: "75%",
                },
                color: "primary.secondary_text",
              }}
              secondary={dateBirthday[0]}
            />
          </ListItem>
        )}
        {props.userData.contactPhone && (
          <ListItem divider>
            <ListItemText
              sx={{
                width: {
                  xs: "40%",
                  md: "25%",
                },
                color: "primary.text",
              }}
              primary={t("profile.phone")}
            />
            <ListItemText
              sx={{
                width: {
                  xs: "60%",
                  md: "75%",
                },
                color: "primary.secondary_text",
              }}
              secondary={
                props.userData.contactPhone +
                (props.userData.homePhone
                  ? ", " + props.userData.homePhone
                  : "")
              }
            />
          </ListItem>
        )}

        {props.userData.email && (
          <ListItem>
            <ListItemText
              sx={{
                width: {
                  xs: "40%",
                  md: "25%",
                },
                color: "primary.text",
              }}
              primary={t("profile.email")}
            />
            <ListItemText
              sx={{
                width: {
                  xs: "60%",
                  md: "75%",
                },
                color: "primary.secondary_text",
              }}
              secondary={props.userData.email}
            />
          </ListItem>
        )}
      </List>
    </ThemeProvider>
  );
};
export default GeneralListInfo;
