import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import Container from '@mui/material/Container';
import TableHead from '@mui/material/TableHead';
import {Button, FormControl, TableBody, TableContainer, TableRow, TextField} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ForwardIcon from '@mui/icons-material/Forward';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import LinearProgress from '@mui/material/LinearProgress';
import {useSelector} from "react-redux";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import DeleteIcon from "@mui/icons-material/Delete";
import {OpenAlertDialog} from "./OpenAlertDialog";
import {OpenInfoDialog} from "./OpenInfoDialog";

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});


const CheckBypassItemAdmin = (props) => {
    const [isLoad, setIsLoad] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    const [resultInfo, setResultInfo] = useState("");
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [templateData, setTemplateData] = useState({});
    const { id } = useParams();
    const [bypassCurrentItem, setBypassCurrentItem] = useState({});
    const [formData, setFormData] = useState({});
    const user = useSelector(state=>state.users.user);
    console.log("user ", user);
    useEffect(() => {
        (async () => {
                setIsLoad(true);
                const _tmp = await axiosRun(config.apiLinks.getBypassFieldsByAdmin.replace('%id%', id), 'GET');
                setBypassCurrentItem(_tmp);
                setIsLoad(false);
        })();
    }, [id])

    const handleFormSave = async ()=>{
        setIsLoad(true);
        try{
            const _itm = await axiosRun(config.apiLinks.saveFieldsBypassByAdmin.replace('%id%', id), 'PUT', formData);
            console.log( "RESULT ITEM ", _itm );
            setBypassCurrentItem(_itm);
            setFormData({});
            setIsLoad(false);
            setIsOpenInfo(true);
            setResultInfo("Saved");
        }catch (e){
            setIsOpenInfo(true);
            setResultInfo(e.error??"Error try later");
        }

    }

    const removeBypass = async()=>{
        setIsLoad(true);
        const result = await axiosRun(config.apiLinks.removeBypass, 'PUT', {id});
        console.log("result ", result);
        if (result.msg === 'OK'){
            setIsOpen(false);
            setIsLoad(false);
            navigate('/administration/bypass/list');
        }else{
            setIsOpen(false);
            setIsOpenInfo(true);
            setResultInfo(result.msg);
            setIsLoad(false);
        }
    }

    return (
        <Container maxWidth="xl"  sx={{p:0}}>
            <Paper>
                <OpenAlertDialog isOpen={isOpen} onYes={removeBypass} onNo={()=>{setIsOpen(false)}} text={"Ви впевнені? Буде повністю видалено із бази"} />
                <OpenInfoDialog isOpen={isOpenInfo} text={resultInfo} onYes={()=>{setIsOpenInfo(false)}} />
                <Box sx={{ width: '100%',height:'5px' }}>
                    {isLoad&&<LinearProgress />}
                </Box>
                <Box display={"div"} sx={{display: 'flex', width: "80%" ,flexDirection: 'column',
                mt: '3%',
                pl: "2%",
                pr: "2%",
                pb: "3%"}}>
                    <Typography sx={{mt:"7px", mb:"8px", fontWeght: "bold"}} variant="h4">Поля обхідного листа {bypassCurrentItem?.TemplatesBypass?.name}</Typography>
                    <Typography sx={{mt:"7px", mb:"8px", fontWeght: "bold"}} variant="h5">{bypassCurrentItem?.User?.lastName} {bypassCurrentItem?.User?.firstName} {bypassCurrentItem?.User?.middleName}</Typography>
                    <Typography sx={{mt:"7px", mb:"8px", fontWeght: "bold", color: "#558b2f"}} variant="h6">Пройдено на: {bypassCurrentItem?.progress??0}%</Typography>
                    <TableContainer component={Paper}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width:"40%", fontWeight: "bold"}}>Назва поля</TableCell>
                                <TableCell sx={{width:"40%",fontWeight: "bold"}}>Коментар</TableCell>
                                <TableCell sx={{width:"20%",fontWeight: "bold"}}>Відмітка про борги***</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                        {bypassCurrentItem?.BypassStudentFields?.map(dataItemField=>{
                            const isDisabled = parseInt(dataItemField.Department.id)!==parseInt(user.user.departmentId);
                                return <TableRow sx={{height: "160px"}}>
                                    <TableCell>{`${dataItemField.BypassField?dataItemField.BypassField.fieldName:dataItemField.Department.name} (відділ ${dataItemField.Department.name})`}</TableCell>
                                    <TableCell>
                                        {( ( formData[dataItemField.id]?.isDone === undefined && !dataItemField.isDone) || formData[dataItemField.id]?.isDone===false || dataItemField?.BypassField?.isApprovedField === false  )&&
                                            <FormControl>
                                                <TextField
                                                    label={"коментар"}
                                                    value={formData[dataItemField.id]?.comment??dataItemField.comment}
                                                    onChange={
                                                        (e) => {
                                                            const item = Object.assign({}, formData, {[dataItemField.id]: { ...formData[dataItemField.id],isDefault:dataItemField.BypassField===null,'comment': e.target.value, isApprovedField:dataItemField.BypassField!==null?dataItemField.BypassField.isApprovedField:true} })
                                                            setFormData(item)
                                                        }
                                                    }
                                                    multiline={true}
                                                    rows={3}
                                                    disabled={isDisabled}
                                                    placeholder={"Введіть ваш коментар"}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </FormControl>}
                                        </TableCell>
                                    <TableCell>
                                        {/*{dataItemField.BypassField.isApprovedField&&*/}
                                        <FormControlLabel control={<Checkbox disabled={isDisabled} checked={formData[dataItemField.id]?.isDone??dataItemField.isDone} onChange={(e)=>{
                                            console.log("e target value ", e.target.checked);
                                            const item = Object.assign({}, formData, {[dataItemField.id]: { ...formData[dataItemField.id] ,'isDone': e.target.checked,isDefault:dataItemField.BypassField===null,isApprovedField:dataItemField.BypassField===null||dataItemField.BypassField.isApprovedField} })
                                            setFormData(item);
                                        }} defaultChecked={false} />} label={dataItemField.BypassField===null||dataItemField.BypassField.isApprovedField?`Боргів немає`:"Поле фіналізовано"} />
                                    {/*}*/}
                                        </TableCell>
                                </TableRow>
                        })}
                        </TableBody>
                    </TableContainer>
                    <Box dispay={"div"} sx={{display: 'flex', justifyContent:"space-between", flexDirection: 'row', gap: "4px"}}>
                        <Button sx={{mt: "2%"}} startIcon={<SaveIcon />} variant="contained" onClick={handleFormSave}>
                            {t("save")}
                        </Button>
                        <Button sx={{mt: "2%"}} disabled={bypassCurrentItem?.progress===100 || ['SUPER_ADMIN','ADMIN_FACULTY'].indexOf(user.user.roles) === -1} startIcon={<DeleteIcon />} variant="contained" onClick={()=>{setIsOpen(true)}}>
                            {t("delete")}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
};

export default CheckBypassItemAdmin;
