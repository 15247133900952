import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import {useStyleTablePaginatorActions} from "../css/useStyleTablePaginatorActions";
import { useTheme } from '@mui/material/styles';

export const TablePaginationActionsWrapped = (props)=>{
    const classes = useStyleTablePaginatorActions();
    const { count, page, rowsPerPage } = props;
    const theme = useTheme();
    const handleFirstPageButtonClick = event => {
        props.onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
        props.onPageChange(event, props.page - 1);
    };

    const handleNextButtonClick = event => {
        console.log("proppsss", props);
        props.onPageChange(event, props.page + 1);
    };

    const handleLastPageButtonClick = event => {
        props.onPageChange(
            event,
            Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1),
        );
    };
    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Previous Page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );

}
