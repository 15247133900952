import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RequestFormEdit from "./RequestFormEdit";
import {ChatMessages} from "./ChatMessages";
import NewRequestStundet from "./NewRequesStundet";

const RequestFormStudent = () => {
    const { id } = useParams(); // Accessing ID parameter from the router

    return !id?<NewRequestStundet />:<>
        <RequestFormEdit isStudent={true} id={id} />
        <ChatMessages id={id} isStudent={true} />
    </>
};

export default RequestFormStudent;
