import React, {useEffect,useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import {useStylesGridDialog} from "../css/useStylesGridDialog";
import {FormRaw} from "./FormRaw";
// import {fetcher} from "../../helper/fetcher"; ;
import {fetcher} from "../../helper/fetcher"; 

export const GridDialogFormRemove = (props) => {
    const classesDialog = useStylesGridDialog();
    const {open, openTrigger, columnsFormQuick } = props;
    const handleClose = ()=>{
        openTrigger(false);
    }

    const additionalTriggerAfterSave = ()=>{
        props.setIsUpdate(true);
        handleClose()
    }

    const handleRemove = ()=>{
        fetcher(props.triggersLinks.deleteLink,'DELETE',{ids:props.selected}).then(data=>{
            additionalTriggerAfterSave();
        })
    }

    return (
        <>
            <Dialog open={open} maxWidth={"xl"}  onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Быстрый просмотр</DialogTitle>
                <DialogContent className={classesDialog.dialog}>
                    <DialogContentText>
                        Точно удаляем?
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleRemove} color="primary">
                        Ok
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    );
}
