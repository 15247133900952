import React, {useEffect, useState} from "react";
import GridTable from "./UI/base/GridTable/components/GridTable";
import {config} from "../config";
import {axiosRun} from "../utils/axiosWrap";

export const TemplatesList = ()=>{

    const [isLoad,setIsLoad] = useState(true);
    const [departments, setDepartments] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnsForQuick, setColumnsForQuick] = useState([]);
    useEffect( ()=>{
        const loadAllReq = async ()=> {
            const faculties = await axiosRun(config.apiLinks.getFacultiesListClear, 'GET');
            let converted = {};
            faculties.forEach( (itm,inx) =>{
                converted[itm.id] = itm;
            })
            setFaculties(converted);
            setDepartments(await axiosRun(config.apiLinks.getDepartmentsList, 'GET'));
            setIsLoad(false);

        };
        if (isLoad===true){
            console.log("1", isLoad);
            loadAllReq();
        }
    }, [isLoad])

    useEffect( () => {
        if (!isLoad && Object.keys(faculties).length>0 ){
            const _columns = [
                { id: 'name', numeric: false, disablePadding: false, label: 'Назва шаблону', isLinkItem: true },
                { id: 'facultyId', numeric: false, type:'entityList', entityList: faculties, disablePadding: true, label: "факультет", isLinkItem: true },
            ];
            const _columnsFormQuick = [
                { id: 'name', numeric: false, disablePadding: false, label: 'Назва шаблону', isLinkItem: true, type: "text" },
                { id: 'description', numeric: false, disablePadding: true, label: "Стартове повідомлення зверення", isLinkItem: true, type: "ckeditor"  },
                {
                    id: 'type', label: "Тип", isRequired: true, entityName: "type",
                    entityIdentify: "type",
                    displayType: "Select",
                    type: 'entityList',
                    entityList: [
                        { id: 0, name: "Звернення" },
                        { id: 1, name: "Заява" }
                    ]
                },
                {id: 'adminDescription', numeric: false, disablePadding: true, label: "Інструкція для деканата", isLinkItem: true, type: "textMultiline"},
                { id: 'facultyId', label: "Факультет", isRequired: true, entityName: "facultyId",
                    entityIdentify: "facultyId",
                    displayType: "Select",
                    type: 'entityList',
                    entityList:faculties
                },
                {
                    id: 'departmentId', label: "Відділ", isRequired: true, entityName: 'departmentId',
                    entityIdentify: "facultyId",
                    displayType: "Select",
                    type: "entityList",
                    entityList:departments
                }
            ];
            setColumns(_columns);
            setColumnsForQuick(_columnsFormQuick)
        }
    }, [isLoad,faculties])

    const  triggerLinks = {
        createLinkSave: config.apiLinks.createTemplate,
        editLinkSave: config.apiLinks.editTemplatesItem,
        linkItem: '/administration/template/list/%itemId%',
        deleteLink: 'products/remove',
        redirectAfterAdd: '/administration/templates/list'
    }

    return <>
        {!isLoad&&<GridTable
            linkGetData={config.apiLinks.getTemplateList}
            columns={columns}
            triggerLinks={triggerLinks}
            columnsFormQuick={columnsForQuick}
            tableHeaderText={"Шаблони для комунікації зі студентом"}
            rows4page={10}
        />}
        </>

}