
import React, {useState, useEffect} from 'react';
import {fetcher} from "../../helper/fetcher";
import {getPropByString} from "../../helper/utils";

export const useGridTableEager = ({rows4page,columns, linkGetData}) =>{

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rows4page);
    const [selected, setSelected] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(null);
    const [rows, setRows] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isLoadData, setIsLoadData] = useState(true);
    let timeOut = 0;
    const [filteredData, setFilteredData] = useState([]);

    const handleSubmit = (filterValues) => {
        setIsLoadData(true);
        console.log("linked data ", filterValues);
        const filtersForSubmit = {...filterValues};
        Object.keys(filtersForSubmit).forEach((k) => ( filtersForSubmit[k] === '' || filtersForSubmit[k] === null || filtersForSubmit[k] === -1 ) && delete filtersForSubmit[k]);
        console.log("linked data ", filtersForSubmit);
        fetcher(linkGetData+`?filtersValue=${JSON.stringify(filtersForSubmit)}`,'GET').then(data=> {
                setRows(data);
                setIsLoadData(false);
                setPage(0);
            }
        );
    };

    const handleClear = () => {
        setIsLoadData(true);
        fetcher(linkGetData,'GET').then(data=> {
                console.log("data data ", data);
                setRows(data);
                setIsLoadData(false);
            }
        );
    };


    useEffect(()=>{
        fetcher(linkGetData,'GET').then(data=> {
                console.log("data data ", data);
                setRows(data);
                setIsLoadData(false);
            }
        );
    }, [linkGetData])

    useEffect( () => {
        if ( isUpdate ){
            setIsLoadData(true);
            // alert(2);
            fetcher(linkGetData, 'GET').then((data)=>{
                // alert(3);
                setRows(data);
                // alert(4);
                setIsUpdate(false);
                // setIsLoadData(false);
                // alert(5);
            })
        }
    }, [isUpdate,linkGetData])


    useEffect( ()=>{
        setIsLoadData(false);
    }, [rows] )

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelected( rows.map(n=>n.id) );
            return;
        }
        setSelected([]);
    };




    const handleRequestSort = (event, property) => {
        const _orderBy = property;
        let _order = order;
        if (_order === 'desc') {
            _order = 'asc';
        }else{
            _order = 'desc';
        }
        console.log("order by ", _orderBy, " sorting By", _order);
        setOrder(_order);
        setOrderBy(_orderBy);
    };

    const handleChangePage = (event, page) => {
        setPage( page );
    };

    const handleChangeRowsPerPage = (event) => {
        console.log("page ", event.target.value);
        setRowsPerPage(parseInt(event.target.value));
    };

    /**
     * Handle search
     *
     * @param event
     */
    const handleSearch = (event, name) =>{
        const pattern = event.target.value.replace(' ','').toLowerCase();
        const regex = new RegExp(pattern,'gm');
        if (timeOut) clearTimeout(timeOut);
        timeOut = setTimeout(() => {
            console.log("patterm", pattern, name);
            if (pattern){
                const filtered = rows.filter( itm => {
                    return itm[name]?.toLowerCase().match(regex)
                });
                setRows(filtered);
            }else{
                setIsUpdate(true);
            }
        }, 1500);
    }

    const stableSort= (array, cmp) => {
        console.log("arrya", array);

        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }


    const getSorting = (order, orderBy)=>{
        return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    }

    const desc =(a, b, orderBy)=>{
        const propA = getPropByString(a,{id:orderBy});
        const propB = getPropByString(b, {id:orderBy});
        if (propB < propA) {
            return -1;
        }
        if (propB > propA) {
            return 1;
        }
        return 0;
    }

    /**
     * Get deep property by string.
     * Example getPropByString(object, 'product.color.id')
     *
     * @param obj
     * @param propString
     * @returns {*}
     */
    const handleClick = (event, id) => {
        if (event.target.tagName.toLowerCase() !== 'a') {
            const selectedIndex = selected.indexOf(id);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }
            setSelected(newSelected);
        }else{

        }
    };

    return {
        selected,
        rows,
        isUpdate,
        setIsUpdate,
        order,
        orderBy,
        page,
        rowsPerPage,
        handleClick,
        getSorting,
        stableSort,
        handleChangeRowsPerPage,
        handleChangePage,
        handleRequestSort,
        handleSelectAllClick,
        setOrderBy,
        setSelected,
        isLoadData,
        handleSearch,
        handleClear,
        handleSubmit
    }

}