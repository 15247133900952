import React, { useRef ,useState } from "react";
import Box from '@mui/material/Box';
import ReCAPTCHA from "react-google-recaptcha"
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import Logo from "../../images/snau_logo.png";
import {FormRegisterWithPhone} from './FormRegisterWithPhone';
import {axiosRun} from "../../utils/axiosWrap";
import {config} from "../../config";
import {ModalResult} from "./Register/ModalResult";
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from "@mui/material/FormHelperText";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export const FormUserWrapper = ({fields,logoText, submitLink}) => {
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [registerResult, setRegisterResult] = useState('');
    const [open, setOpen] = useState(false);
    const [submitStart, setSubmitStart] = useState(false);
    const captchaRef = useRef(null);
    let history = useNavigate();
    const { t } = useTranslation();

    const validate = ( )=>{
        let isDone = true;
        const errorsValidation = {};
        Object.keys(fields).forEach( (fieldName) =>{
            if (fields[fieldName]['isEmpty'] === false){
                if ( form[fieldName] !== undefined ){
                    if (form[fieldName] === ''){
                        errorsValidation[fieldName] = {fieldName, 'error': t("reset.empty")};
                        return isDone = false;
                    }
                }else{
                    errorsValidation[fieldName] = {fieldName, 'error': t("reset.empty")};
                    return isDone = false;
                }
            }
        })
        const token = captchaRef.current.getValue();
        if (!token){
            isDone = false;
            errorsValidation['captcha'] = {'fieldName':'captcha', 'error': t("reset.wrong_captcha")};
        }
        setErrors(errorsValidation);
        return isDone;
    }

    const handleSubmit = async (e)=> {
        e.preventDefault();
        if (validate()){
            setSubmitStart(true);
            const token = captchaRef.current.getValue();
            captchaRef.current.reset();
                const registerResult = await axiosRun(
                    submitLink,
                    'POST',
                    Object.assign({},form,{token})
                );
                if (registerResult.isSend){
                    setRegisterResult(t("reset.passwordOk"));

                    setTimeout(()=>{
                        history('/');
                    }, 7000);
                }else{
                    setRegisterResult(registerResult.message?t(registerResult.message):'');
                }

            setOpen(true);
            setSubmitStart(false);
        }
        console.log("registerResult", registerResult);
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: "#00A43C",
                text: "#2d3e50",
            },
        },
    });

    return (
        <>
            <ThemeProvider theme={theme}>
                <ModalResult open={open} onOpen={()=>{}} onClose={()=>{
                    window.history.replace("/")
                }} >
                    {registerResult&&<Typography>{registerResult}</Typography>}
                    <Button onClick={e=>{setOpen(false)}} variant="contained">Close</Button>
                </ModalResult>
                <Container component="main" maxWidth="m">
                    <CssBaseline/>
                    <Box  sx={{
                        marginTop: '30px',
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                        <Box sx={{marginBottom: '43px',  alignItems: 'center', display: "flex", flexDirection: "column"}}>
                            <Avatar
                                sx={{width: 100, height: 100, p: 1, bgcolor: "primary.main"}}
                                src={Logo}
                            />
                            <Typography component="h1" variant="h5" sx={{marginTop: 1}}>
                                {logoText}
                            </Typography>
                        </Box>
                        <FormRegisterWithPhone form={form} setForm={setForm} fields={fields} errors={errors} />
                        <Box sx={{mt:"5px"}}>
                            <ReCAPTCHA ref={captchaRef} sitekey={config.captchaKey} />
                            {errors['captcha']!==undefined&&<FormHelperText error={1} key={'captcha'} id="component-error-text">{errors['captcha']['error']}</FormHelperText>}
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}} >
                            {submitStart===false?<Button onClick={handleSubmit} variant='contained'>
                                {t('form.submit')}
                            </Button>:<CircularProgress />}
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </>
    );
}