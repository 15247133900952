import React, {useEffect, useState} from "react";
import GridTable from "../UI/base/GridTable/components/GridTable";
import {config} from "../../config";
import {axiosRun} from "../../utils/axiosWrap";

export const BypassesList = ()=>{
    const [isLoad,setIsLoad] = useState(true);
    const [faculties, setFaculties] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnsForQuick, setColumnsForQuick] = useState([]);

    useEffect( ()=>{
        const loadAllReq = async ()=> {
            const faculties = await axiosRun(config.apiLinks.getFacultiesListClear, 'GET');
            let converted = {};
            faculties.forEach( (itm,inx) =>{
                converted[itm.id] = itm;
            })
            setFaculties(converted);
            setIsLoad(false);
        };
        if (isLoad===true){
            loadAllReq();
        }
    }, [isLoad])

    useEffect( () => {
        if (!isLoad && Object.keys(faculties).length>0 ){
            const _columns = [
                { id: 'name', numeric: false, disablePadding: false, label: "Ім'я", isLinkItem: true },
                { id: 'facultyId', numeric: false, type:'entityList', entityList: faculties, disablePadding: true, label: "факультет", isLinkItem: true },
                { id: 'createdAt', numeric: false, disablePadding: true, label: "Дата створення", isLinkItem: true },
            ];
            const _columnsFormQuick = [];
            setColumns(_columns);
            setColumnsForQuick(_columnsFormQuick)
        }
    }, [isLoad,faculties])

    const  triggerLinks = {
        createLinkSave: null,
        editLinkSave: null,
        linkItem: '/administration/bypass/templates/item/%itemId%',
        deleteLink: 'products/remove',
        redirectAfterAdd: '/administration/templates/list'
    }

    return <>
        {!isLoad&&<GridTable
            linkGetData={config.apiLinks.getBypassList}
            columns={columns}
            triggerLinks={triggerLinks}
            columnsFormQuick={columnsForQuick}
            tableHeaderText={"Обхідні листи"}
            rows4page={10}
            addLink={"/administration/bypass/templates/item/new"}
            isAddLink={true}
            quickViewNotAllowed={true}
        />}
        </>

}