import React, {useState, useEffect} from 'react';
import {makeStyles} from '@mui/styles';
import {
    Select,
    MenuItem,
    Button,
    TextField,
    Chip,
    Paper,
    Box,
    Autocomplete,
    Typography,
    InputLabel
} from '@mui/material';
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import SaveIcon from "@mui/icons-material/Save";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import {unstable_resetCreateSelectorCache} from "@mui/x-data-grid/internals";
import {OpenInfoDialog} from "./OpenInfoDialog";

export const AdminBypassSender = () => {

    const [faculties, setFaculties] = useState([]);
    const [groups, setGroups] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selectedFaculty, setSelectedFaculty] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [usersFilter, setUsersFilter] = useState([]);
    const [infoFields, setInfoFields] = useState([]);
    const [dataInfoFields, setDataInfoFields] = useState({});
    const [isLoad, setIsLoad] = useState(true);
    const [showInfoMsg, setShowInfoMSg] = useState(false);

    useEffect(() => {
        (async () => {
            setFaculties(await axiosRun(config.apiLinks.getFacultiesListClear, 'GET'));
            setIsLoad(false);
        })()
    }, []);


    const fetchGroups = (facultyId) => {
        (async () => {
            setGroups(await axiosRun(config.apiLinks.getGroupsByFaculties.replace("%id%", facultyId), 'GET'));

        })()
    };

    const fetchTemplates = (facultyId) => {
        (async () => {
            setTemplates(await axiosRun(config.apiLinks.getTemplatesByFaculties.replace("%id%", facultyId), 'GET'));
        })()
    };


    const handleFacultyChange = (event) => {
        const facultyId = event.target.value;
        setSelectedFaculty(facultyId);
        setSelectedGroups([]);
        setIsLoad(true);
        fetchGroups(facultyId);
        fetchTemplates(facultyId);
        setIsLoad(false);
    };

    const handleGroupChange = (event, newValue) => {
        console.log("new value ", newValue);
        setSelectedUsers([]);
        if (newValue.length>0){
            let userFilterArray = [];
            newValue.forEach( itm =>{
                console.log(" users sselected >>>", itm.UserToFaculties);
               userFilterArray = userFilterArray.concat(itm.UserToFaculties);
               console.log("userFilterArray concattted >>>", userFilterArray);
            })
            setUsersFilter(userFilterArray);
            // setUsersFilter(prevState =>{
            //     console.log( "new value ", newValue, newValue[newValue.length-1].UserToFaculties, newValue.length );
            //     return prevState.concat(newValue[newValue.length-1].UserToFaculties);
            // })
        }else{
            setUsersFilter([]);
            setSelectedUsers([]);
        }
        setSelectedGroups(newValue);

    };
    const handleSelectedUsers = (event, newUsers) =>{
        console.log("new Users selectred ", newUsers);
        setSelectedUsers(newUsers);
    }

    const getInfoFields = async (id) =>{
        const data = await axiosRun( config.apiLinks.getBypassFields.replace("%id%", id), "GET" );
        const _infoFields = data.BypassFields.filter(itm => !itm.isApprovedField);
        setInfoFields(_infoFields);
    }

    const handleSend = () => {
        (async ()=>{
            setIsLoad(true);
            console.log("data info fields ", dataInfoFields);
            await axiosRun(config.apiLinks.initiateBypassForGroup,'PUT',{selectedFaculty, selectedGroups, selectedTemplate, selectedUsers, dataInfoFields})
        })()
        setSelectedFaculty(null);
        setSelectedGroups([]);
        setSelectedTemplate(null);
        setTemplates([]);
        setGroups([]);
        setSelectedUsers([]);
        setUsersFilter([]);
        setIsLoad(false);
        setInfoFields([]);
        setDataInfoFields({})
        setShowInfoMSg(true);
    };

    return (
        <Paper>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoad}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <OpenInfoDialog isOpen={showInfoMsg} text={"Розсилка розпочалась..."} onYes={()=>setShowInfoMSg(false)} />
            <Box display={"div"} sx={{display: 'flex', width: '40%' ,flexDirection: 'column'}}>
                <Typography sx={{pl: "6%", mt: "5%"}} variant="h4">Розсилка обхідних листів</Typography>
                    <Box display={"div"} sx={{
                        display: 'flex', flexDirection: 'column',
                        mt: '5%',
                        pl: "6%",
                        gap: '10px',
                        borderColor: "gray",
                        pr: "2%",
                        pb: "3%"
                    }}>
                        <FormControl>
                            <InputLabel id="faculty">{"Faculty"}</InputLabel>
                            <Select
                                value={selectedFaculty}
                                onChange={handleFacultyChange}
                                labelId="faculty"
                                label={"Faculty"}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>Select Faculty</MenuItem>
                                {faculties.map(faculty => (
                                    <MenuItem key={faculty.id} value={faculty.id}>{faculty.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="template">{"Template bypass"}</InputLabel>
                            <Select
                                value={selectedTemplate}
                                onChange={async (e)=>{
                                    setIsLoad(true);
                                    await getInfoFields(e.target.value);
                                    setSelectedTemplate(e.target.value);
                                    setIsLoad(false);
                                }}
                                labelId="template"
                                label={"Template bypass"}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>Select Faculty</MenuItem>
                                {templates.map(template => (
                                    <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <Autocomplete
                                multiple
                                options={groups}
                                getOptionLabel={(option) => option.name}
                                value={selectedGroups}
                                onChange={handleGroupChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Groups"
                                        placeholder="Select groups"
                                    />
                                )}
                            />
                        </FormControl>
                        {console.log("selected users ", usersFilter)}
                        <FormControl>
                            <Autocomplete
                                multiple
                                options={usersFilter}
                                getOptionLabel={(option) => `${option.User.lastName} ${option.User.firstName} ${option.User.middleName} (ІПН ${option.User.ipnNumber})`}
                                value={selectedUsers}
                                onChange={handleSelectedUsers}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Users"
                                        placeholder="Select users"
                                    />
                                )}
                            />
                        </FormControl>
                        {Object.keys(infoFields).map(_kfield=>{
                            const field = infoFields[_kfield];
                            console.log("field ", field);
                            return <FormControl>
                                <TextField
                                    label={field.fieldName}
                                    value={dataInfoFields[field.id]}
                                    onChange={
                                        (e) => {
                                            const item = Object.assign({}, dataInfoFields, {[field.id]: e.target.value });
                                            setDataInfoFields(item);
                                        }
                                    }
                                />
                            </FormControl>
                        })}
                        <Button sx={{mt: "2%", width: "30%"}} variant="contained" endIcon={<ForwardToInboxIcon/>} color="primary" onClick={handleSend}>
                            Send
                        </Button>
                    </Box>
            </Box>

        </Paper>
    );
};
