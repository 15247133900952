import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {FormControl,InputLabel,Select} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexWrap:'wrap',
        border: '1px solid #ccc',
        padding: '20px',
        borderRadius: '4px',
        '& > *': {
            margin: '8px 8px 8px 0',
            flex: '0 0 100%',
            "@media (min-width: 475px)": {
                flex: '0 0 100%',
            },
            "@media (min-width: 768px)": {
                flex: '0 0 46%',
            },
            "@media (min-width: 1140px)": {
                flex: '0 0 23%',
            },
            "@media (min-width: 1240px)": {
                flex: '0 0 32%',
            },
           
        },
    },
    block_title:{
        position: 'absolute',
        top: '-26px',
        left: '30px',
        fontSize: '1.2rem',
        padding: '5px 10px',
        background: '#fff'
    },
}));

export const Filters = ({ filtersList,onSubmit, onClear })=>{
    const classes = useStyles();

    const [filterValues, setFilterValues] = useState(
        filtersList.reduce(
            (acc, filter) => ({
                ...acc,
                [filter.name]: filter.default??'',
            }),
            {}
        )
    );

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFilterValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        console.log(' filter Values >>> ', filterValues, name, value );

    };

    const handleChangeAutocomplete = (event, name, value) =>{
        setFilterValues( (prevValues) => ({
            ...prevValues,
            [name]: value,
        }) )
    };

    const handleClear = () => {
        setFilterValues(
            filtersList.reduce(
                (acc, filter) => ({
                    ...acc,
                    [filter.name]: filter.default??'',
                }),
                {}
            )
        );
        onClear();
    };

    const handleSubmit = () => {
        onSubmit(filterValues);
    };

    return (
        <div className={classes.root}>
            <div className={classes.block_title}>Фільтри</div>
            {filtersList.map((filter) => (
                <React.Fragment key={filter.name}>
                    {filter.type === 'text' ? (
                        <TextField
                            label={filter.label}
                            size="small"
                            name={filter.name}
                            value={filterValues[filter.name]}
                            onChange={handleChange}
                        />
                    ) : filter.type === 'select' ? (

                        <FormControl>
                            {/* <InputLabel>{filter.label}</InputLabel> */}
                            <TextField
                                labelId={`${filter.name}-label`}
                                size="small"
                                select
                                label={filter.label}
                                id={filter.name}
                                name={filter.name}
                                value={filterValues[filter.name]?filterValues[filter.name]:0}
                                onChange={handleChange}
                            >
                                {filter.options.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    ) : filter.type === 'autocomplete'? (<div>
                        <Autocomplete
                            value={filterValues[filter.name]}
                            onChange={(event, newValue) => {
                                handleChangeAutocomplete(event, filter.name ,newValue?newValue:null);
                            }}
                            getOptionLabel={(option) =>
                                option.label ? option.label : ''
                            }
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            id="controllable-states-demo"
                            options={filter.options}
                           
                            renderInput={(params) => <TextField {...params} label={filter.label} size="small"/>}
                        />
                    </div>): null}
                </React.Fragment>
            ))}
            <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}  >
            <Button variant="contained" color="success" onClick={handleSubmit}>
                    Застосувати
                </Button>
                <Button variant="outlined"  color="error" onClick={handleClear}>
                    Очистити
                </Button>
                
            </Stack>
        </div>
    );
}

Filters.propTypes = {
    filtersList: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['text', 'select']).isRequired,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.string.isRequired,
                    label: PropTypes.string.isRequired,
                })
            ),
        })
    ).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
};

