import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import Container from '@mui/material/Container';
import {Button, FormControl, TableCell, TextField} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ForwardIcon from '@mui/icons-material/Forward';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DoneAllTwoToneIcon from '@mui/icons-material/DoneAllTwoTone';
import LinearWithValueLabel from "./sub/LinearWithValueLabel";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import fileDownload from "js-file-download";

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});


const CheckBypassItem = (props) => {
    const [isLoad, setIsLoad] = useState(true);
    const [isLoadPdf, setIsLoadPDf] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [templateData, setTemplateData] = useState({});
    const { templateId , bypassId, speciality, specialization } = useParams();
    const [bypassCurrentItem, setBypassCurrentItem] = useState({});
    const [formData, setFormData] = useState({});
    useEffect(() => {

        const load = async () => {
            setIsLoad(true);
            if (bypassId !== 'new'){
                const _tmp = await axiosRun(config.apiLinks.getBypassFieldsByStudent.replace('%id%', bypassId), 'GET');
                setBypassCurrentItem(_tmp);
                setTimeout(()=>{
                    setIsLoad(false);
                }, [1000])

            }else{
                const _template = await axiosRun(config.apiLinks.getBypassFieldsByTemplate.replace("%id%", templateId), 'GET');
                setTemplateData(_template);
                console.log("template ", _template);
                setIsLoad(false);
            }
        }
        load();
        let timer = null;
        if (bypassId !== 'new'){
            timer = setInterval(()=>{
                load();
            }, 10000)
        }

        return ()=>{
            clearTimeout(timer);
        }
    }, [templateId, bypassId])

    const initiateNewBypassList = async ()=>{
       if (false){
           setIsLoadPDf(true);
           const data = await axiosRun( config.apiLinks.createNewStudentsBypass, 'POST', {templateId, speciality, specialization} );
           navigate(`/profile/bypass/template/${templateId}/${data.item.id}/`);
           setIsLoadPDf(false);
       }
    }

    const downloadAsPdf = async ()=>{
       setIsLoadPDf(true);
       const result = await axiosRun( config.apiLinks.getBypassPdf, 'POST', {id:bypassCurrentItem.id,templateId},  true )
       fileDownload(result, `bypass_#${bypassCurrentItem.id}.pdf`);
       setIsLoadPDf(false);
    }

    return (
        <Container maxWidth="xl"  sx={{p:0}}>
            <Paper>
                <Backdrop
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoadPdf}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box sx={{ width: '100%',height:'5px' }}>
                    {isLoad&&<LinearProgress />}
                </Box>
                <Box display={"div"} sx={{display: 'flex', flexDirection: 'column',
                mt: '3%',
                pl: "2%",
                pr: "2%",
                pb: "3%"}}>

                    {bypassId==='new'&&<><Typography sx={{mt:"7px"}} variant="h6">Поля обхідного листа {templateData?.name}</Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{backgroundColor:"#eeeeee"}}>
                                        <TableCell sx={{ fontWeight: "bold"}}>{t("bypass.fieldTableColumnForPassing")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {templateData?.BypassFields?.map(dataItemField=>{
                                      return <TableRow sx={{backgroundColor:dataItemField.isDone?"#dcedc8":"#f5f5f5"}}>
                                      <TableCell>{`${dataItemField.fieldName} (відділ ${dataItemField.Department.name})`}</TableCell>
                                  </TableRow>
                                })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>}
                    {bypassId!=="new"&&<><Typography sx={{mt:"7px"}} variant="h6">Поля обхідного листа {bypassCurrentItem?.TemplatesBypass?.name}</Typography>
                    <Box sx={{ width: '100%' }}>
                        <LinearWithValueLabel progress={bypassCurrentItem?.progress} />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{backgroundColor:"#eeeeee"}}>
                                    <TableCell sx={{ fontWeight: "bold"}}>{t("bypass.fieldTableColumn")}</TableCell>
                                    <TableCell sx={{ fontWeight: "bold"}}>{t("bypass.fieldTablecomment")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {bypassCurrentItem?.BypassStudentFields?.map(dataItemField=>{
                                console.log(dataItemField.isDone,dataItemField?.BypassField?.isApprovedField );
                                return <TableRow sx={{backgroundColor:dataItemField.isDone?"#f1f8e9":"#f5f5f5"}}>
                                            <TableCell>{`${dataItemField.BypassField?dataItemField.BypassField.fieldName:dataItemField.Department.name} (відділ ${dataItemField.Department.name})`}</TableCell>
                                            <TableCell>{((dataItemField.BypassField === null&&dataItemField.isDone)||(dataItemField.isDone&&dataItemField.BypassField.isApprovedField===true))?<><DoneAllTwoToneIcon /></>:dataItemField.comment??t('message.noData')}</TableCell>
                                    </TableRow>
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </>}
                    <Box dispay={"div"} sx={{display: 'flex', flexDirection: 'row', gap: "4px"}}>
                        <Button sx={{mt: "2%"}} variant="contained" startIcon={<KeyboardBackspaceIcon />} onClick={()=>{
                            navigate('/profile/bypass/lists');
                        }}>
                            {t("bypass.back")}
                        </Button>
                        {bypassId==="new"&&false&&<Button sx={{mt: "2%"}} variant="contained" endIcon={<ForwardIcon />} onClick={initiateNewBypassList}>
                            {t("bypass.begin")}
                        </Button>}
                        {bypassCurrentItem?.progress === 100&&<Button sx={{mt: "2%"}} variant="contained" endIcon={<PictureAsPdfIcon />} onClick={downloadAsPdf}>
                            {t("bypass.downloadPdf")}
                        </Button>
                        }
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
};

export default CheckBypassItem;
