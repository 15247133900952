import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Paper,
    TextField,
    Typography,
} from '@mui/material';
import {useParams} from "react-router-dom";
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import CheckboxList from "./sub/CheckboxList";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {useTranslation} from "react-i18next";


const Faculty = () => {
    const [facultyName, setFacultyName] = useState('');
    const [linkName, setLinkName] = useState('');
    const [direction, setDirection] = useState(0);
    const [linkURL, setLinkURL] = useState('');
    const [links, setLinks] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoad, setIsLoad] = useState(true);
    const {id} = useParams();
    const [checked, setChecked] = useState([]);
    const { t } = useTranslation();
    const [isUpdate, setIsUpdate] = useState(true);
    const [clickedForEdit, setClickedForEdit] = useState(0);
    useEffect(()=>{
        if (isUpdate){
            (async()=>{
                const faculty = await axiosRun( config.apiLinks.facultyItemLink.replace('%id%', id), "GET" )
                setFacultyName(faculty.name);
                setLinks(faculty.FacultyLinks);
                setIsLoad(false);
                setIsUpdate(false);
            })();
        }
    }, [id,isUpdate])

    const handleAddLink = () => {
        setOpenDialog(true);
    };

    useEffect(()=>{
        if (clickedForEdit){
            const item = links.filter(itm => itm.id === clickedForEdit);
            console.log("item ", item);
            setLinkName(item[0].name);
            setLinkURL(item[0].link);
            setDirection(item[0].direction);
            setOpenDialog(true);
        }
    }, [clickedForEdit,links])

    const handleSaveLink = async () => {
        if (linkName && linkURL) {
            setIsLoad(true);
            await axiosRun(config.apiLinks.facultyItemLinkAdd, "POST", {id: clickedForEdit,link:linkURL, name:linkName, direction:direction||links.length+1, facultyId: id})
            setIsUpdate(true);
            setOpenDialog(false);
            setClickedForEdit(null);
            setLinkName('');
            setLinkURL('');
            setDirection(0);
        }
    };

    const handleRemoveLink = async () =>{
        await axiosRun(config.apiLinks.removeLinksFromGroup, "POST", {ids:checked});
        setIsUpdate(true);
        setChecked([]);
    }

    return (
        <Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoad}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ width: {lg: "100%"}, pl: "25px", pt: "20px", pb: "20px"}} component={"div"}>
                <Typography variant="h4">Links for: {facultyName}</Typography>

                <Box sx={{display:"flex", flexDirection:"row", justifyContent:'flex-start', gap: "5px" , mt:'25px'}}>
                    <Button variant="contained" color="primary" onClick={handleAddLink}>
                        Add Link
                    </Button>
                    <Button disabled={checked.length === 0} variant="contained" color="primary" onClick={handleRemoveLink}>
                        Remove selected
                    </Button>
                </Box>
                <CheckboxList setChecked={setChecked} checked={checked} list={links} setClickedForEdit={setClickedForEdit} />

                <Dialog open={openDialog} onClose={() =>{
                    setClickedForEdit(null);
                    setLinkName('');
                    setLinkURL('');
                    setDirection('');
                    setOpenDialog(false);
                }}>
                    <DialogTitle>Add Link</DialogTitle>
                    <DialogContent>
                        <Box sx={{display:"flex", flexDirection: "column", gap:"5px",justifyContent: "flex-start", alignItems:"flex-start"}}>
                            <DialogContentText>
                                Please enter the name and URL for the link.
                            </DialogContentText>
                            <TextField
                                label="Link Name"
                                variant="outlined"
                                value={linkName}
                                onChange={(e) => setLinkName(e.target.value)}
                            />
                            <TextField
                                label="Link URL"
                                variant="outlined"
                                value={linkURL}
                                onChange={(e) => setLinkURL(e.target.value)}
                            />
                            <FormControl variant="standard">
                                <InputLabel shrink htmlFor="direction">
                                    Direction
                                </InputLabel>
                                <Input placeholder={t('faculty.putdirection')}
                                       id={"direction"}
                                       value={direction||links.length+1}
                                       type="number"
                                       onChange={(e) => setDirection(e.target.value)} />
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setClickedForEdit(null);
                            setLinkName('');
                            setLinkURL('');
                            setDirection('');
                            setOpenDialog(false);
                        }} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSaveLink} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Paper>
    );
};

export default Faculty;
