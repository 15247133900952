import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import {useEnhancedTableHead} from "../css/useEnhancedTableHead";

export const EnhancedTableHead = (props)=>{
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, columns } = props;
    const classes = useEnhancedTableHead();
    const createSortHandler = (event,property)=>{
        props.onRequestSort(event, property);
    }

    return (
        <TableHead >
            <TableRow>
                {!props.quickViewNotAllowed&&<TableCell className={classes.headTable} padding="checkbox">
                   <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell>}
                {columns.map(
                    row => (
                        <TableCell
                            className={classes.headTable}
                            key={row.id}
                            align='left'
                            padding={row.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === row.id ? order : false}
                        >
                            <Tooltip
                                title="Sort"
                                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={orderBy === row.id}
                                    direction={order?order:'asc'}
                                    onClick={event => createSortHandler(event,row.id)}
                                >
                                    {row.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ),
                    this,
                )}
            </TableRow>
        </TableHead>
    );
}


