import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {logoutUser} from "../slices/usersSlice";

export const Logout = () => {
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(logoutUser());
    },[dispatch])
    return (<>...exit</>)
}