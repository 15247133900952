import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {GridTableActions} from "./GridTableActions";
import {useStylesEnhancedTableToolbar} from "../css/useStylesEnhancedTableToolbar";

export const EnhancedTableToolbarWrapper = props => {
    const { numSelected, tableHeaderText } = props;
    const classes = useStylesEnhancedTableToolbar();
    console.log("hideeeee", props.hideAdd);
    return (
        <>
        <Toolbar className={classes.toolbarTop}>
            <div className={classes.title}>
                    <Typography variant="h4" id="tableTitle">
                        {tableHeaderText}
                    </Typography>
            </div>
        </Toolbar>
        <GridTableActions lockedProps={props.lockedProps} setIsUpdate={props.setIsUpdate} triggerLinks={props.triggerLinks} rows={props.rows} columnsFormQuick={props.columnsFormQuick}
                          selected={props.selected} numSelected={numSelected}
                          setSelected={props.setSelected}
                          isLoadData={props.isLoadData}
                          handleSearch={props.handleSearch}
                          handleSubmit={props.handleSubmit}
                          handleClear={props.handleClear}
                          filtersList={props.filtersList}
                          isAddLink={props.isAddLink}
                          quickViewNotAllowed={props.quickViewNotAllowed}
                          addLink={props.addLink}
                          hideAdd={props.hideAdd}
        />
      </>
    );
};