import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { axiosRun } from "../utils/axiosWrap";
import { config } from "../config";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CircularProgress from "@mui/material/CircularProgress";

const EditProfile = ({ setUserData }) => {



    const setCanvasImage = (image, canvas, crop) => {
        if (!crop || !canvas || !image) {
            return;
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;


        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }

    const [upImg, setUpImg] = useState();

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);

    const [crop, setCrop] = useState({ unit: 'px', minWidth: 150, minHeight: 200 ,width: 150, height: 200, maxWidth: 300, maxHeight: 400, aspect: 3/4 });
    const [completedCrop, setCompletedCrop] = useState();
    const [isLoading, setIsLoading] = useState(null);



    // on selecting file we set load the image on to cropper
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);
    const handleUpload = async (canvas) => {
        if (!canvas) {
            return;
        }
        try {

            canvas.toBlob(async (blob) => {
                setIsLoading(true);
                await axiosRun(config.apiLinks.uploadStPhoto, "POST", { file: blob });
                const userData = await axiosRun(config.apiLinks.getUserInfo, "GET");
                setUserData(userData);
                setIsLoading(false);
            }, 'image/jpeg', 0.8);
        } catch (e) {
            alert("try again later")
        }
    };
    useEffect(() => {
        setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
    }, [completedCrop]);



    const { t } = useTranslation();
    return (
        <Grid container spacing={2} sx={{ pt: 2, pb: 4, mt: 2 }} >

            {upImg &&
                <Grid item xs={12} md={6} >
                    {isLoading&&<CircularProgress color="inherit" />}
                    <ReactCrop
                        src={upImg}
                        onImageLoaded={onLoad}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                    />

                </Grid>
            }
            {upImg &&
                <Grid item xs={12} md={6} >
                    <canvas
                        ref={previewCanvasRef}

                        style={{
                            width: Math.round(completedCrop?.width ?? 0),
                            height: Math.round(completedCrop?.height ?? 0),
                            marginBottom: '20px',
                            display: "none"
                        }}
                    />
                </Grid>
            }
            <Grid item xs={12}  >
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent="center" sx={{
                    px: {
                        xs: 2,
                        md: 0
                    }
                }} >
                    <Button variant="contained" component="label">
                        <input hidden type='file' accept='image/*' onChange={onSelectFile} />
                        <DriveFolderUploadOutlinedIcon sx={{ mr: 2 }} />
                        {t('profile.upload_photo')}
                    </Button>
                    <Button color={'success'} variant={'contained'} onClick={() => handleUpload(previewCanvasRef.current)}>{t('text.save')}</Button>
                </Stack>
            </Grid>

        </Grid>
    );
};

export default EditProfile;
