
export function handleResponse(response) {
    console.log("response ", response);
    if (response.status === 200 || response.status === 201){
        return response.data
    }else{
        return Promise.reject(response)
    }
    // return response.text().then(text => {
    //     const data = text && JSON.parse(text);
    //     console.log("handle st", response.ok);
    //     console.log("handle response", response);
    //     if (!response.ok) {
    //         if (response.status === 401) {
    //             localStorage.removeItem('userInfo');
    //             window.location = '/logout';
    //         }
    //         const error = (data && data.message) || response.statusText;
    //         console.log("errror 14", error);
    //         return Promise.reject(error);
    //     }
    //     console.log("data response", data);
    //     return data;
    // });
}