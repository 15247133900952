import * as React from 'react';
import { useState } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useEffect} from "react";
import axios from "axios";
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import {SubTable} from "./sub/SubTable";
import GridTable from "./UI/base/GridTable/components/GridTable";

function createData(name, calories, fat, carbs, protein, price) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        history: [
            {
                date: '2020-01-05',
                customerId: '11091700',
                amount: 3,
            },
            {
                date: '2020-01-02',
                customerId: 'Anonymous',
                amount: 1,
            },
        ],
    };
}

function Row(props) {
    const { row } = props;
    const [openInfo, setOpenInfo] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow >
                <TableCell component="th" scope="row">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpenInfo(!openInfo)}>
                        {openInfo ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpenError(!openError)}>
                        {openError ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    {row.id}
                </TableCell>
                <TableCell component="th" scope="row" align="right">{row.createdAt}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={openInfo} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Log success</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            {/*<SubTable data={row.logs} specProps={[]} />*/}
                                            <SubTable data={<div><pre>{JSON.stringify(row.logs, null, 2)
                                                .replaceAll("[","")
                                                .replaceAll("{","")
                                                .replaceAll("}","")
                                                .replaceAll("]","")
                                                .replaceAll('"',"")
                                                .replaceAll(',', "")
                                            }</pre></div>} specProps={[]} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                    <Collapse in={openError} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Log Errors</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <SubTable data={<div><pre>{JSON.stringify(row.errors, null, 2).replaceAll("[","")
                                                .replaceAll("{","")
                                                .replaceAll("}","")
                                                .replaceAll("]","")
                                                .replaceAll('"',"")
                                                .replaceAll(',', "") }</pre></div>} specProps={[]} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


export const LogList = () => {
    const [logList, setLogList] = useState([]);
    const [isLoad, setIsLoad] = useState(true);
    const columns = [
        { id: 'id', numeric: false, disablePadding: false, label: 'Id', isLinkItem: true },
        { id: 'hash', numeric: false, disablePadding: true, label: "hash", isLinkItem: true },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Дата імпорту', isLinkItem:true },
        { id: 'Administrator.login', numeric: true, disablePadding: false, label: "admin Login",isLinkItem:true},
        { id: 'Administrator.firstName', numeric: true, disablePadding: false, label: "адмін Опис 1",isLinkItem:true},
        { id: 'Administrator.lastName', numeric: true, disablePadding: false, label: "адмін Опис 2",isLinkItem:true},
    ];
    useEffect( ()=> {
        const getData = async ()=> {
            const logData = await axiosRun(config.apiLinks.getLogsData, "GET");
            setLogList(logData);
            setIsLoad(false);
        }
        getData();
        return ()=>{}
    }, []);

    const  triggerLinks = {
        editLinkSave: '',
        createLinkSave: '',
        refreshLink: 'products',
        linkItem: '/administration/log/item/%itemId%',
        deleteLink: ''
    }

    return (<>
            {!isLoad&&<GridTable
                linkGetData={config.apiLinks.getLogsData}
                columns={columns}
                triggerLinks={triggerLinks}
                columnsFormQuick={columns}
                tableHeaderText={"Log history"}
                rows4page={10}
            />}
        </>)

    // return (
    //     <TableContainer component={Paper}>
    //         <Table aria-label="collapsible table">
    //             <TableHead>
    //                 <TableRow>
    //                     <TableCell align="left">Import ID</TableCell>
    //                     <TableCell align="right">created At</TableCell>
    //                 </TableRow>
    //             </TableHead>
    //             <TableBody>
    //                 {isLoad===false&&logList.map((row) => (
    //                     <Row key={row.id} row={row} />
    //                 ))}
    //             </TableBody>
    //         </Table>
    //     </TableContainer>
    // );
}