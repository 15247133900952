import React, {useEffect,useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import {useStylesGridDialog} from "../css/useStylesGridDialog";
import FormRaw from "./FormRaw";
import {useNavigate} from "react-router-dom";


export const GridDialogFormAdd = (props) => {
    const classesDialog = useStylesGridDialog();
    const {open, openTrigger, columnsFormQuick } = props;
    const navigate = useNavigate();

    const handleClose = ()=>{
        openTrigger(false);
    }

    const additionalTriggerAfterSave = (id)=>{
        handleClose();
        props.setIsUpdate(true);
        navigate(props.triggerLinks.redirectAfterAdd.replace('%itemId%',id));
    }

    return (
        <>
            <Dialog open={open} maxWidth={"xl"}  onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Швидкий перегляд</DialogTitle>
                <DialogContent className={classesDialog.dialog}>
                    <DialogContentText>
                        Швидке додавання. Натисніть кнопку заберегти
                    </DialogContentText>
                    <Grid container direction="row"
                          justifyContent="center"
                          alignItems="center"
                          fullWidth={false}
                          spacing={3}>
                            <Grid item xs>
                                <FormRaw
                                    columnsFormQuick={columnsFormQuick}
                                    itemWithData={{}}
                                    additionalEvent={ additionalTriggerAfterSave }
                                    link={props.triggerLinks.createLinkSave}
                                    lockedProps={props.lockedProps}
                                />
                            </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Закрити
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
