import React, {useState, useEffect} from 'react';
import {makeStyles} from '@mui/styles';
import {
    Select,
    MenuItem,
    Button,
    TextField,
    Chip,
    Paper,
    Box,
    Autocomplete,
    Typography,
    InputLabel
} from '@mui/material';
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import SaveIcon from "@mui/icons-material/Save";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import {OpenInfoDialog} from "./OpenInfoDialog";
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import {OpenAlertDialog} from "./OpenAlertDialog";

export const StudentDeactivator = () => {

    const [faculties, setFaculties] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedFaculty, setSelectedFaculty] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [usersFilter, setUsersFilter] = useState([]);
    const [isLoad, setIsLoad] = useState(true);
    const [showInfoMsg, setShowInfoMSg] = useState(false);
    const [resultText, setResultText] = useState(false);

    useEffect(() => {
        (async () => {
            setFaculties(await axiosRun(config.apiLinks.getFacultiesListClear, 'GET'));
            setIsLoad(false);
        })()
    }, []);


    const fetchGroups = (facultyId) => {
        (async () => {
            setGroups(await axiosRun(config.apiLinks.getGroupsByFaculties.replace("%id%", facultyId), 'GET'));

        })()
    };



    const handleFacultyChange = (event) => {
        setIsLoad(true);
        setSelectedFaculty(event.target.value);
        setSelectedGroups([]);
        fetchGroups(event.target.value);
        setIsLoad(false);
    };

    const handleGroupChange = (event, newValue) => {
        console.log("new value ", newValue);
        setSelectedUsers([]);
        if (newValue.length>0){
            let userFilterArray = [];
            newValue.forEach( itm =>{
                console.log(" users sselected >>>", itm.UserToFaculties);
                userFilterArray = userFilterArray.concat(itm.UserToFaculties);
                console.log("userFilterArray concattted >>>", userFilterArray);
            })
            setUsersFilter(userFilterArray);
        }else{
            setUsersFilter([]);
            setSelectedUsers([]);
        }
        setSelectedGroups(newValue);
    };
    const handleSelectedUsers = (event, newUsers) =>{
        setSelectedUsers(newUsers);
    }

    const handleSend = () => {
        if (selectedGroups.length>0){
            (async ()=>{
                setIsLoad(true);
                await axiosRun(config.apiLinks.studentDeactivator,'PUT',{selectedFaculty, selectedGroups, selectedUsers });
                setShowInfoMSg(true);
            })()
            setSelectedFaculty(null);
            setSelectedGroups([]);
            setGroups([]);
            setSelectedUsers([]);
            setUsersFilter([]);
            setIsLoad(false);
            setResultText("Деактивовано");
        }else{
            setResultText("Помилка! виберіть факультету і групу для деактивації");
            setShowInfoMSg(true);
        }
    };

    return (
        <Paper>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoad}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <OpenInfoDialog isOpen={showInfoMsg} text={resultText} onYes={()=>setShowInfoMSg(false)} />
            <Box display={"div"} sx={{display: 'flex', width: '40%' ,flexDirection: 'column'}}>
                <Typography sx={{pl: "6%", mt: "5%"}} variant="h4">Деактивація студентів</Typography>
                <Box display={"div"} sx={{
                    display: 'flex', flexDirection: 'column',
                    mt: '5%',
                    pl: "6%",
                    gap: '10px',
                    borderColor: "gray",
                    pr: "2%",
                    pb: "3%"
                }}>
                    <FormControl>
                        <InputLabel id="faculty">{"Факультет"}</InputLabel>
                        <Select
                            value={selectedFaculty}
                            onChange={handleFacultyChange}
                            labelId="faculty"
                            label={"Факультет"}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Select Faculty</MenuItem>
                            {faculties.map(faculty => (
                                <MenuItem key={faculty.id} value={faculty.id}>{faculty.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Autocomplete
                            multiple
                            options={groups}
                            getOptionLabel={(option) => option.name}
                            value={selectedGroups}
                            onChange={handleGroupChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Groups"
                                    placeholder="Select groups"
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl>
                        <Autocomplete
                            multiple
                            options={usersFilter}
                            getOptionLabel={(option) => `${option.User.lastName} ${option.User.firstName} ${option.User.middleName} (ІПН ${option.User.ipnNumber})`}
                            value={selectedUsers}
                            onChange={handleSelectedUsers}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Users"
                                    placeholder="Select users"
                                />
                            )}
                        />
                    </FormControl>
                    <Button sx={{mt: "2%", width: "50%"}} variant="contained" endIcon={<ToggleOffOutlinedIcon />} color="primary" onClick={handleSend}>
                        Деактивувати
                    </Button>
                </Box>
            </Box>

        </Paper>
    );
};
