import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Container from '@mui/material/Container';

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});
const RequestsListSudent = (props) => {

    const [listData, setListData] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoad(true);
        const load = async () => {
            const _tmp = await axiosRun(config.apiLinks.getRequestList, 'GET');
            setListData(_tmp);
            setIsLoad(false);
        }
        load();
    }, [])

    const navigateClick = (item)=>{
        navigate(`/profile/request/chat/${item.id}`)
    }

    return (
        <Container maxWidth="xl"  sx={{p:0}}>
        <Paper elevation={3} sx={{width: '100%', margin: '0 auto', marginTop: "10px",padding: '20px', position: 'relative'}}>
             
            <Box sx={{paddingBottom: '20px'}} component={"div"}>
                <Typography variant="h5" component={'div'} sx={{textAlign:'center'}}>{t('profile.MyRequestsList')}</Typography>
            </Box>

           
            <Grid
                container
                alignItems="flex-start"
                spacing={2}
               
            >
                {listData.map(item => {
                    return <Grid xs={12} md={6} lg={4} item onClick={e=>navigateClick(item)} >
                        <Paper sx={{
                            p: 2,
                            flexGrow: 1,
                            backgroundColor: '#fafafa'
                        }}>
                            <Grid container spacing={2}>
                                <Grid item  sm container>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Grid item xs>
                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                {item.template.name}
                                            </Typography>

                                            <Typography variant="body2" color="text.secondary">
                                                 {item.template.facultyTemplate.name}
                                            </Typography>

                                            <Typography component={'div'} sx={{mt: '8px'}} variant="body2" gutterBottom>
                                                {t('profile.DateRequest')} {item.createdAt}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                })}
            </Grid>
        
        </Paper>
        </Container>
    );
};

export default RequestsListSudent;
