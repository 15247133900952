import React, { useState, useEffect } from 'react';
import {useNavigate, useParams} from 'react-router-dom'; // Import useParams
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    Paper,
    Box
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import SaveIcon from "@mui/icons-material/Save";
import KeyIcon from '@mui/icons-material/Key';
import {axiosRun} from "../../../utils/axiosWrap";
import {config} from "../../../config";
import {shallowEqual, useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {OpenInfoDialog} from "../../OpenInfoDialog";
import Snackbar from "@mui/material/Snackbar";
import {Alert} from "../../UI/base/GridTable/components/FormRaw";

const AdministratorItem = () => {
    const { id } = useParams(); // Get ID from URL params
    const [department, setDepartment] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [formData, setFormData] = useState({});
    const [isLoad, setIsLoad] = useState(true);
    const [isOpen,setIsOpen] = useState(false);
    const [textResult, setTextResult] = useState('');
    const [validError, setValidError] = useState([]);
    const user = useSelector(state=>state.users.user, shallowEqual);
    const [openAlert, setOpenAlert] = useState(false);
    const [openAlertText, setOpenAlertText] = useState(false);

    const navigate = useNavigate();

    console.log("user user user ", user);

    const _convertItemData = (data) =>{
        const _tmpF = data?.AdministratorToFaculties?.map(itm=>{return{id: itm.fid, name: itm.Faculty.name}});
        data.faculties = _tmpF;
        delete data.AdministratorToFaculties;
        return data;
    }

    useEffect(() => {
        console.log("id ", id);

        if (id!=='new') {
            (async ()=> {
                const data = await axiosRun(config.apiLinks.getAdministratorData.replace("%id%", id), 'GET' );
                setFormData(_convertItemData(data));

            })();
        }
        fetchDepartments();
        fetchFaculties();
        setIsLoad(false);
    }, [id]); // Trigger effect when ID changes



    const fetchDepartments = async () => {
         const data = await axiosRun(config.apiLinks.getDepartmentsList, 'GET');
         setDepartment(data);
    };

    const fetchFaculties = async () => {
        const data = await axiosRun(config.apiLinks.getFacultiesListClear, 'GET');
        const _tmp = data.map(itm=>{return{id: itm.id, name: itm.name}});
        console.log("data333", _tmp);
        setFaculties(_tmp);
    };

    const handleSave = async () => {
       console.log("formDATAaa", formData);
       setIsLoad(true);
       try {
           const data = await axiosRun(config.apiLinks.saveAdminData.replace("%id%", id), "PUT", formData);
           setFormData(_convertItemData(data));
           setValidError([]);
           setOpenAlertText('SAVED');
           setOpenAlert(true);
           if (id === 'new'){
               navigate('/administration/administrator/item/'+data.id);
           }
       }catch (e){
           setValidError(e.data);
           setIsLoad(false);
       }
        setIsLoad(false);

    };

    const saveNewPassword = async () =>{
        setIsLoad(true);
        try{
           await axiosRun(config.apiLinks.sendNewPassword, 'POST', {id,email:formData['email']})
            setTextResult("відправлено");
            setIsOpen(true);
            setOpenAlertText('WAS SEND');
            setOpenAlert(true);
        }catch (e) {
            setIsOpen(true);
            setTextResult("помилка відправки. Спробуйте ще");
        }
        setIsLoad(false);
    }

    return (
        <Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoad}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={openAlert}
                autoHideDuration={2000}
                onClose={() => {
                    setOpenAlert(false);
                }}
            >
                <Alert
                    onClose={() => {
                        setOpenAlert(false);
                    }}
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    {openAlertText}
                </Alert>
            </Snackbar>
            <OpenInfoDialog isOpen={isOpen} text={textResult} onYes={()=>setIsOpen(false)} />
            <Box display={"form"} sx={{display: "flex", width: "400px", flexDirection: "column",pl: "2%", pt: "2%", pb:"1%", gap: "10px"}}>
                <TextField
                    error={validError['login']}
                    helperText={validError['login']??''}
                    label="Login"
                    value={formData['login']??""}
                    onChange={(e) => {  setFormData({...formData, login:e.target.value}) }}
                />
                <TextField
                    label="П.І.Б"
                    error={validError['firstName']}
                    helperText={validError['firstName']??''}
                    value={formData['firstName']??""}
                    onChange={(e) =>  setFormData({...formData, firstName:e.target.value}) }
                />
                <TextField
                    label="відділ/посада"
                    error={validError['lastName']}
                    helperText={validError['lastName']??''}
                    value={formData['lastName']??""}
                    onChange={(e) =>  setFormData({...formData, lastName:e.target.value}) }
                />
                <TextField
                    label="Email"
                    error={validError['email']}
                    helperText={validError['email']??''}
                    value={formData['email']??""}
                    onChange={(e) => setFormData({...formData, email:e.target.value}) }
                />
                <FormControl error={validError['roles']}>
                    <InputLabel id={"role"}>Role</InputLabel>
                    <Select
                        value={formData["roles"]??''}

                        labelId={"role"}
                        label={"Role"}
                        onChange={
                        (e) =>
                            {
                                setFormData({...formData, roles:e.target.value});
                            }
                        }
                    >
                        {user.user.roles === "SUPER_ADMIN"&&<MenuItem value="SUPER_ADMIN">Super admin</MenuItem>}
                        <MenuItem value="ADMIN_FACULTY">Admin Faculty</MenuItem>
                        {user.user.roles === "SUPER_ADMIN"&&<MenuItem value="ADMIN_DEPARTMENT">Admin Department</MenuItem>}
                    </Select>
                </FormControl>
                {formData['roles']==='ADMIN_DEPARTMENT'&&<FormControl error={validError['departmentId']}>
                    <InputLabel id={"department"}>Department</InputLabel>
                    <Select
                        value={formData['departmentId']??""}
                        labelId={"department"}
                        label={"Department"}
                        onChange={(e) => setFormData( {...formData, departmentId: e.target.value} )}
                    >
                        {department?.map(item=>{
                            return <MenuItem value={item.id}>{item.name}</MenuItem>
                        })}
                    </Select>
                    {validError['departmentId']&&<FormHelperText>{validError['departmentId']}</FormHelperText>}
                </FormControl>}
                {console.log("validError ", validError)}
                {formData['roles'] !== 'SUPER_ADMIN'&&<FormControl error={validError['faculties']}>
                    <Autocomplete
                    multiple
                    id="faculties"
                    options={faculties}
                    getOptionLabel={(option) => option.name}
                    value={formData?.faculties??[]}
                    onChange={(event, newValue) => {
                        setFormData({...formData, faculties:newValue})
                    }}
                    renderInput={(params) => (
                        <TextField
                            error={validError['faculties']}
                            helperText={validError['faculties']??''}
                            {...params}
                            label="Faculties"
                        />
                    )}
                /></FormControl>}
                <Box sx={{display:'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button sx={{mt: "1%", width:"30%"}} startIcon={<SaveIcon />} variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                    {id !=='new'&&<Button sx={{mt: "1%", width:"65%"}} disabled={(!formData['email'])?true:false} endIcon={<KeyIcon />} variant="contained" color="primary"
                            onClick={saveNewPassword}>
                        send new password
                    </Button>}
                </Box>
            </Box>

        </Paper>
    );
};

export default AdministratorItem;
