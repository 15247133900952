import React, {useState,useEffect} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Collapse, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import {SubTable} from "./SubTable";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {useParams} from "react-router-dom";
import i18n from "../i18n";
import i18next from "i18next";
import {useTranslation} from "react-i18next";

export const Financial = () => {
  const [openRows, setOpenRows] = useState([]);
  const [finReportData, setFinReportData] = useState([]);
  const [isLoad, setIsload] = useState(true);
  const [paymentData, setPaymentData] = useState('');
  const {type} = useParams();
  const { t, i18n } = useTranslation();
  useEffect(()=>{
   const load = async ()=>{
      const data = await axiosRun(config.apiLinks.getUserInfo,'GET');
       const pData = await axiosRun(
           config.apiLinks.getPaymentData.replace('%type%',type==='study'?'paymentRowEducation':'paymentRowAptRent'),
           'GET'
       );
       setPaymentData(pData);

      if (type === 'study'){
          setFinReportData(data?.UserToFaculties.map((item) => {
                  return { name: item.Faculty.name ,data:item.paymentReportStudy }
              }
          ));
          setIsload(false);
      }
      if (type === 'rent'){
          setFinReportData(data.paymentReportRent?[{name: "", data: data.paymentReportRent}]:[]);
          setIsload(false);
      }
    }
    load();
  }, [type])

  const toggleRow = (index) => {
    if (openRows.includes(index)) {
      setOpenRows(openRows.filter(row => row !== index));
    } else {
      setOpenRows([...openRows, index]);
    }
  };

  return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoad}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {!isLoad&&finReportData.length===0&&<Paper sx={{minHeight:"50px",paddingTop:"15px", paddingLeft:"7px"}}>
                <b>{t("financial.no_reports_available")}</b>
            </Paper>}
            {finReportData.map((item, index) => (
                    <SubTable
                        key={index}
                        reportFaculty={item}
                        indexReportFaculty={index}
                        openRows={openRows}
                        toggleRow={toggleRow}
                        pData={paymentData}
                        type={type}
                    />
            ))}
       </>
  );
};
