import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import {SampleReadOnlyForm} from "./UI/SampleReadOnlyForm";
import {Typography} from "@mui/material";
import {Item} from "./UI/Item";
import { ProfileTabs } from "./UI/ProfileTabs";
import Avatar from '@mui/material/Avatar';
import MaleIcon from '../images/male_user_icon.png';
import FemaleIcon from '../images/female_user_icon.png';


import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useTranslation} from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
const theme = createTheme({
    palette: {
      primary: {
       main: "#00A43C",
       text: "#2d3e50"
      },
    },
  });

export const Profile = () =>{
    const [userData,setUserData] = useState({});
    const [isLoad,setIsLoad] = useState(true);
    const { t, i18n } = useTranslation();
    const requiredFieldBlock1 = [
        {fieldType: "text",name:'firstName'},
        {fieldType: "text",name:'secondName'},
        {fieldType: "text",name:'phone'},
        {fieldType: "text",name:'phone'}
    ]
    useEffect( ()=>{
        const getData = async ()=> {
            const userData = await axiosRun(config.apiLinks.getUserInfo, "GET");
            setUserData(userData);
            console.log("user data ", userData);
            setIsLoad(false);
        }
        getData();
        return ()=>{}
    },[])
  

    return (<>
        {!isLoad &&
            <Grid container spacing={1}>
                <Grid item xs={12} md={8} xl={7} order={{xs:2,md:1,xl:1, lg:1}}>
                    {isLoad&&<CircularProgress color="inherit" />}
                    <ProfileTabs data={userData} setUserData={setUserData} />
                </Grid>
                <Grid item xs={12} md={4} xl={5} order={{xs:1,md:2,xl:2,lg:2}} sx={{textAlign: "center", mt: 2}}>
                    {userData?.photo?<Avatar variant="rounded" sx={{  width: {
                                xs: 150,
                                md: 300,
                            }, height:{
                                xs: 200,
                                md: 400
                            }, m: '0 auto'}} src={userData.photo} />:
                   <Avatar
                  
                   variant="rounded"            
                   src={userData?.id_PersonSex === 1 ? MaleIcon:FemaleIcon}
                   sx={{   width: {
                    xs: 150,
                    md: 250,
                  }, height:{
                    xs: 150,
                    md: 250
                  }, m: ' auto'}}
                   imgProps={{
                    loading: 'lazy'
                   }}
                   />}
                    
                  
                </Grid>
            </Grid>
        }

    </>)
}