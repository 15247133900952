import React, {useEffect, useState} from "react";
import GridTable from "../UI/base/GridTable/components/GridTable";
import {config} from "../../config";
import {axiosRun} from "../../utils/axiosWrap";

export const DepartmentList = ()=>{
    const [isLoad,setIsLoad] = useState(true);
    const [departments, setDepartments] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnsForQuick, setColumnsForQuick] = useState([]);


    useEffect( () => {
            const _columns = [
                { id: 'name', numeric: false, disablePadding: false, label: "Назва",isLinkItem:true},
                { id: 'nameEn', numeric: false, disablePadding: false, label: "Назва (англ)",isLinkItem:true},
                { id: 'createdAt', numeric: false, disablePadding: true, label: "Дата створення",isLinkItem:true },
            ];
            const _columnsFormQuick = [
                { id: 'name', numeric: false, disablePadding: false, label: 'Назва',  type: "text" },
                { id: 'nameEn', numeric: false, disablePadding: false, label: 'Назва (англ)',  type: "text" }
            ];
            setColumns(_columns);
            setColumnsForQuick(_columnsFormQuick)

    }, [])

    const  triggerLinks = {
        createLinkSave: null,
        editLinkSave: null,
        refreshLink: 'products',
        linkItem: '/administration/department/item/%itemId%',
        deleteLink: 'products/remove',
        redirectAfterAdd: '/administration/departments/list'
    }

    return <>
        <GridTable
            linkGetData={config.apiLinks.getDepartmentsList}
            columns={columns}
            triggerLinks={triggerLinks}
            columnsFormQuick={columnsForQuick}
            tableHeaderText={"Відділи/департаменти"}
            addLink={ '/administration/department/item/new'}
            isAddLink={true}
            quickViewNotAllowed={true}
            rows4page={25}
        />
        </>

}