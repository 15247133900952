import React, {useState, useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import Paper from "@mui/material/Paper";
import Box from '@mui/material/Box';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {useNavigate} from "react-router-dom";

const GroupItemNew = () => {
    const [load, setIsLoad] = useState(true);
    const [faculties, setFaculties] = useState([]);
    const [selectedFacultySpecSpecialization, setSelectedFacultySpecSpecialization] = useState(null);
    const [name, setName] = useState(' ');
    const navigate = useNavigate();
    const getFaculties = async ()=>{
        setIsLoad(true);
        const _faculties = await axiosRun(config.apiLinks.getFacultiesList, 'GET');
        setFaculties(_faculties);
        console.log(_faculties);
        setIsLoad(false);
    }

    useEffect(() => {
        getFaculties();
    }, []);

    const handleSaveNameResource = async ()=>{
        setIsLoad(true);
        console.log(selectedFacultySpecSpecialization);
        const data = await axiosRun(config.apiLinks.createGroup,'POST', {name, resource: selectedFacultySpecSpecialization.value});
        setIsLoad(false);
        navigate(`/administration/group/list/${data.id}`);
    }

    return (
        <Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={load}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ width: {lg: "100%"}, pl: "25px", pt: "20px", pb: "20px"}} component={"div"}>
                <Box
                    component="form"
                    noValidate
                    sx={
                        {display: 'flex', flexDirection: 'column', rowGap: '10px', mb: '2%'}
                    }
                    autoComplete="off"
                >
                    <TextField
                        sx={{width:'20%'}}
                        id="outlined-controlled"
                        label="Назва"
                        value={name}
                        onChange={(event) => {
                            setName(event.target.value);
                        }}
                    />
                    <Autocomplete
                        sx={{width:'50%',height: 'auto',fontSize: '10pt'}}
                        options={faculties}
                        getOptionLabel={(item) => `${item.name}`}
                        value={selectedFacultySpecSpecialization}
                        onChange={(event, newValue) => {
                            setSelectedFacultySpecSpecialization(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Faculty spec specialization"/>
                        )}
                    />
                    <Button sx={{width:'10%'}} variant="contained" startIcon={<SaveAsIcon />} onClick={handleSaveNameResource}>
                        SAVE
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default GroupItemNew;
