import React from 'react';
import GridTableEagerLoad from "./GridTableEagerLoad";
import GridTableLazyLoad from "./GridTableLazyLoad";

function GridTable(props) {
    const { isLazy } = props;
    return (<>
            {isLazy?<GridTableLazyLoad {...props} />:<GridTableEagerLoad {...props} />}
        </>
    );
}


export default GridTable;
