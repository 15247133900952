import React, {useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../slices/usersSlice";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Logo from "../images/snau_logo.png";
import UkFlag from "../images/uk-flag.svg";
import EnFlag from "../images/en-flag.svg";
import ReCAPTCHA from "react-google-recaptcha";
import {config} from "../config";
import FormHelperText from "@mui/material/FormHelperText";
import { useTranslation } from 'react-i18next';
const theme = createTheme({
  palette: {
    primary: {
      main: "#00A43C",
      text: "#2d3e50",
    },
  },
});

const Copyright = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        SNAU
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export const Login = ({isAdmin}) =>{
    const dispatch = useDispatch();
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const error = useSelector((state) => state.users.error);
    const status = useSelector((state) => state.users.status);
    const captchaRef = useRef(null);
    const { t, i18n } = useTranslation();

  const loginUser = (event) => {
    event.preventDefault();
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    const data = new FormData(event.currentTarget);
      dispatch( userLogin(
          {
              login:data.get('login'),
              password: data.get('password'),
              isAdmin: isAdmin,
              token
          }
      ));

  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ width: 100, height: 100, p: 1, bgcolor: "primary.main" }}
              src={Logo}
            />
            <Typography component="h1" variant="h5" sx={{ marginTop: 1 }}>
              {t('login.signinLabel')}
            </Typography>
            <Box
              component="form"
              onSubmit={loginUser}
              noValidate
              sx={{ mt: 1 }}
            >
              {status === "rejected" && (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">{error?.message}</Alert>
                </Stack>
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t("login.email")}
                name="login"
                autoComplete="login"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t('login.password')}
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {t('login.signin')}
              </Button>
              <Box sx={{mt:"5px", ml: "49px",  flexDirection: "column",
                alignItems: "center",}}>
                <ReCAPTCHA ref={captchaRef} sitekey={config.captchaKey} />
                <FormHelperText key={'captcha'} id="component-error-text">

                </FormHelperText>
              </Box>
              <Grid container
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
              >
                <Grid item >
                  <Link href={`/ua/reset`} variant="body2" color="primary.text">
                    {'Отримати пароль'}
                  </Link>
                </Grid>
                <Grid item>
                      <Link href={`/en/reset`} variant="body2" color="primary.text">
                          {'Get password'}
                      </Link>
                </Grid>
              </Grid>
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                columnSpacing={{ xs: 2}}
                sx={{mt: 3}}
              >
                <Grid item xs={2}>
                  <Link href={"/"}><img width='30px' src={UkFlag} alt="" /></Link>
                </Grid>
                <Grid item xs={2}>
                  <Link href={"/en"}><img width='30px' src={EnFlag} alt="" /></Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </>
  );
};
