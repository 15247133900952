import React, {useEffect, useState} from "react";
import GridTable from "./UI/base/GridTable/components/GridTable";
import {config} from "../config";
import {axiosRun} from "../utils/axiosWrap";

export const FacultiesLinkList = ()=>{

    const [columns, setColumns] = useState([]);
    const [columnsForQuick, setColumnsForQuick] = useState([]);

    useEffect( () => {
            const _columns = [
                { id: 'name', numeric: false, disablePadding: false, label: 'Назва групи', isLinkItem: true },
            ];
            const _columnsFormQuick = [];
            setColumns(_columns);
            setColumnsForQuick(_columnsFormQuick);
    }, [])

    const  triggerLinks = {
        createLinkSave: config.apiLinks.createTemplate,
        editLinkSave: config.apiLinks.editTemplatesItem,
        linkItem: '/administration/faculty/%itemId%',
        redirectAfterAdd: '/administration/group/list/%itemId%'
    }

    return <>
        {<GridTable
            linkGetData={config.apiLinks.getFacultiesLinkList}
            columns={columns}
            triggerLinks={triggerLinks}
            columnsFormQuick={columnsForQuick}
            tableHeaderText={"посилання для факультетів"}
            rows4page={10}
            quickViewNotAllowed={true}
            hideAdd={true}
        />}
        </>

}