import {alpha, makeStyles} from '@mui/styles';

export const useStylesGridDialog = makeStyles((theme) => ({
        dialog: {
            maxHeight: "700px",
        },
        form: {
            display: 'flex',
            flexWrap: 'wrap',
            "@media (min-width: 1140px)":{
                padding: '0 0 0 19px',
            },
            // width: 'fit-content',
            // border: '1px solid #ccc!important',
            // borderRadius: '16px',
            // margin: "10px 10px 10px 10px",
            // minWidth: "300px",
            // backgroundColor: "#f5f5f5"
        },
        formColumn: {
            display: 'flex',
            flexDirection: "column",
            rowGap: "15px",
            maxWidth: "644px",
            margin: '0 auto',
            flex: '0 0 25em'
        },
        formItemColumn: {
            flex: '0 0 100%',
        },
        formItem:{
            flex: '0 0 100%',
            "@media (min-width: 768px)":{
                flex: '0 0 45%',
                margin: '10px !important' 
            },
            "@media (min-width: 1140px)":{
                flex: '0 0 calc(25% - 20px)',
                
            },
            
        },
        dialogForm:{
            display: 'flex',
            flexDirection: 'column',
        },
        formInProgress: {
            display: 'flex',
            flexDirection: 'column',
           
            "@media (min-width: 1140px)":{
                padding: '0 0 0 19px',
            },
            width: 'fit-content',
            // border: '1px solid #ccc!important',
            // borderRadius: '16px',
            // margin: "10px 10px 10px 10px",
            minWidth: "300px",
            opacity: 0.1
            // backgroundColor: "#f5f5f5"
        }
    })

);