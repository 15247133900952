import FormItem from "./FormItem";
import React, { useEffect, useState, useRef, memo } from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { useGridDialogFormList } from "../hooks/GridDialogFormList.hook";
import { fetcher } from "../../helper/fetcher";
import { useStylesGridTableActions } from "../css/useStylesGridTableActions";
import { useStylesGridDialog } from "../css/useStylesGridDialog";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAppBarOutletContent } from "../css/useAppBarOutletContent";
import Paper from '@mui/material/Paper';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import {type} from "@testing-library/user-event/dist/type";
import {useNavigate} from "react-router-dom";


export const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FormRaw = (props) => {
  const classes = useStylesGridTableActions();
  const classesDialog = useStylesGridDialog();
  const appBar = useAppBarOutletContent();
  let history = useNavigate();
  // const [formData, setFormData] = useState({});
  const {
    columnsFormQuick,
    itemWithData,
    additionalEvent,
    link,
    formClassName,
    buttonClassName,
    tables,
    lockedProps,
  } = props;
  const { entitiesList, isLoadEntitiesList } =
    useGridDialogFormList(columnsFormQuick);

  const [openAlert, setOpenAlert] = useState(false);
  const formData = useRef({});
  const [validationError, setValidationError] = useState({});


  const saveForm = (event, rowId) => {
    event.preventDefault();
    console.log("data", formData);
    fetcher(link, "POST", { id: rowId, ...formData.current[rowId] }).then(
      (data) => {
          console.log("data", data);
          if (additionalEvent) {
          additionalEvent(data.id);
        }
        setTimeout( ()=>{
         setOpenAlert(true);
        }, 800 );
        setValidationError({});
      }
    ).catch( (error) =>{
        console.log("error ", error);
        const errors = {};
        Object.keys(error.response.data).forEach( (itnx) =>{
          const item = error.response.data[itnx];
          console.log("item index", itnx, item);
          errors[itnx] = item.join(' ,');
        })
      setValidationError(errors);
    });
  };



  return (
    <>

      

          <Box
            sx={{ opacity: props.isLoadData ? "0.05" : "1" }}
            component={"div"}
          >
            <Snackbar
              open={openAlert}
              autoHideDuration={1000}
              onClose={() => {
                setOpenAlert(false);
              }}
            >
              <Alert
                onClose={() => {
                  setOpenAlert(false);
                }}
                severity="success"
                sx={{ width: "100%" }}
              >
                Saved
              </Alert>
            </Snackbar>
            {
              <form
                key={"quick_form_add"}
                className={formClassName ? classesDialog.dialogForm : classesDialog.form}
              >
                {!isLoadEntitiesList &&
                  columnsFormQuick.map((itm) => {
                    console.log("item 127 ", itm);
                    return (
                      <FormItem
                        keyForm={
                          Object.keys(itemWithData).length ? itemWithData.id : 0
                        }
                        errorsFromServer={validationError}
                        formData={formData}
                        itemColumn={itm}
                        entitiesList={entitiesList}
                        itemWithData={itemWithData}
                        lockedProps={lockedProps}
                        isDiaolog={formClassName ? true : false}
                      />
                    );
                  })}
              </form>
            }
          </Box>
          <Box sx={{ marginTop: "20px", textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              
              onClick={(e) =>
                saveForm(
                  e,
                  Object.keys(itemWithData).length ? itemWithData.id : 0
                )
              }
              className={
                buttonClassName ? buttonClassName : classes.buttonMiddle
              }
              startIcon={<SaveIcon />}
            >
              {`Save`}
            </Button>
          </Box>
    </>
  );
};

const check2Objects = (obj1, obj2) => {
      if  ( JSON.stringify(obj1) == JSON.stringify(obj2)){
        return true;
      }else{
        return false;
      }
}

const areEqual = (prev, next) => {
  let result = true;

  if ( !check2Objects(prev.itemWithData,next.itemWithData) ) {
    console.log('ARE EQUAL : 189', prev.itemWithData, next.itemWithData);
    return false;
  }

  if ( !check2Objects(prev.columnsFormQuick,next.columnsFormQuick) ) {
    console.log("are equal");
    return false;
  }

  if (prev.isLoadData != next.isLoadData) {
    return false;
  }

  console.log("PREV NEXT ", prev, next);
  return result;
};

export default memo(FormRaw, areEqual);