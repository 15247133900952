import React from 'react';
import { useTranslation } from "react-i18next";
import { Typography, Button, Link } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const Error403Page = () => {

    const Item = styled(Box)(({ theme }) => ({
        width: '100%',
        textAlign: 'center'
    }));

    const { t } = useTranslation();
    return (

        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center',
                flexWrap: 'wrap',
                height: '100vh',
            }}
        >

            <Typography variant="h2" component="div" sx={{ width: '100%', textAlign: 'center', fontSize: '10rem',fontWeight: 700 }}>
                403
            </Typography>
            <Item component="p">
                {t('error.access_denied')}
            </Item>
            <Item>
                {t('error.page_403_description')}
            </Item>

        </Box>
    );
}

export default Error403Page;
