import React from 'react';
import ErrorPage from './ErrorPage';
import Error404Page from './Error404page';
import Error401Page from './Error401page';
import Error403Page from './Error403page';
import Error500Page from './Error500page';
import Error410page from "./Error410page";
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, message: '' };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({message:error.message});
        console.log("ERROR ", error);
        // console.log("this state", this.state);
    }

    render() {
        if (this.state.hasError && this.state.message) {
            console.log("error ", typeof this.state.message);
            if ( this.state.message === '401' ){
                 return <Error401Page />
            }else if ( this.state.message === '403' ){
                return <Error403Page />
            }else if ( this.state.message === '500' ){
                return <Error500Page />
            }
            else if ( this.state.message === '404' ){
                return <Error404Page />
            }
            else if ( this.state.message === '410' ){
                return <Error410page />
            }
            else{
                return <ErrorPage message={this.state.message} />;
            }
          // return <Error404Page />
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
