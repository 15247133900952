
import axios from 'axios';
import {config} from "../config";
import {handleResponse} from "./responser";
import {useState,useCallback} from "react";

const useAsyncError = () => {
    const [_, setError] = useState();
    return useCallback(
        e => {
            setError(() => {
                throw e;
            });
        },
        [setError],
    );
};



export const instanceAxious = axios.create({
    timeout: 1000000,
    headers: {
        post: {
            "Content-Type": "multipart/form-data"
        }
    }
});

export const axiosRun = async ( url, method, body={}, isBlobResponse = false )=>{

    const userData = JSON.parse(localStorage.getItem('userInfo'))??{};
    instanceAxious.defaults.baseURL = config.apiUrl;
    instanceAxious.defaults.headers.common['Authorization'] = userData.token??{};
    console.log("defaults ", instanceAxious.defaults.responseType);
    if (isBlobResponse){
        instanceAxious.defaults.responseType = 'blob';
    }else{
        instanceAxious.defaults.responseType = 'json';
    }
    console.log("url " , isBlobResponse, url, config.apiUrl);

    const jsonToFormData = (data)=>{
        const formData = new FormData();
        buildFormData(formData, data);
        return formData;
    }

    const  buildFormData = (formData, data, parentKey)=>{
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;
            formData.append(parentKey, value);
        }
    }

    let response = {}
     try{
        switch (method){
            case "POST":
                const formData = new FormData();
                Object.keys( body ).forEach( (key)=>{
                    if ( body[key] instanceof  Blob){
                        formData.append(key, body[key])
                    }else if (typeof body[key] === 'object' && body[key] !== null ){
                        let isFile = true;
                        console.log("key body", key, body, body[key], typeof body[key] === "object");
                        Object.keys( body[key] ).map( inx =>{
                            const item = body[key][inx];
                            if ( item instanceof File){
                                formData.append( key, item, item.name )
                            }else{
                                isFile = false;
                            }
                        })
                        if (!isFile){
                            formData.append( key, body[key] );
                        }
                    }else{
                        formData.append( key, body[key] );
                    }
                });
               response = await instanceAxious.post(url,formData);
                break;
            case "GET":
                response = await instanceAxious.get(url);
                break;
            case "PUT":
                response = await instanceAxious.put(url, jsonToFormData(body));
                console.log("response ", response);
        }
    }catch (error){
        console.log("error ", error);
        if (error.response.status === 403 || error.response.status === 401){
            localStorage.removeItem('userInfo');
            window.location = '/logout';
        }else{
            throw { "data":error.response.data, 'status': error.response.status }
        }
    }
    return response.data;
}

export const axiosPromiseRun = ( url, method, body ) =>{
    const userData = JSON.parse(localStorage.getItem('userInfo'))??{};
    instanceAxious.defaults.baseURL = config.apiUrl;
    instanceAxious.defaults.headers.common['Authorization'] = userData.token??{};

    let response = {};
    switch (method){
        case "POST":
            const formData = new FormData();
            Object.keys( body ).forEach( (key)=>{
                formData.append( key, body[key] );
            });
            return instanceAxious.post(url,formData).then(response =>{
                return handleResponse(response )
            });
            break;
        case "GET":
           return instanceAxious.get(url).then(response =>{
                return handleResponse(response )
            });
            break;
        case "PUT":
           return instanceAxious.put(url, body).then(response =>{
            handleResponse(response )
        });;
    }

}