import React, {useState, useEffect} from 'react';
import {getPropByString} from "../components/GridTable";
// import {fetcher} from "../../../../helpers/fetcher";
import {fetcher} from "../../helper/fetcher";
export const useGridDialogFormList = (columnsFormQuick) =>{
    const [entitiesList, setEntitiesList] = useState([]);
    const [isLoadEntitiesList, setIsLoadEntitiesList] = useState(true);

    useEffect( ()=>{
        const filters = columnsFormQuick.filter(itm=>itm.type === 'entityList' && itm.trigger);
        let entitiesList = [];
        if (filters.length){
            Promise.all( filters.map ( (itm)  =>
                new Promise( (resolve,reject) =>{
                    fetcher(itm.trigger, 'GET').then(data =>{
                        entitiesList[itm['entityName']] = data;
                        resolve({[itm['entityName']]:data});
                    })
                }))
            ).then( data =>{
                let entitiesList = {}
                data.map( itemList =>{
                    const entityName = Object.keys(itemList)[0];
                    entitiesList[entityName] = itemList[entityName];
                })
                setEntitiesList(entitiesList);
                setIsLoadEntitiesList(false);
            })
        }else{
            setIsLoadEntitiesList(false);
        }


    }, [columnsFormQuick])

    return {entitiesList,isLoadEntitiesList}

}