
import Routing from "./Routing/Routing";
import ErrorBoundary from "./components/ErrorBoundary";
import AxiosErrorHandler from "./utils/AxiosErrorHandler";

function App() {
  return (
      <ErrorBoundary>

             <Routing />

      </ErrorBoundary>
  );
}

export default App;
