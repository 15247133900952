import React, { useRef ,useState } from "react";
import {config} from "../../../config";
import {FormUserWrapper} from "../FormUserWrapper";


export const Register = () => {

    const fields = {
        'firstName': { 'label':'reg.name', 'isEmpty': false,'isRenderWithAll': true },
        'lastName': { 'label':'reg.secondName', 'isEmpty': false,'isRenderWithAll': true },
        'middleName': { 'label':'reg.middleName', 'isEmpty': false,'isRenderWithAll': true },
        'email': { 'label':'reg.email', 'isEmpty': false,'isRenderWithAll': true },
        'ipnNumber': { 'label':'reg.inn', 'isEmpty': false,'isRenderWithAll': true },
        'documentSeries': { 'label':'reg.documentSeries', 'isEmpty': false,'isRenderWithAll': true },
        'documentNumbers': { 'label':'reg.documentNumbers', 'isEmpty': false,'isRenderWithAll': true },
        'phone': {'label': 'phone', 'isEmpty': false, 'isRenderWithAll': false}
    }


    return (
        <>
            <FormUserWrapper fields={fields} submitLink={config.apiLinks.registerUser} logoText={"Register"} />
        </>
    );
}