import React, { useEffect, useState } from "react";
import { axiosRun } from "../utils/axiosWrap";
import { config } from "../config";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),

    color: '#222',
    maxHeight: '80vh',
    overflow: 'auto'
}));

export const LogItem = (props) => {
    const { id } = useParams();
    const [item, setItem] = useState(null);
    const [isLoad, setIsLoad] = useState(true);

    useEffect(() => {
        const getData = async () => {
            const logData = await axiosRun(config.apiLinks.getLogItem.replace('%id%', id), "GET");
            setItem(logData);
            setIsLoad(false);
        }
        getData();
    }, [])

    return (<>
        {!isLoad && <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
            <Paper sx={{ p: 3, mb:3 }}>
                <Stack direction='row' justifyContent="space-between" alignItems="center">
                    <Box component='div' >
                        <Typography variant="h4" component="div" >{`Log number #${item.id} info`}</Typography>
                    </Box>
                    <Box component='div'>
                        <Stack direction='row' spacing={1}>
                            {item.importType !== 4 && (
                                <>
                                    <Chip label={`Додано - ${item.countInserted}`} color='success' />
                                    <Chip label={`Оновлено - ${item.countUpdated}`} color='primary' />
                                    <Chip label={`Помилок -  ${item.countError}`} color='error' />
                                </>
                            )}

                            <Chip label={`Лог номер - ${item.hash}`} />
                        </Stack>
                    </Box>
                </Stack>


            </Paper>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6} xl={6}>
                    <Item><div><pre>{JSON.stringify(item.logs, null, 2)
                        .replaceAll("[", "")
                        .replaceAll("{", "")
                        .replaceAll("}", "")
                        .replaceAll("]", "")
                        .replaceAll('"', "")
                        .replaceAll(',', "")}</pre></div></Item>
                </Grid>
                <Grid item xs={6} md={6} xl={6}>
                    <Item><div><pre>{JSON.stringify(item.errors, null, 2)
                        .replaceAll("[", "")
                        .replaceAll("{", "")
                        .replaceAll("}", "")
                        .replaceAll("]", "")
                        .replaceAll('"', "")
                        .replaceAll(',', "")}</pre></div></Item>
                </Grid>
            </Grid>
        </Box>}
    </>)

}