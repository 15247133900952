import React, {useState} from 'react';
import {
    FormControl,
    InputLabel,
    Input,
    Paper,
    Button,
    TextField,
    Autocomplete,
    Chip,
    Box,
    Typography
} from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {axiosRun} from "../../utils/axiosWrap";
import {useNavigate, useParams} from "react-router-dom";
import {config} from "../../config";

const DepartmentItem = ({isNewItem}) => {
    const [specialtyList, setSpecialtyList] = useState([]);
    const [specializationList, setSpecializationList] = useState([]);
    const [departmentItem, setDepartmentItem] = useState({});
    const [isLoad, setIsLoad] = useState(true);
    const {id} = useParams();
    let navigate = useNavigate();
    useState(() => {
            (async () => {
                if (id !== 'new') {
                    const _itmD = await axiosRun(config.apiLinks.getDeparmentItem.replace("%id%", id), 'GET');
                    setDepartmentItem(_itmD);
                }
                const _speciality = await axiosRun(config.apiLinks.getSpecialityList, 'GET');
                setSpecialtyList(_speciality);
                setSpecializationList(await axiosRun(config.apiLinks.getSpecializationList, 'GET'));
                setIsLoad(false);
            })();
    }, [id]);
    const handleSave = async ()=>{
        setIsLoad(true);
        const item = await axiosRun(config.apiLinks.addOrEditDepartment, "PUT", departmentItem)
        setDepartmentItem( item );
        if (id ==='new'){
            navigate(`/administration/department/item/${item.id}`);
        }
        setIsLoad(false);
    }
    return (
        <Paper>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoad}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Box display={"div"} sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography sx={{pl: "5%", mt: "1%"}} variant="h4">Форма відділу</Typography>
                <Box sx={{width: '40%', pl: '5%', mb: "2%"}}>
                    <Box display={"form"} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mt: '3%',
                        pl: "2%",
                        pr: "2%",
                        pb: "3%"
                    }}>
                        <FormControl fullWidth>
                            <TextField
                                id="name-input"
                                label={"Name"}
                                value={departmentItem.name??''}
                                margin={"normal"}
                                onChange={(e) => setDepartmentItem({...departmentItem,'name':e.target.value})}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                id="nameEn-input"
                                label={"name (Eng)"}
                                value={departmentItem.nameEn??''}
                                margin={"normal"}
                                onChange={(e)  => setDepartmentItem({...departmentItem,'nameEn':e.target.value})}
                            />
                        </FormControl>
                        {id!=='new'&&(<>
                            <Autocomplete
                                multiple
                                id="specialty-autocomplete"
                                options={specialtyList}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => {
                                    setDepartmentItem({...departmentItem,'Specialities':newValue})
                                    console.log("new value ", newValue);
                                }}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField {...params} label="Specialty" placeholder="Select specialty"/>
                                )}
                                value={departmentItem?.Specialities??[]}
                                defaultValue={[]}
                                fullWidth
                                margin={"normal"}
                            />
                                <Autocomplete
                                    multiple
                                    id="specialization-autocomplete"
                                    options={specializationList}
                                    filterSelectedOptions
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        setDepartmentItem({...departmentItem,'Specializations':newValue})
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Specialization" placeholder="Select specialization"/>
                                    )}
                                    value={departmentItem.Specializations??[]}
                                    fullWidth
                                    sx={{mt:"2%"}}
                                    margin={"normal"}
                                />
                            </>
                        )}

                        <Button sx={{mt: "2%", width: "30%"}} onClick={handleSave}  type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
};

export default DepartmentItem;
