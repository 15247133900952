import React, {useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Collapse,
    IconButton
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Tab from "@mui/material/Tab";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Button from '@mui/material/Button';
import {shallowEqual, useSelector} from "react-redux";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {DialogWithPayments} from "./sub/DialogWithPayments";


const useStyles = makeStyles((theme) => ({
    customTable: {
        "& .MuiTableCell-sizeMedium": {
            padding: "6px" // <-- arbitrary value
        }
    },
    customRow: {
            backgroundColor: "#f5f5f5",
            fontStyle: "oblique",
            fontWeight: "medium",

    },
    link: {
        "& :link":{ color: "#000" },
        "& :visited":{color: "#757575"}
    }
}));
export const SubTable = ({reportFaculty, indexReportFaculty, openRows, toggleRow,isLoad,pData,type}) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const user = useSelector(state=>state.users.user, shallowEqual);
    const [showPaymentDialogData,setShowPaymentDialogData] = useState({});
    const [open, setOpen] = useState(false);

    console.log("user user ", user);

    const generateGoogleCalendatEvent = ( date, sum ) =>{
        const base = 'https://calendar.google.com/calendar/render';
        const dateArr = date.split('.');
        const text = t('message.payNotification', {date, sum});
        const textDetails = t('message.payNotification', {date, sum})
        return `${base}?action=TEMPLATE&text=${text}&dates=${dateArr[2]}${dateArr[1]}${parseInt(dateArr[0])-1}/${dateArr[2]}${dateArr[1]}${dateArr[0]}&details=${textDetails}`;
    }

    const showDialogWithPayment = (data)=>{
        setShowPaymentDialogData(data);
        setOpen(true);
    }

    const generateTextForPayment = ( report ) =>{
        let payData = [];
        const otherData = report.mainInfo.stringEducationPlace.split(", ");
        report.rows.forEach(coursesRows =>{
            coursesRows.rowsReport.forEach(row =>{
                if (row.column1 === 'Оплатити до'){
                    const sum = row.column3;
                    const course = coursesRows.courseName;
                    const date = row.column2;
                    payData.push({sum,course,date});
                }
            })
        })

        if (payData.length>0){
            console.log("paydata.length ", payData.length);
            payData.sort( (a,b)=>{
                const _tmpAr = a.date.split('.');
                const dateA = new Date(`${_tmpAr[2]}-${_tmpAr[1]}-${_tmpAr[0]}`);
                const _tmpArB = b.date.split('.');
                const dateB = new Date(`${_tmpArB[2]}-${_tmpArB[1]}-${_tmpArB[0]}`);

                if (dateA.getTime() < dateB.getTime()){
                    return -1;
                }else{
                    return 1
                }
            })
            const earlyData = payData[0]??{};
            const dateNow = new Date();
            if (type === 'study'){
                const paymentFor = otherData[otherData.length-1];
                const faculty = otherData[1];
                const speciality = otherData[2];
                const specialization = otherData.length == 5?otherData[3]:'';
                return {
                    firstName:user.user.firstName,
                    lastName:user.user.lastName,
                    middleName:user.user.middleName,
                    pDateText: pData.text,
                    dateGenerate:`${dateNow.getFullYear()}-${dateNow.getMonth()+1}-${dateNow.getDate()}`,
                    course: earlyData.course,
                    sum: earlyData.sum,
                    paymentFor,
                    faculty,
                    speciality,
                    specialization,
                    numberDeal:report.mainInfo.numberDeal,
                    codeFaculty: report.mainInfo.codeFaculty
                }
            }else{
                return {}
            }
        }else{
            return {}
        }
    }


    return (<React.Fragment>
        <DialogWithPayments paymentData={showPaymentDialogData} open={open} onOpen={setOpen} />
        <TableContainer sx={{mb: "10px",pl:"0"}} component={Paper}>
            <Table className={classes.customTable}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell colspan={2}><b>{reportFaculty.name}</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reportFaculty.data && reportFaculty.data.map((reportFaculty, indexReportFaculty) => {
                        console.log("item ", reportFaculty);
                        const data = generateTextForPayment(reportFaculty);
                        //console.log("data.length", data);
                        return <React.Fragment key={indexReportFaculty}>
                            <TableRow>
                                <TableCell>
                                    <IconButton size="small" onClick={() => toggleRow(indexReportFaculty)}>
                                        {openRows.includes(indexReportFaculty) ? <KeyboardArrowUpIcon/> :
                                            <KeyboardArrowDownIcon/>}
                                    </IconButton>
                                </TableCell>
                                <TableCell colspan={2}>{reportFaculty.mainInfo.numberDeal}<br/>{reportFaculty.mainInfo.stringEducationPlace}
                                    <br />
                                    {Object.keys(data).length>0&&<IconButton onClick={()=>showDialogWithPayment(data)} color="primary" aria-label="add to shopping cart">
                                        <ShoppingCartIcon/>
                                    </IconButton>}

                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={3}>
                                    <Collapse in={openRows.includes(indexReportFaculty)} timeout="auto" unmountOnExit>
                                        <Table>
                                            <TableBody>
                                                {reportFaculty.rows.map((row, rowInx) => {
                                                    return <TableRow key={rowInx}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell width={"33.3%"} align={"left"}><b>{row.courseName}</b></TableCell>
                                                                    <TableCell width={"33.3$"}></TableCell>
                                                                    <TableCell width={"33.4%"} align={"right"}><b>{row.totalPayed}</b></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {row.rowsReport.map((itmRR, inxRR) => {
                                                                    let isLink = false;
                                                                    let link = null;
                                                                    if (itmRR.column1==='Оплатити до'){
                                                                        isLink = true;
                                                                        link = generateGoogleCalendatEvent(itmRR.column2,itmRR.column4||itmRR.column3);
                                                                    }
                                                                    if (link){
                                                                        return <TableRow className={classes.customRow}>
                                                                            <TableCell  width={"40%"}  align={"left"}>{"Оплата до"}<IconButton sx={{ml:"3px",width:'19px'}} target={"_blank"} variant="outlined" href={link}>
                                                                                <CalendarMonthIcon />
                                                                                </IconButton></TableCell>
                                                                            <TableCell  width={'30%'} className={classes.link}  align={"center"}><a target={"_blank"} href={link}>{itmRR.column2}</a></TableCell>
                                                                            <TableCell  width={'30%'}  align={"right"}>{itmRR.column4||itmRR.column3}</TableCell>
                                                                        </TableRow>
                                                                    }else{
                                                                       return <TableRow>
                                                                            <TableCell  width={"33.3%"}  align={"left"}>{!isLink?itmRR.column1:<a href={link}>{itmRR.column1}</a>}</TableCell>
                                                                            <TableCell  width={'33.3%'}  align={"center"}>{itmRR.column2}</TableCell>
                                                                            <TableCell  width={'33.3%'}  align={"right"}>{itmRR.column4||itmRR.column3}</TableCell>
                                                                        </TableRow>
                                                                    }
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    })}
                    {!reportFaculty.data&&<TableRow>
                            <TableCell></TableCell>
                            <TableCell>
                                {t("financial.noDataAvailableForThisFaculty")}
                            </TableCell>
                        </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
    </React.Fragment>)
}


