import React, {useState, useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import {useParams} from "react-router-dom";
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import {UserGroupList} from "./UserGroupList";
import Paper from "@mui/material/Paper";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {OpenAlertDialog} from "./OpenAlertDialog";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {MenuItem} from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from '@mui/material/FormControl';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

const GroupItem = () => {
    const [open, setOpen] = useState(false);
    const [openRemoveStudent, setOpenRemoveStudent] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState([]);
    const [selectedStudentAdd, setSelectedStudentAdd] = useState([]);
    const [load, setIsLoad] = useState(true);
    const [isRemoveWhenAdd, setIsRemoveWhenAdd] = useState(false);
    const [groupItem, setGroupItem] = useState({});
    const [name, setName] = useState(' ');
    const [faculties, setFaculties] = useState([]);
    const [degrees, setDegress] = useState([]);
    const {id} = useParams();
    const [selectedFacultySpecSpecialization, setSelectedFacultySpecSpecialization] = useState(null);
    const [studyType, setStudyType] = useState(0);
    const [degreesType, setDegreesType] = useState(0);
    const [dateFinish, setDateFinish] = useState('01-01-2026');
    const [adminGroupLead, setAdminGroupLead] = useState(null);
    const [usersGroupClear, setUsersGroupClear] = useState([]);
    const [usersToGroup, setUsersToGroup] = useState([]);
    const [studentsForAdding, setStudentsForAdding] = useState([]);
    const [searchPhrase, setSearchPhrase] = useState('');

    useEffect( ()=>{
            (async () =>{
                if (searchPhrase.length > 2){
                    const studentsForAdding = await axiosRun(config.apiLinks.studentsForAdding+`?phrase=${searchPhrase}&id=${id}`, 'GET');
                    setStudentsForAdding(studentsForAdding);
                }else{
                    setStudentsForAdding([]);
                }
            })();
    }, [searchPhrase])

    const getData = async (updateStudentsOnly = false) => {
        const _groupItem = await axiosRun(config.apiLinks.getGroupItem.replace('%id%', id), 'GET');
        const degress = await axiosRun(config.apiLinks.getDegreesList, 'GET');
        setUsersToGroup(_groupItem.data.UserToFaculties);
        setUsersGroupClear(_groupItem.data.UserToFaculties.map(user=>user.User).filter(user=>user.email));
        setDegress(degress);
        setIsLoad(false);

        if (!updateStudentsOnly){
            setGroupItem(_groupItem.data);
            setName(_groupItem.data.name);
            setStudyType(_groupItem.data.studyType);
            setDegreesType(_groupItem.data.degreesId);
            setDateFinish(_groupItem.data.dateFinish);
            setAdminGroupLead(_groupItem.data.User);
            setUsersGroupClear(_groupItem.data.UserToFaculties.map(user=>user.User).filter(user=>user.email));
            console.log("stuident", _groupItem.facultyStudents);
        }
    }

    const getFaculties = async ()=>{
        const faculties = await axiosRun(config.apiLinks.getFacultiesList, 'GET');
        setFaculties(faculties);
    }

    // Fetch students list from API
    useEffect(() => {
        getFaculties();
        getData();
    }, [id]);

    useEffect( ()=>{
        if (faculties.length > 0 && Object.keys(groupItem).length > 0 ){
            const findString = groupItem.specializationId?`${groupItem.facultyId}-${groupItem.specialityId}-${groupItem.specializationId}`:`${groupItem.facultyId}-${groupItem.specialityId}`;
            const item = faculties.find(item=>item.value===findString);
            setSelectedFacultySpecSpecialization(item);
        }
    }, [faculties,groupItem ] )

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        setIsLoad(true);
        console.log("seleted for add", selectedStudentAdd);
        const addId = selectedStudentAdd.map(itm=>itm.id);
        await axiosRun(config.apiLinks.addStudentToGroup.replace('%id%', groupItem.id), 'POST', {addId, isRemoveWhenAdd});
        await getData(true);
        setSelectedStudentAdd([])
        setOpen(false);
        setIsLoad(false);
    };

    const deleteStudentsFromGroup = () =>{
        setOpenRemoveStudent(true);
    }

    const removeStudentsFromGroup = async ()=>{
        setIsLoad(true);
        await axiosRun( config.apiLinks.removeStudentsFromGroup, 'POST', {selectedStudent} );
        await getData(true);
        setSelectedStudent([]);
        setIsLoad(false);
    }

    const handleSaveNameResource = async ()=>{
        setIsLoad(true);
        console.log('3',adminGroupLead?adminGroupLead.id:null);
        await axiosRun(config.apiLinks.saveNameResource,'POST',
            {name,
                resource: selectedFacultySpecSpecialization.value,
                id:groupItem.id,
                dateFinish,
                studyType,
                degreesType,
                groupLeadId: adminGroupLead?adminGroupLead.id:null
            });
        await getData();
        setIsLoad(false);
    }

    return (
        <Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={load}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ width: {lg: "100%"}, pl: "25px", pt: "20px", pb: "20px"}} component={"div"}>
                <OpenAlertDialog isOpen={openRemoveStudent} onClose={
                    ()=>{
                        setOpenRemoveStudent(false);
                        setSelectedStudent([]);
                    }
                } onNo={ ()=> {
                        setOpenRemoveStudent(false);
                    }
                } onYes={async ()=>{
                    await removeStudentsFromGroup();
                    setOpenRemoveStudent(false)
                }} text={"Ви впенені що хочете видалити? "} />

                <Box
                    component="form"
                    noValidate
                    sx={
                        {display: 'flex', flexDirection: 'column', rowGap: '10px', mb: '2%'}
                    }
                    autoComplete="off"
                >
                    <TextField
                        sx={{width:'20%'}}
                        id="outlined-controlled"
                        label="Назва"
                        value={name}
                        onChange={(event) => {
                            setName(event.target.value);
                        }}
                    />
                    <Autocomplete
                        sx={{width:'50%',height: 'auto',fontSize: '10pt'}}
                        options={faculties}
                        getOptionLabel={(item) => `${item.name}`}
                        value={selectedFacultySpecSpecialization}
                        onChange={(event, newValue) => {
                            setSelectedFacultySpecSpecialization(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Факультет спеціальнсіть спеціалізація"/>
                        )}
                    />
                    <Autocomplete
                        sx={{width:'50%',height: 'auto',fontSize: '10pt'}}
                        options={usersGroupClear}
                        getOptionLabel={(student) => {
                           return  `${student.firstName} ${student.lastName} ІПН (${student.ipnNumber})`
                        }
                        }
                        value={adminGroupLead}
                        onChange={(event, newValue) => {
                            setAdminGroupLead(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Староста (тільки студент з email)"/>
                        )}
                    />
                    <FormControl fullWidth>
                    <InputLabel id="studyType">
                        {"Тип навчання"}
                    </InputLabel>
                    <Select name={'studyType'}
                            labelId={'studyType'}
                            label={'Тип навчання'}
                            onChange={e=>setStudyType(e.target.value)}
                            value={studyType}
                            sx={{ width: '50%', minWidth: '150px'}}
                            MenuProps={{
                                sx:{maxHeight: '200px'}
                            }}
                    >
                        <MenuItem value={0}>{"не вибрано"}</MenuItem>
                        { [{id:1, name:"Денна"},{id:2, name: "Заочна"}].map(itm=>{
                            return <MenuItem value={itm.id}>{itm.name}</MenuItem>
                        }) }
                    </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="studyType">
                            {"Ступінь навчання"}
                        </InputLabel>
                        <Select name={'degreesType'}
                                labelId={'degreesType'}
                                label={'Тип навчання'}
                                onChange={e=>setDegreesType(e.target.value)}
                                value={degreesType}
                                sx={{ width: '50%', minWidth: '150px'}}
                                MenuProps={{
                                    sx:{maxHeight: '200px'}
                                }}
                        >
                            <MenuItem value={0}>{"не вибрано"}</MenuItem>
                            { degrees.map(itm=>{
                                return <MenuItem value={itm.id}>{itm.name}</MenuItem>
                            }) }
                        </Select>
                    </FormControl>
                    <FormControl sx={{width: '50%', minWidth: '150px'}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker
                                placeholder="01.01.2026"
                                value={dateFinish}
                                label={"Дата закінчення навчання"}
                                onChange={(newValue) => {
                                    setDateFinish(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} sx={{ mb: 2 }} />}
                            />
                        </LocalizationProvider>
                    </FormControl>

                    <Button sx={{width:'10%'}} variant="contained" startIcon={<SaveAsIcon />} onClick={handleSaveNameResource}>
                        SAVE
                    </Button>
                </Box>


                <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item>
                        <Button variant="contained" startIcon={<PersonAddAlt1Icon />} onClick={handleClickOpen}>
                            Add Student
                        </Button>
                    </Grid>
                    
                    <Grid item>
                        <Button variant="contained"  disabled={selectedStudent.length===0} onClick={deleteStudentsFromGroup} startIcon={<DeleteIcon />}>
                            Delete from group
                        </Button>
                    </Grid>

                </Grid>

                <UserGroupList setSelectedStudent={setSelectedStudent} load={load} userList={usersToGroup} />

                {!load && <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Add Student</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Select a student from the list:
                        </DialogContentText>
                        <FormControlLabel checked={isRemoveWhenAdd}
                                          onChange={()=>{setIsRemoveWhenAdd(!isRemoveWhenAdd)}} required control={<Checkbox />} label="Видаляти з іншої групи" />

                        <Autocomplete
                            multiple
                            options={studentsForAdding}
                            defaultValue={[]}
                            filterOptions={(x)=>x}
                            onInputChange={ (event, newInputValue)=>{
                                setSearchPhrase(newInputValue);
                            }}
                            getOptionLabel={(student) => `${student.firstName} ${student.lastName} ІПН (${student.ipnNumber})`}
                            value={selectedStudentAdd}
                            onChange={(event, newValue) => {
                                setSelectedStudentAdd(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Student"/>
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSave} disabled={!selectedStudentAdd}>Save</Button>
                    </DialogActions>
                </Dialog>}
            </Box>
        </Paper>
    );
};

export default GroupItem;
