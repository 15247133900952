import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography, List, ListItem, ListItemText } from '@mui/material';
import CheckboxList from "../sub/CheckboxList";

export const RenderFields = ({ fields,setChecked,checked,setClickedForEdit}) => {
    return (
        <div>
            <CheckboxList setChecked={setChecked} checked={checked} list={fields} setClickedForEdit={setClickedForEdit} />
        </div>
    );
};
