import React, {useEffect, useState} from "react";
import GridTable from "./UI/base/GridTable/components/GridTable";
import {config} from "../config";
import {axiosRun} from "../utils/axiosWrap";
import {Form} from "./UI/base/Form/components/Form";
import {
    useParams
} from "react-router-dom";
import Paper from "@mui/material/Paper";

import {StudentTabEducation} from "./StudentTabEducation";
import CircularProgress from "@mui/material/CircularProgress";
import Box from '@mui/material/Box';
import SendEmail from "./SendEmail";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import InfoIcon from '@mui/icons-material/Info';


export const TemplateForm = ()=>{
    const {id} = useParams();
    const [item, setItem] = useState({});
    const [isLoad, setIsLoad] = useState(true);
    const [faculties, setFaculties] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [columns, setColumns] = useState([]);
    const { t } = useTranslation();

    useEffect(()=>{
        if (id && isLoad){
            const getData = async () => {
                const itemTemplate = await axiosRun( config.apiLinks.getTemplateItem.replace("%id%",id), 'GET' )
                setItem(itemTemplate);
            }
            const loadAllReq = async ()=> {
                const _faculties = await axiosRun(config.apiLinks.getFacultiesListClear, 'GET');
                setFaculties(_faculties);
                const _departments = await axiosRun(config.apiLinks.getDepartmentsList, 'GET');
                setDepartments(_departments);
            };

            getData();
            loadAllReq();
        }
    }, [id,isLoad])

    useEffect(()=>{
        if (Object.keys(item).length>0 && Object.keys(faculties).length>0 && Object.keys(departments).length>0){
            setIsLoad(false);
        }
    }, [item,faculties,departments])

    //when all data loaded config columns
    useEffect( () => {
        if (!isLoad && Object.keys(faculties).length>0){

            const columns_ = [
                { id: 'name', numeric: false, disablePadding: false, label: 'Назва шаблону', isLinkItem: true, type: "text" },
                { id: 'description', numeric: false, disablePadding: true, label: "Повідомлення зверення", isLinkItem: true, type: "ckeditor" },
                { id: 'adminDescription', numeric: false, disablePadding: true, label: "Інструкція для деканата", isLinkItem: true, type: "textMultiline"},
                {
                    id: 'type', label: "Тип", isRequired: true, entityName: "type",
                    entityIdentify: "type",
                    displayType: "Select",
                    type: 'entityList',
                    entityList: [
                        { id: 0, name: "Звернення" },
                        { id: 1, name: "Заява" }
                    ]
                },
                { id: 'facultyId', label: "Факультет", isRequired: true, entityName: "facultyId",
                    entityIdentify: "facultyId",
                    displayType: "Select",
                    type: 'entityList',
                    entityList:faculties
                },
                {
                    id: 'departmentId', label: "Відділ", isRequired: true, entityName: 'departmentId',
                    entityIdentify: "facultyId",
                    displayType: "Select",
                    type: "entityList",
                    entityList:departments
                }
            ];
            setColumns(columns_);
        }
    }, [isLoad,faculties])


    const  triggerLinks = {
        createLinkSave: config.apiLinks.editTemplatesItem,
        editLinkSave: config.apiLinks.editTemplatesItem,
        refreshLink: 'products',
        linkItem: '/admin/product/item/%itemId%',
        deleteLink: 'products/remove'
    }

    const triggerAfterSave = ()=>{
        setIsLoad(true);
    }

    if ( isLoad ){
        return <></>
    }

    return <Box component={"div"} sx={{display:"flex", flexDirection:"column", justifyContent: "start"}} >
            <Box component={"div"}>
                <Paper>
                    <Form
                        columns={columns}
                        itemWithData={item}
                        additionalTriggerAfterSave={triggerAfterSave}
                        triggerLinks={triggerLinks}
                        formClassName={""}
                        buttonClassName={""}
                        isLoadData={isLoad}
                    />
                </Paper>
            </Box>

            <Box sx={{height: '100%'}}>
                <Paper sx={{pt:"10px"}}>
                    <Typography variant="h5" component={'div'} sx={{textAlign:'left', pt:'1px',pl:1}}><InfoIcon sx={{pt:"3px"}} /> Плейсхолдери, які автоматично заміняться на відповідні значення</Typography>
                    <List sx={{pl:"18px"}}>
                            <ListItem>
                                <ListItemText
                                    primary="%П.І.Б% або %ПІБ% на прізвище ім'я по - батькові студента"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="%пошта% на email"
                                />
                            </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="%телефон% на телефон"
                            />
                        </ListItem>
                    </List>
                </Paper>
            </Box>
    </Box>
}