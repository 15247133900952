import React, {useState, useEffect} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import { makeStyles  } from "@mui/styles";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexFlow: "column wrap",
        // gap: "0 30px",
        minWidth: "10%",
        maxWidth: "100%",
        minHeight: 300,
        maxHeight: 600, // set the height limit to your liking
        backgroundColor: 'white',
        overflow: "auto",
        marginBottom: "10px",
    },
    item: {
        width: "auto"
    }
});

export const UserGroupList = (props) => {
    const classes = useStyles();
    console.log("props user List ", props.userList);

    const columns = [
        {
            field: 'lastName',
            headerName: 'Прізвище',
            width: 150,
            editable: false,
            valueGetter: params => params.row.User.lastName
        },
        {
            field: "firstName",
            headerName: "Ім'я",
            width: 150,
            editable: false,
            valueGetter: params => params.row.User.firstName
        },
        {
            field: 'middleName',
            headerName: 'По батькові',
            width: 150,
            editable: false,
            valueGetter: params => params.row.User.middleName
        },
        {
            field: 'ipnNumber',
            headerName: 'ІПН',
            width: 150,
            editable: false,
            valueGetter: params => params.row.User.ipnNumber
        },
        {
            field: 'email',
            headerName: 'Пошта',
            width: 150,
            editable: false,
            valueGetter: params => params.row.User.email
        },
        {
            field: 'studentTicketId',
            headerName: 'Номер студентського',
            width: 150,
            editable: false,
            valueGetter: params => params.row.User.studentTicketId
        },
    ];

    return (
        <div style={{ height: 560, width: '90%', mt: '10px' }}>
            {!props.load&&<DataGrid
                rows={props.userList}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 50,
                        },
                    },
                }}
                sx={{mt: "10px"}}
                onSelectionModelChange={(newRowSelectionModel) => {
                    props.setSelectedStudent(newRowSelectionModel);
                }}
                pageSizeOptions={[50]}
                checkboxSelection
            />}
        </div>
    )

}