import React, { useEffect, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import ListIcon from "@mui/icons-material/List";
import ChatIcon from '@mui/icons-material/Chat';
import DescriptionIcon from '@mui/icons-material/Description';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BadgeIcon from '@mui/icons-material/Badge';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { Link } from "react-router-dom";
const theme = createTheme({
  palette: {
    primary: {
      main: "#00A43C",
      text: "#2d3e50",
    },
    card_icons_color: {
      progress: "#009688",
      finances: "#2196f3",
      tasks: "#ff5722",
      help: "#ffc107",
      admissions: "#00bcd4",
      reader_card: "#607d8b",
      library: "#00bcd4",
      dorm_card: "#03a9f4",
    },
  },
});

export const Dashboard = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid2 container spacing={{ xs: 2 }}>
          <Grid2 xs={6} sm={4} md={4}>
            <Card sx={{ textAlign: "center", padding: "10px", height: '100%' }} elevation={3}>
              <Link to={"/profile/courses"}>
                <CardActionArea>
                  <CardMedia>
                    <ListIcon
                      sx={{ fontSize: "75px", color: "card_icons_color.tasks" }}
                    />
                  </CardMedia>
                  <CardContent>
                    <Typography gutterBottom  component="div" sx={{color: 'primary.text'}}>
                      Courses
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </Grid2>
          <Grid2 xs={6} sm={4} md={4}>
            <Card sx={{ textAlign: "center", padding: "10px", height: '100%' }} elevation={3}>
              <CardActionArea>
              <Link to={"/profile/admissions"}>
                <CardMedia>
                  <DescriptionIcon
                    sx={{ fontSize: "75px", color: "card_icons_color.admissions" }}
                  />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom  component="div">
                  Admissions
                  </Typography>
                </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid2>
          <Grid2 xs={6} sm={4} md={4}>
            <Card sx={{ textAlign: "center", padding: "10px" }} elevation={3}>
              <CardActionArea>
              <Link to={"/profile/tasks"}>
                <CardMedia>
                  <AssignmentIcon
                    sx={{ fontSize: "75px", color: "card_icons_color.tasks" }}
                  />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom  component="div">
                    Tasks
                  </Typography>
                </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid2>
          <Grid2 xs={6} sm={4} md={4}>
            <Card sx={{ textAlign: "center", padding: "10px", height: '100%' }} elevation={3}>
              <CardActionArea>
                <CardMedia>
                  <BadgeIcon
                    
                    sx={{ fontSize: "75px", color: "card_icons_color.reader_card" }}
                  />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom  component="div">
                  Reader card
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid2>
          <Grid2 xs={6} sm={4} md={4}>
            <Card sx={{ textAlign: "center", padding: "10px" }} elevation={3}>
              <CardActionArea>
                <CardMedia>
                  <ApartmentIcon
                    
                    sx={{ fontSize: "75px", color: "card_icons_color.dorm_card" }}
                  />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom  component="div">
                  Dorm card
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid2>
          <Grid2 xs={6} sm={4} md={4}>
            <Card sx={{ textAlign: "center", padding: "10px" }} elevation={3}>
              <CardActionArea>
                <CardMedia>
                  <DonutSmallIcon
                    sx={{
                      fontSize: "75px",
                      color: "card_icons_color.progress",
                    }}
                  />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom  component="div">
                    Progress
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid2>
          <Grid2 xs={6} sm={4} md={4}>
            <Card sx={{ textAlign: "center", padding: "10px", height: '100%' }} elevation={3}>
              <CardActionArea>
                <CardMedia>
                  <AutoStoriesIcon
                    sx={{
                      fontSize: "75px",
                      color: "card_icons_color.library",
                    }}
                  />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom  component="div">
                    Library
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid2>
          <Grid2 xs={6} sm={4} md={4}>
            <Card sx={{ textAlign: "center", padding: "10px" }} elevation={3}>
              <CardActionArea>
              <Link to={"/profile/calendar"}>
                <CardMedia>
                  <CalendarMonthIcon
                    color="primary"
                    sx={{ fontSize: "75px" }}
                  />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom  component="div">
                    Calendar
                  </Typography>
                </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid2>
          <Grid2 xs={6} sm={4} md={4}>
            <Card sx={{ textAlign: "center", padding: "10px" }} elevation={3}>
              <CardActionArea>
              <Link to={"/profile/finances"}>
                <CardMedia>
                  <AccountBalanceIcon
                    sx={{
                      fontSize: "75px",
                      color: "card_icons_color.finances",
                    }}
                  />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom  component="div">
                    Finances
                  </Typography>
                </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid2>
      
          <Grid2 xs={6} sm={4} md={4}>
            <Card sx={{ textAlign: "center", padding: "10px" }} elevation={3}>
              <CardActionArea>
              <Link to={"/profile/help"}>
                <CardMedia>
                  <ChatIcon
                    sx={{
                      fontSize: "75px",
                      color: "card_icons_color.help",
                    }}
                  />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom  component="div">
                    Help
                  </Typography>
                </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid2>
        </Grid2>
      </ThemeProvider>
    </>
  );
};
