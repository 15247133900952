import React, {useState, useEffect} from 'react';
import {TextField, Autocomplete, TextareaAutosize, Button} from '@mui/material';
import {axiosRun} from '../utils/axiosWrap';
import {config} from '../config';
import {shallowEqual, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {useStylesGridDialog} from "./UI/base/GridTable/css/useStylesGridDialog";
import {useStylesGridTableActions} from "./UI/base/GridTable/css/useStylesGridTableActions";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Container from '@mui/material/Container';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import {OpenAlertDialog} from "./OpenAlertDialog";
import {OpenInfoDialog} from "./OpenInfoDialog";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";


const NewRequestStundet = () => {
    const [templateList, setTemplateList] = useState([]);
    const [template, setTemplate] = useState(null);
    const [message, setMessage] = useState('');
    const [userAllowedFaculties, setUserAllowedFaculties] = useState([]);
    const [userRequestFaculty, setUserRequestFaculty] = useState(null);
    const [   showValidationError, setShowValidationError ] = useState(false);
    const [   validationText, setValidationText ] = useState('');
    const user = useSelector(state => state.users.user, shallowEqual);
    const [isLoad, setIsLoad] = useState(false);
    const navigate = useNavigate();
    const classesDialog = useStylesGridDialog();
    const classes = useStylesGridTableActions();

    useEffect(() => {
        console.log("user ", user);
        const _getFacultiesAndPreselect = async () => {
            const userData = await axiosRun(config.apiLinks.getUserInfo, "GET");
            const _faculties = userData.UserToFaculties.map(itmFaculty => itmFaculty.Faculty);
            setUserAllowedFaculties(_faculties);
            if (_faculties.length > 0) {
                setUserRequestFaculty(_faculties[0])
            }
        }
        _getFacultiesAndPreselect();

    }, [])

    useEffect(() => {
        if (userRequestFaculty) {
            const getTemaplatesList = async () => {
                const _templates = await axiosRun(config.apiLinks.getTemplatesListForStudent.replace("%id%", userRequestFaculty.id), 'GET');
                setTemplateList(_templates);
            }
            getTemaplatesList();
        }
    }, [userRequestFaculty])

    const handleSave = async (event) => {
        event.preventDefault();
        setIsLoad(true);
        const hasError = validateSaving(message);
        if  ( hasError.length === 0 ){
            const result = await axiosRun(config.apiLinks.createNewRequest, "POST", {
                studentId: user.user.id,
                templateId: template.id,
                facultyId: userRequestFaculty.id,
                message,
                authorSnapshot: `${user.user.email}`
            })
            navigate(`/profile/request/chat/${result.id}`);
        }else{
            setValidationText(`Замініть в заяві текст <b> ${hasError.join(',')} </b> на відповідне його значення та після цього видаліть символи %% біля заповнених даних`)
            setIsLoad(false);
        }
    }

    useEffect(()=>{
        if (validationText!==''){
            setShowValidationError(true);
        }
    },[validationText])

    const validateSaving = (inputText) =>{
        const result = [];
        const regex = /%(.*?)%/g;
        let match;

        while ((match = regex.exec(inputText)) !== null) {
            result.push(match[1]);
        }
        return result;
    }

    const setTemplateRequest = (event, value) => {
        setTemplate(value);
        const replacement =
            {
                '%П.І.Б%':`${user.user.lastName} ${user.user.firstName} ${user.user.middleName}`,
                '%ПІБ%':`${user.user.lastName} ${user.user.firstName} ${user.user.middleName}`,
                '%пошта%':`${user.user.email}`,
                '%телефон%': `${user.user.homePhone?user.user.homePhone:user.user.contactPhone??'%телефон%'}`
            }
        let text = value.description;
        Object.keys(replacement).forEach( (changeWhat) =>{
            const changeTo = replacement[changeWhat];
            text = text.replaceAll( changeWhat, changeTo );
        })
        setMessage(text);
    }

    const setRequestFaculty = (event, value) => {
        setUserRequestFaculty(value);
        setTemplate(null);
    }


    return (
        <Container maxWidth="xl"  sx={{p:0}}>
    <Paper elevation={3} sx={{maxWidth: '650px', marginTop: "10px", paddingLeft: '10px', paddingRight:'10px', position: 'relative'}}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoad}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ paddingTop: '15px', paddingBottom: '20px'}} component={"div"}>
                <Typography variant="h5" component={'div'} sx={{textAlign:'center'}} > Створення запиту в деканат </Typography>
            </Box>
            <Box component="div">
                <form onSubmit={handleSave} className={classesDialog.formColumn}>

                    <Autocomplete
                        options={userAllowedFaculties}
                        value={userRequestFaculty}
                        onChange={setRequestFaculty}
                        getOptionLabel={(option) => {
                            return option ? option.name : ''
                        }}
                        renderInput={(params) => <TextField {...params} label="Факультет зверення"/>}

                    />
                    <OpenInfoDialog isOpen={showValidationError} onClose={
                        ()=>{
                            setShowValidationError(false);
                            setValidationText('');
                        }
                    }
                    onYes={async ()=>{
                        setShowValidationError(false);
                        setValidationText('');
                    }} text={validationText} />

                    <Autocomplete
                        options={templateList}
                        value={template}
                        onChange={setTemplateRequest}
                        getOptionLabel={(option) =>
                            option ? option.name : ''
                        }
                        renderInput={(params) => <TextField {...params} label="Шаблон звернення"/>}
                        fullWidth
                    />


                    <div id={`ckeditor-field`}>
                        <>
                            <CKEditor editor={DecoupledEditor}
                                      config={{
                                          toolbar: [  'bold', 'italic',  'alignment' ],
                                          heading: {
                                              options: [
                                                  { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                                  { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                                  { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                                              ],

                                          },
                                        }}
                                      onReady={ editor => {
                                          console.log( 'Editor is ready to use!', editor.ui.getEditableElement() );
                                          // Insert the toolbar before the editable area.
                                          editor.ui.getEditableElement().parentElement.insertBefore(
                                              editor.ui.view.toolbar.element,
                                              editor.ui.getEditableElement()
                                          );
                                          editor.editing.view.change((writer) => {
                                              writer.setStyle(
                                                  "width",
                                                  "100%",
                                                  editor.editing.view.document.getRoot()
                                              );
                                          });

                                      } }
                                      data={message} onChange={(event,editor) => {
                                setMessage(editor.getData())

                            }
                            } />
                        </>
                    </div>


                    <Box sx={{paddingBottom: "30px", textAlign: 'right'}}>
                        <Button onClick={handleSave} className={classes.buttonMiddle} variant="contained"
                                color="primary" type="submit" startIcon={<SaveIcon/>}>
                            Save
                        </Button>
                    </Box>
                </form>
            </Box>
        
    </Paper>
    </Container>
    )

}

export default NewRequestStundet;