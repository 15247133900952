import React, {useEffect, useState} from "react";
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {styled} from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),

    color: '#222',
    maxHeight: '80vh',
    overflow: 'auto'
}));

export const GenerateUserFaculties = ()=>{
    const [dataLogResult, setDataLogResult] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [csvLoad, setcsvLoad] = useState([]);

    useEffect( ()=>{
        const check = async ()=>{
            await axiosRun( config.apiLinks.checkUser, 'GET' );
        };
        check();
    }, [] )

    const sendGenerate = async ( type ) =>{
       const dataSecurity = await axiosRun(config.apiLinks.secretSantaSuperAdmin, 'POST',{type});
       setDataLogResult(dataSecurity);
        var json = dataSecurity;
        var fields = Object.keys(json[0])
        var replacer = function(key, value) { return value === null ? '' : value }
        var csv = json.map(function(row){
            return fields.map(function(fieldName){
                return JSON.stringify(row[fieldName], replacer)
            }).join(',')
        })
        csv.unshift(fields.join(',')) // add header column
        csv = csv.join('\r\n');
        setcsvLoad(csv);
    }

    return (
        <Paper sx={{p:3, minHeight: '85vh'}}>
            <Typography variant="h4" component="h1" gutterBottom>{"Генерація адміністраторів"}</Typography>
            {isLoading===false&& Object.keys(dataLogResult).length === 0 &&<>
                <Button variant="contained" component="label" onClick={e=>sendGenerate(0)}>
                Generate passwords
                </Button>
               
            </>}

            <Typography variant="h4" component="h1" gutterBottom>{"Генерація адміністраторів груп"}</Typography>
            {isLoading===false&& Object.keys(dataLogResult).length === 0 &&<>
                <Button variant="contained" component="label" onClick={e=>sendGenerate(1)}>
                    Generate groups lead passwords
                </Button>
            </>}

            <Typography variant="h4" component="h1" gutterBottom>{"Генерація адміністраторів відділів"}</Typography>
            {isLoading===false&& Object.keys(dataLogResult).length === 0 &&<>
                <Button variant="contained" component="label" onClick={e=>sendGenerate(2)}>
                    Generate groups lead passwords
                </Button>
            </>}


            {isLoading===true&&"Loading data...."}
            {Object.keys(dataLogResult).length>0&&<div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                            Information about passwords:
                        </Typography>

                        <List dense={true}>
                            {dataLogResult.map( (itemLog) =>{
                                return  <ListItem>
                                    <h3>{itemLog.description}</h3> <b>login</b>:<i>{itemLog.login}</i>:<b>password</b> <i>{itemLog.password}</i>
                                </ListItem>
                            }) }
                        </List>

                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6} xl={6}>
                                <Item>
                                    <div>
                                        <pre>
                                            {JSON.stringify(dataLogResult, null, 2)}
                                        </pre>
                                    </div>
                                </Item>
                            </Grid>
                            <Grid item xs={6} md={6} xl={6}>
                                <Item>
                                    <div>
                                        <pre>
                                            {csvLoad}
                                        </pre>
                                    </div>
                                </Item>
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            </div>}
        </Paper>
    );
}

