import React, {useEffect, useState} from "react";
import GridTable from "./UI/base/GridTable/components/GridTable";
import {config} from "../config";
import {axiosRun} from "../utils/axiosWrap";
import {shallowEqual, useSelector} from "react-redux";


export const StudentsBypassList = ()=>{

    const [columns, setColumns] = useState([]);
    const [columnsForQuick, setColumnsForQuick] = useState([]);
    const [isLoad, setIsLoad] = useState(true);
    const [facultiesGroupsList, setFacultiesGroupsList] = useState([]);
    const roles = useSelector((state) => state.users.user, shallowEqual).user.roles;

    useEffect( ()=>{
        (async ()=>{
            const _facultiesGroupsList = await axiosRun(
                roles!=='ADMIN_GROUP_LEAD'?config.apiLinks.getFacultiesgroupList:config.apiLinks.getFacultiesgroupListGroupLead,
                'GET'
            );
            setFacultiesGroupsList(_facultiesGroupsList);
            setIsLoad(false)
        })();
    }, [isLoad, roles] )


    useEffect( () => {
            const _columns = [
                { id: 'TemplatesBypass.name', numeric: false, disablePadding: false, label: 'Назва обхідного', isLinkItem: true },
                { id: 'TemplatesBypass.facultyTemplate.name', numeric: false, disablePadding: false, label: 'Факультет', isLinkItem: true },
                { id: 'User.lastName', numeric: false, disablePadding: false, label: "Прізвище", isLinkItem: true },
                { id: 'User.firstName', numeric: false, disablePadding: false, label: "Ім'я", isLinkItem: true },
                { id: 'User.middleName', numeric: false, disablePadding: false, label: "По-батькові", isLinkItem: true },
                { id: 'User.ipnNumber', numeric: false, disablePadding: false, label: "ІПН", isLinkItem: true },
                { id: 'progress', numeric: false, disablePadding: false, label: "% проходження", isLinkItem: true },
                { id: 'creationDate', numeric: false, disablePadding: false, label: "створено(дата)", isLinkItem: true}
            ];
            const _columnsFormQuick = [];
            setColumns(_columns);
            setColumnsForQuick(_columnsFormQuick)
    }, [])

    const  triggerLinks = {
        createLinkSave: config.apiLinks.createTemplate,
        editLinkSave: config.apiLinks.editTemplatesItem,
        linkItem: '/administration/bypass/item/%itemId%',
        deleteLink: 'products/remove',
        redirectAfterAdd: '/administration/group/list/%itemId%'
    }

    return <>
        {!isLoad&&<GridTable
            linkGetData={config.apiLinks.getStudentsAllBypassByACL}
            columns={columns}
            triggerLinks={triggerLinks}
            columnsFormQuick={columnsForQuick}
            tableHeaderText={"Обхідні листи студентів"}
            rows4page={10}
            quickViewNotAllowed={true}
            hideAdd={true}
            filtersList={[
                { name: 'id', label: 'Ідентифікатор',type: 'text'},
                { name: '$User.firstName$', label: "Ім`я", type: 'text' },
                { name: '$User.lastName$', label: "Прізвище", type: 'text' },
                { name: '$User.middleName$', label: 'По батькові', type: 'text' },
                { name: '$User.ipnNumber$', label: 'IПН', type: 'text' },
                { name: 'progress', label: "Стан проходження", type: 'select',
                    options: [
                        {value: 100, label: "Пройдено"},
                        {value: null, label: "Не розпочатий"},
                        {value: 99, label: "В процессі"}
                    ]
                },
                { name: "faculty_sp_spec_group", label: "Факультет/спеціальність/спеціалізація/група", type: 'autocomplete', options: facultiesGroupsList },
                { name: "$UserToFaculty.Group.study_type$", label: "Форма навчання", type: 'select', options: [
                        {value: 1, label: "Денна"},
                        {value: 2, label: "Заочно"}
                    ]},
                { name: '$TemplatesBypass.name$', label: "Назва обхідного", type: 'text'},
                {
                    name: 'isDone', label: "Опрацьований моїм відділом", type: "select", options: [
                        {value: 1, label: "Так"},
                        {value: 2, label: "Ні"}
                    ]
                },
                { name: 'creationDate', label: "дата(YYYY-MM-DD)", type: "text"},

            ]}
        />}
        </>

}