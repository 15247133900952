import { alpha, makeStyles } from '@mui/styles';

export const useStylesGridTableActions = makeStyles((theme) => ({
    actions: {
        backgroundColor: "#e0e0e0"
    },
    toolbarTop: {
        backgroundColor: "#e0e0e0"
    },
    toolbar:{
        minHeight: "15%",
        marginBottom: "10px",
        // backgroundColor: "#f1f8e9"
    },
    buttonMiddle: {
        width: "150px",
        marginTop: 0
       
    },
    buttonLong: {
        margin: theme.spacing(1),
        backgroundColor:"#17C1E8",
        // minWidth: "35.2%",
        color: "#fafafa",
        "&:hover":{
            backgroundColor: "#45cded",
            color: "#eeeeee"
        }
    },
    buttonGroup: {
        marginLeft: "auto",
        marginRight: "0"
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: "2px",
        zIndex: 5
    },
    inputInput: {
        padding: theme.spacing(1.01, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        height: "0.76em",
        borderRadius: "15px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#bdbdbd",
        marginTop: "1px",
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    inputRoot:{
      color: 'inherit',
      lineHeight: "1em",

    },
    search: {
        position: 'relative',

        // backgroundColor: alpha(theme.palette.common.white, 0.15),
        // '&:hover': {
        //     backgroundColor: alpha(theme.palette.common.white, 0.25),
        // },
        
    
        // [theme.breakpoints.up('sm')]: {
        //     width: 'auto',
        // },
    },
}));