import React, {useEffect,useState} from 'react';
import FormRaw from "../../GridTable/components/FormRaw";
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CircularProgress from "@mui/material/CircularProgress";
import {type} from "@testing-library/user-event/dist/type";
import Typography from "@mui/material/Typography";
import { useForm } from "./css/useForm";
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    const classes = useForm();
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className={classes.box}
            
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
export const Form = React.memo((props)=>{
    const {
        columns,
        additionalTriggerAfterSave,
        triggerLinks,
        formClassName,
        buttonClassName,
        itemWithData,
        showRemove,
        tables,
        isLoadData
    } = props;
    const [valueTab, setValueTab] = useState(0);
    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };
    return<Paper elevation={3} sx={{width: '100%', margin: '0 auto', position: 'relative'}}>
    <Box sx={{display:"flex", width: {lg: "100%"}, marginTop: "16px", flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'left'}} component={"div"}>
          <Tabs value={valueTab} onChange={handleChangeTab}>
            <Tab label={"Форма"} sx={{width: {sm:'150px'}}}  />
            {tables &&
              tables.map((itmTables) => {
                return <Tab label={itmTables.label} />;
              })}
          </Tabs>

        <TabPanel value={valueTab} index={0}>
          {props.isLoadData === true && (
            <Box component="div" sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: '#ffffffab', zIndex: 9 }}>
              <CircularProgress color="success" size={50} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
          </Box>
          )}
            <FormRaw
                columnsFormQuick={columns}
                itemWithData={itemWithData}
                additionalEvent={ additionalTriggerAfterSave }
                link={itemWithData.id?triggerLinks.editLinkSave.replace("%rowId%",itemWithData.id):triggerLinks.createLinkSave}
                formClassName={formClassName}
                buttonClassName={buttonClassName}
                tables={tables}
                isLoadData={isLoadData}
            />
        </TabPanel>
        {tables &&
          tables.map((Component, inxTables) => {
            const itemComponent = Component;
            return (
              <TabPanel value={valueTab} index={inxTables + 1}>
                {React.createElement(
                  itemComponent.component,
                  itemComponent.props
                )}
              </TabPanel>
            );
          })}
    </Box>
    </Paper>
})