import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import ButtonGroup from "@mui/material/ButtonGroup";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { GridDialogFormList } from "./GridDialogFormList";
import { useStylesGridTableActions } from "../css/useStylesGridTableActions";
import { GridDialogFormAdd } from "./GridDialogFormAdd";
import { GridDialogFormRemove } from "./GridDialogFormRemove";
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { Filters } from "./Filters";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box'

export const GridTableActions = ({
  lockedProps,
  rows,
  selected,
  numSelected,
  columnsFormQuick,
  triggerLinks,
  setIsUpdate,
  setSelected,
  isLoadData,
  handleSearch,
  handleClear,
  handleSubmit,
  filtersList,  isAddLink, hideAdd,
                                     quickViewNotAllowed,addLink
}) => {
  const classes = useStylesGridTableActions();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenRemove, setIsOpenRemove] = useState(false);

  const Item = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '-16px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '1.2rem',
    padding: '5px 10px',
    background: '#fff',
    "@media (min-width: 1140px)": {
      top: '-20px'
    },
  }));

  return (
    <Toolbar className={classes.toolbar} sx={{ flexWrap: "wrap" }}>
      <GridDialogFormList
        open={isOpen}
        rows={rows}
        setIsUpdate={setIsUpdate}
        columnsFormQuick={columnsFormQuick}
        selected={selected}
        triggerLinks={triggerLinks}
        setSelected={setSelected}
        isLoadData={isLoadData}
        openTrigger={setIsOpen}
      />

      <GridDialogFormAdd
        open={isOpenAdd}
        setIsUpdate={setIsUpdate}
        columnsFormQuick={columnsFormQuick}
        triggerLinks={triggerLinks}
        openTrigger={setIsOpenAdd}
        isLoadData={isLoadData}
        lockedProps={lockedProps}
      />

      <GridDialogFormRemove
        open={isOpenRemove}
        triggersLinks={triggerLinks}
        openTrigger={setIsOpenRemove}
        selected={selected}
        setIsUpdate={setIsUpdate}
      />
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} lg={9}>

          {filtersList&&Object.keys(filtersList).length > 0 && <Filters filtersList={filtersList} onClear={handleClear} onSubmit={handleSubmit} />}

        </Grid>
        <Grid item xs={12} lg={3}>

            {!hideAdd&&<>
        <Stack  spacing={2} sx={{ position: 'relative', width: '100%', height: '100%', justifyContent: 'center', alignItems:'center', border: '1px solid #ccc', borderRadius: '4px', padding:'20px'}}>
        <Item>Дії</Item>

          {!quickViewNotAllowed&&<Button
              variant="outlined"
              color="primary"
              onClick={(e) => setIsOpen(true)}
              disabled={numSelected === 0}

              className={classes.buttonMiddle}
              startIcon={<VisibilityIcon />}
              sx={{width: '100% !important'}}
            >
              {`Quick view`}
            </Button>}
          {!isAddLink?
            <Button
              variant="contained"
              color="primary"
              component={Link}
              className={classes.buttonMiddle}
              onClick={(e) => setIsOpenAdd(true)}
              sx={{width: '100% !important'}}

              startIcon={<AddCircleOutlineSharpIcon />}
            >
              {`Add`}
            </Button>:
              <Button
                  variant="contained"
                  color="primary"
                  href={addLink}
                  className={classes.buttonMiddle}
                  sx={{width: '100% !important'}}

                  startIcon={<AddCircleOutlineSharpIcon />}
              >
                  {`Add`}
              </Button>
          }
          </Stack>
            </>}
        </Grid>
      </Grid>
    </Toolbar >
  );
};
