import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {TextField, Button, Typography} from '@mui/material';
import {useParams,useNavigate} from "react-router-dom";
import {axiosRun} from "../../utils/axiosWrap";
import {config} from "../../config";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {ModalResult} from "./Register/ModalResult";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Logo from "../../images/snau_logo.png";
import Alert from '@mui/material/Alert';
import {useTranslation} from "react-i18next";
import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '1rem',
        '& > *': {
            margin: '0.5rem',
            width: '25ch',
        },
    },
    button: {
        marginTop: '2rem',
    },
    error: {
        color: '#f44336',
    },
}));

const SetPassword = () => {
    const {hash} = useParams();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [formError, setFormError] = useState(false);
    const [user, setUser] = useState({});
    const [isLoad, setIsLoad] = useState(true);
    const [showError, setShowError] = useState(false);
    const [open, setOpen] = useState(false);
    const [submitResult, setSubmitResult] = useState({});
    let history = useNavigate();

    useEffect(() => {
        setIsLoad(true);
        const getUser = async (hash) => {
            const _user = await axiosRun(config.apiLinks.getUserByHash.replace('%hash%', hash), 'GET');
            console.log("user ", _user);
            setUser(_user);
            setIsLoad(false);
        }
        getUser(hash);
    }, [hash])


    useEffect(() => {
        if (!isLoad && Object.keys(user).length === 0) {
            setShowError(true);
        }
    }, [isLoad, user])

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setPasswordError(!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(event.target.value));
        if (event.target.value === confirmPassword){
            setFormError(false);
        }else{
            setFormError(true);
        }
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        if (event.target.value === password){
            setFormError(false);
        }else{
            setFormError(true);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setFormError(true);
        } else {
             setFormError(false);
             const result = await axiosRun(config.apiLinks.updateUserPassword, 'POST',{hash,password});
             setSubmitResult(result.message);
             setOpen(true);
             setTimeout( ()=>{
                 history('/');
             }, 3000 )
        }
    }

    const handleClose = ()=>{
        history('/');
        setOpen(false);
    }


    const theme = createTheme({
        palette: {
            primary: {
                main: "#00A43C",
                text: "#2d3e50",
            },
        },
    });
    return (
        <>
            <ThemeProvider theme={theme}>
                <ModalResult open={open} onOpen={() => {
                }} onClose={handleClose}>
                    {submitResult && <Typography>{submitResult}</Typography>}
                    <Button onClick={e => {
                        setOpen(false)
                    }} varisant="contained">{t("form.close")}</Button>
                </ModalResult>
                <Container component="main" maxWidth="m">
                    <CssBaseline/>
                    <Box sx={{
                        marginTop: '30px',
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                        <Box
                            sx={{marginBottom: '43px', alignItems: 'center', display: "flex", flexDirection: "column"}}>
                            <Avatar
                                sx={{width: 100, height: 100, p: 1, bgcolor: "primary.main"}}
                                src={Logo}
                            />
                            <Typography component="h1" variant="h5" sx={{marginTop: 1}}>
                                {t("setPass.setPassword")}
                            </Typography>
                        </Box>
                        <Backdrop
                            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={isLoad}
                        >
                            <CircularProgress color="inherit"/>
                        </Backdrop>

                        {!isLoad && !showError ? <>
                            <Box
                                component="form"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    //columnGap: 3,
                                    minWidth: '400px',
                                    maxWidth: '400px',
                                    rowGap: 1,
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <Alert severity="info">
                                   {user.firstName} {user.lastName}, <br />{t("setPass.setYourPassMsg")}
                                </Alert>
                                <TextField
                                    id="password"
                                    label={t("setPass.putYouPasswordHere")}
                                    type="password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    error={passwordError}
                                    helperText={passwordError && t("setPass.minLength8DgitiLetters")}
                                    required
                                />
                                <TextField
                                    id="confirm-password"
                                    label={t("setPass.repeatPassword")}
                                    type="password"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    error={formError}
                                    helperText={confirmPassword && password !== confirmPassword && t('setPass.passwrodNotMatch')}
                                    required
                                />
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={passwordError || formError}
                                >
                                    {t("setPass.resetPassSubmitButton")}
                                </Button>
                            </Box></> :!isLoad&&showError&&<> <Typography variant="body1" className={`${classes.error}`}>
                            {t("setPass.errorLink")}
                        </Typography>
                        <Link href={"/"}>
                            {t("setPass.baseLink")}
                        </Link>
                        </>}
                    </Box>
                </Container>
            </ThemeProvider>
        </>
    );
};

export default SetPassword;