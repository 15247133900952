import React, { useState, useEffect } from 'react';

import { Button, FormControl, InputLabel, MenuItem, Box ,Select, TextField, Typography, List, ListItem, ListItemText } from '@mui/material';
import {axiosRun} from "../../utils/axiosWrap";
import {config} from "../../config";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from '@mui/material/FormControlLabel';
export const AddField = ({ onFieldAdded, id, setOpen, departmentList, clickedForEdit, fields, open }) => {
    console.log("clicked for edit ", clickedForEdit, fields);
    const fieldCur = fields.find(itm => itm.id === clickedForEdit);
    const [fieldName, setFieldName] = useState(clickedForEdit!==null?fieldCur['fieldName']:'');
    const [responsibility, setResponsibility] = useState(clickedForEdit!==null?fieldCur['departmentId']:'');
    const [isApprovedField, setIsApprovedField] = useState(clickedForEdit!==null?fieldCur['isApprovedField']:true);
    const [validationError, setValidationError] = useState([]);
    const handleAddField = async () => {
        try {
            const data =  await axiosRun( config.apiLinks.addToBypass,"PUT", {
                fieldName,
                templateId: id,
                responsibility,
                id: clickedForEdit,
                isApprovedField
            })
            // Notify the parent component that a field has been added
            onFieldAdded(data);
            setFieldName('');
            setResponsibility('');
            setOpen(false);
        } catch (error) {
            console.error('Error adding field:', error);
            setValidationError(error.data.errors);
        }
    };

    return (
        <Box component={"div"} sx={{pt:"2%"}}>
            <FormControl fullWidth>
                <TextField
                    error={validationError['fieldName']?true:false}
                    helperText={validationError['fieldName']??''}
                    label="Field Name"
                    value={fieldName}
                    onChange={(e) => setFieldName(e.target.value)}
                    fullWidth
                    margin="normal"
                />
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="responsibility-label">Responsibility Department</InputLabel>
                <Select
                    error={validationError['responsibility']?true:false}
                    helperText={validationError['responsibility']??''}
                    labelId="responsibility-label"
                    label={"Responsibility Department"}
                    value={responsibility}
                    onChange={(e) => setResponsibility(e.target.value)}
                    fullWidth
                    margin="normal"
                >
                    {departmentList.map(itm =>{
                       return <MenuItem value={itm.id}>{itm.name}</MenuItem>
                    })}
                </Select>
            </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox checked={isApprovedField} onChange={(e)=>{
                            setIsApprovedField(e.target.checked);
                        }} name="jason" />
                    }
                    label="Is approved field"
                />


                <Box component={"div"} sx={{ pt: "2%", display: "flex", flexDirection: "row", gap: "10px"}}>
                    <Button variant="contained" onClick={handleAddField} fullWidth>
                        SAVE
                    </Button>
                    <Button variant="contained" onClick={(e)=>{
                        setFieldName('');
                        setResponsibility('')
                    }} fullWidth>
                        Reset
                    </Button>
                </Box>
        </Box>
    );
};