import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Container from '@mui/material/Container';

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});
const CheckBypassList = (props) => {

    const [listData, setListData] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoad(true);
        const load = async () => {
            const _tmp = await axiosRun(config.apiLinks.getStudentsBypassList, 'GET');
            setListData(_tmp);
            setIsLoad(false);
        }
        load();
    }, [])

    const navigateClick = (item,exist)=>{
        if (exist){
            navigate(`/profile/bypass/template/${item?.template?.id}/${item.studyPlace.id}`);
        }else{
            navigate(`/profile/bypass/template/${item?.template?.id}/new/${item?.studyPlace?.sid}/${item?.studyPlace?.spid}/`);
        }
    }

    return (
        <Container maxWidth="xl"  sx={{p:0}}>
            <Paper elevation={3} sx={{width: '100%', margin: '0 auto', marginTop: "10px",padding: '20px', position: 'relative'}}>

                <Box sx={{paddingBottom: '20px'}} component={"div"}>
                    <Typography variant="h5" component={'div'} sx={{textAlign:'center'}}>{t('profile.MyBypass')}</Typography>
                </Box>
                <Grid
                    container
                    alignItems="flex-start"
                    spacing={2}

                >   {console.log("item st ", listData)}
                    {listData?.inProgress?.map(itm => {
                        return itm.StudentsBypasses.map( item=>{
                            const colors = {'new':'#f9fbe7', 'active':'#dce775', 'done':'#e8f5e9', 'not': '#fafafa'};
                            const status = item.status;
                            return <Grid xs={12} md={6} lg={4} item onClick={e=>navigateClick({template:itm,studyPlace:item},true)} >
                                <Paper sx={{
                                    p: 2,
                                    flexGrow: 1,
                                    backgroundColor: colors[status]
                                }}>
                                    <Grid container spacing={2}>
                                        <Grid item  sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography gutterBottom variant="subtitle1" component="div">
                                                        {itm.name}
                                                    </Typography>
                                                    <Typography variant={'h6'}>{itm?.facultyTemplate?.name} {item.Speciality?.name} {item.Specialization?.name} </Typography>
                                                    <Typography variant={'h7'}>{t(`bypass.${item.status}`)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        })
                    })}
                    {listData?.allStudyPlace?.map(item => {
                        return listData.templatesRequired?.map(tmpl=>{
                            if (item.fid === tmpl.facultyId && !listData.usedStudyPlaceAndTemplate.find(itF=>{
                                console.log("itF1", JSON.stringify(itF));
                                console.log("itF", JSON.stringify(itF),JSON.stringify({fid:item.fid,sid:item.sid,spid:item.spid,templateId:tmpl.id}));
                               return JSON.stringify(itF) === JSON.stringify({fid:item.fid,sid:item.sid,spid:item.spid,templateId:tmpl.id})
                            })){
                                return <Grid xs={12} md={6} lg={4} item onClick={e=>navigateClick({template:tmpl,studyPlace:item},false)}>
                                    <Paper sx={{
                                        p: 2,
                                        flexGrow: 1,
                                        backgroundColor: "#fafafa"
                                    }}>
                                        <Grid container spacing={2}>
                                            <Grid item  sm container>
                                                <Grid item xs container direction="column" spacing={2}>
                                                    <Grid item xs>
                                                        <Typography gutterBottom variant="subtitle1" component="div">
                                                            {tmpl.name}
                                                        </Typography>
                                                        <Typography variant={'h6'}>{item.fname} {item.sname} {item.spname}</Typography>
                                                        <Typography variant={'h7'}><b>{t('bypass.statusNotExists')}</b></Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }
                        })
                        }
                    )}
                </Grid>

            </Paper>
        </Container>
    );
};

export default CheckBypassList;
