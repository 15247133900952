import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import {TablePaginationActionsWrapped} from "./TablePaginatorActions";
import TableFooter from '@mui/material/TableFooter';
import EnhancedTableToolbar, {EnhancedTableToolbarWrapper} from "./EnhancedTableToolbar";
import {EnhancedTableHead} from "./EnhancedTableHead";
import Checkbox from '@mui/material/Checkbox';
import {fetcher} from "../../helper/fetcher";
import {useGridTableLazy} from "../hooks/GridTable.lazy.hook";
import {Link as ReactLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CircularProgress from "@mui/material/CircularProgress";
import Box from '@mui/material/Box';
import {getPropByString} from "../../helper/utils";



const styles = theme => ({
    root: {
        position: 'relative',
       //  marginTop: theme.spacing.unit * 20,
        margin: "0 auto",
        overflowX: 'auto',
    },
    link:{
      "&:hover":{
        color: "#9e9e9e"
      }
    },
    table: {
        minWidth: 700,
    },
    tableRow: {
        "&:hover": {
            backgroundColor: "#f8f9fa !important"
        },
        '&:nth-child(odd)': {
            backgroundColor: '#f8f9fa'
        },
        '&:nth-child(even)': {
            backgroundColor: '#FFFFFF'
        }
    },

    tableHead: {
      backgroundColor: "#e9ecef",
     // position: "fixed",

    },
    tableFooter: {
      backgroundColor: "#e9ecef"
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    hover: {}
});


function GridTableLazyLoad(props) {
    const { classes, columns } = props;
    console.log("columns", columns);
    const {
        selected,
        rows,
        setIsUpdate,
        order,
        orderBy,
        page,
        rowsPerPage,
        handleClick,
        getSorting,
        stableSort,
        handleChangeRowsPerPage,
        handleChangePage,
        handleRequestSort,
        handleSelectAllClick,
        setSelected,
        isLoadData,
        handleSearch,
        handleClear,
        handleSubmit,
        countByApi
    } = useGridTableLazy(props);
        console.log("rows ", rows);
    console.log("hideeeee", props.hideAdd);
    return (
            <Paper className={classes.root}>
            {isLoadData&&<Box component="div" sx={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: '#ffffffab', zIndex: 9}}>
                <CircularProgress color="success" size={70} sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} />
            </Box>}

            <EnhancedTableToolbarWrapper rows={rows}
                                         columnsFormQuick={props.columnsFormQuick}
                                         triggerLinks={props.triggerLinks}
                                         setIsUpdate={setIsUpdate}
                                         selected={selected}
                                         numSelected={selected.length}
                                         lockedProps={props.lockedProps}
                                         setSelected={setSelected}
                                         tableHeaderText={props.tableHeaderText}
                                         isLoadData={isLoadData}
                                         handleSearch={handleSearch}
                                         handleSubmit={handleSubmit}
                                         handleClear={handleClear}
                                         filtersList={props.filtersList}
                                         isAddLink={props.isAddLink}
                                         quickViewNotAllowed={props.quickViewNotAllowed}
                                         addLink={props.addLink}
                                         hideAdd={props.hideAdd}
            />
            <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        columns={columns}
                        className={classes.tableHead}
                        rowCount={rows.length}
                        quickViewNotAllowed={props.quickViewNotAllowed}
                    />
                    <TableBody>
                        {Object.keys(rows).length>0&&stableSort(rows, getSorting(order, orderBy)).map(row => {
                            const isSelected = selected.indexOf(row.id) !== -1;
                            return <TableRow key={row.id}  onClick={e => handleClick(e, row.id)}
                                                                   role="checkbox"
                                                                   aria-checked={isSelected}
                                                                   tabIndex={-1}
                                                                   key={row.id}
                                                                   selected={isSelected} className={classes.tableRow} >
                                {!props.quickViewNotAllowed&&<TableCell className={classes.tableCell} padding="checkbox" >
                                    <Checkbox checked={isSelected}  />
                                </TableCell>}
                                {console.log("columns manp", columns)}
                                {columns.length > 0&&columns.map(column=>{
                                    if (column.isLinkItem){
                                        return<TableCell  className={classes.tableCell}>
                                            <Link color={"inherit"} component={ReactLink} to={props.triggerLinks.linkItem.replace("%itemId%",row['id'])}>
                                                {console.log("row ", row, column)}
                                                {getPropByString(row,column)}
                                            </Link>
                                        </TableCell>
                                    }else{
                                        return <TableCell  className={classes.tableCell}>
                                            {getPropByString(row,column)}
                                        </TableCell>
                                    }
                                })}
                            </TableRow>
                        })}
                    </TableBody>
                    <TableFooter className={classes.tableFooter}>
                        <TableRow key={"footer"}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                count={countByApi}
                                rowsPerPage={rowsPerPage}
                                colspan={columns.length+1}
                                page={page}
                                SelectProps={{
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
        </Paper>
    );
}

GridTableLazyLoad.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GridTableLazyLoad);
