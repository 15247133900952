import React, {useEffect, useState} from "react";
import GridTable from "./UI/base/GridTable/components/GridTable";
import {config} from "../config";
import {axiosRun} from "../utils/axiosWrap";
import {Form} from "./UI/base/Form/components/Form";
import {
    useParams
} from "react-router-dom";

import {StudentTabEducation} from "./StudentTabEducation";
import CircularProgress from "@mui/material/CircularProgress";
import Box from '@mui/material/Box';
import SendEmail from "./SendEmail";


export const PaymentItem = ()=>{
    const [item, setItem] = useState({});
    const [isLoad, setIsLoad] = useState(true);
    const [columns, setColumns] = useState([]);

    useEffect(()=>{
        if (isLoad){
            const getData = async () => {
                const paymentItem = await axiosRun( config.apiLinks.getPaymentItem, 'GET' )
                setItem(paymentItem);
            }
            getData();
        }
    }, [isLoad])


    //when all data loaded config columns
    useEffect( () => {
        if (Object.keys(item).length>0){
            setIsLoad(false);
            const columns_ = [
                { id: 'paymentRowEducation', numeric: false, disablePadding: false, label: 'Оплата навчання', isLinkItem: true, type: "textMultiline" },
                { id: 'paymentRowAptRent', numeric: false, disablePadding: true, label: "Оплата за проживання", isLinkItem: true, type: "textMultiline" },
            ];
            setColumns(columns_);
        }
    }, [item])


    const  triggerLinks = {
        createLinkSave: config.apiLinks.editPaymentItem,
        editLinkSave: config.apiLinks.editPaymentItem,
        refreshLink: 'products',
        linkItem: '/admin/product/item/%itemId%',
        deleteLink: 'products/remove'
    }

    const triggerAfterSave = ()=>{
        setIsLoad(true);
    }

    if ( isLoad ){
        return <></>
    }

    return <>
        {console.log("item 81", item, columns)}
        {<Form
            columns={columns}
            itemWithData={item}
            additionalTriggerAfterSave={triggerAfterSave}
            triggerLinks={triggerLinks}
            formClassName={""}
            buttonClassName={""}
            isLoadData={isLoad}
        />}
    </>
}