import React, {useEffect,useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import {useStylesGridTableActions} from "../css/useStylesGridTableActions";
import {useStylesGridDialog} from "../css/useStylesGridDialog";
import {useGridDialogFormList} from "../hooks/GridDialogFormList.hook";
import FormRaw from "./FormRaw";

export const GridDialogFormList = (props) => {
    const classes = useStylesGridTableActions();
    const classesDialog = useStylesGridDialog();
    const {open, rows,selected, openTrigger, columnsFormQuick } = props;
    const [dataToDisplay, setDataToDisplay] = useState([]);

    useEffect(()=>{
       if (selected.length !== 0){
           const dataToDisplay = rows.filter(itm => selected.indexOf(itm.id) !== -1 )
           setDataToDisplay(dataToDisplay);
           console.log("data to display >>>>>>>>> ", dataToDisplay);
       }
    }, [rows,selected])

    const handleClose = ()=>{
        openTrigger(false);
        console.log("props set order by", props.setOrderBy);
        props.setSelected([]);
        props.setIsUpdate(true);
    }


    const additionalTriggerAfterSave = ()=>{
       props.setIsUpdate(true);
        props.setSelected([]);
       openTrigger(false);
    }

    return (
        <>
            <Dialog open={open} maxWidth={"xl"}  onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Швидкий перегляд</DialogTitle>
                <DialogContent className={classesDialog.dialog}>
                    <DialogContentText>
                       Відредагуйте дані та нажміть кнопку зберегти
                    </DialogContentText>
                    <Grid container direction="row"
                          justifyContent="center"
                          alignItems="center"
                          fullWidth={false}
                          spacing={2}>
                        {dataToDisplay.map((item, inNum)=>{
                            console.log("item54", item, columnsFormQuick);
                            return <Grid item xs>
                                <FormRaw
                                    columnsFormQuick={columnsFormQuick}
                                    itemWithData={item}
                                    additionalEvent={ additionalTriggerAfterSave }
                                    link={props.triggerLinks.editLinkSave.replace("%rowId%",item.id)}
                                    formClassName={classesDialog.form}
                                    buttonClassName={classes.buttonMiddle}
                                    isLoadData={props.isLoadData}
                                />
                            </Grid>
                            })}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
