import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NewRequest from "./NewRequest";
import RequestFormEdit from "./RequestFormEdit";
import {ChatMessages} from "./ChatMessages";
import {StatusRequest} from './StatusRequest';

const RequestForm = () => {
    const { id } = useParams(); // Accessing ID parameter from the router
    return !id?<NewRequest />:<>
        <RequestFormEdit isStudent={false}  id={id} />
        <ChatMessages id={id} isStudent={false}  />
    </>
};

export default RequestForm;
