import React from "react";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { DataGrid } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';

export const  Courses = () =>{
    

return(
    <Container disableGutters={true}>
         <CssBaseline />
        <Typography variant="h4" component="h1" gutterBottom>
        Courses
      </Typography>
        <Box component="div" sx={{ height: '80vh', width: '100%', bgcolor: '#fff', '& .table-title': {
          fontWeight: '600',
        }, }}>
        <DataGrid
       
        columns={[{ field: 'Class', headerClassName: 'table-title' }, { field: 'Description', minWidth: 200, headerClassName: 'table-title' }, { field: 'Date', headerClassName: 'table-title' }, { field: 'Status', headerClassName: 'table-title' }]}
        rows={[
          {
            id:1,
            Class: 'MS101',
            Description: 'test test ettst ete',
            Date: '20.02.2022',
            Status: 1
          },
          {
            id:2,
            Class: 'MS101',
            Description: 'test test ettst ete',
            Date: '20.02.2022',
            Status: 1
          },
          {
            id:3,
            Class: 'MS101',
            Description: 'test test ettst ete',
            Date: '20.02.2022',
            Status: 1
          },
          {
            id:4,
            Class: 'MS101',
            Description: 'test test ettst ete',
            Date: '20.02.2022',
            Status: 1
          },
          {
            id:5,
            Class: 'MS101',
            Description: 'test test ettst ete',
            Date: '20.02.2022',
            Status: 1
          },
          {
            id:6,
            Class: 'MS101',
            Description: 'test test ettst ete',
            Date: '20.02.2022',
            Status: 1
          },
          {
            id:7,
            Class: 'MS101',
            Description: 'test test ettst ete',
            Date: '20.02.2022',
            Status: 1
          },
        ]}
      />
       </Box>
    </Container>
    
)
   
}