import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import Barcode from 'react-barcode'

const theme = createTheme({
    palette: {
        primary: {
            main: "#00A43C",
            text: "#000",
            secondary_text: "#121828",
        },
    },
    typography: {
        primary: {
            header: '1.2rem',
        }
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: "1.1rem",
                    fontWeight: 600,
                    textTransform: "inherit",
                    "@media (max-width: 640px)": {
                        fontSize: "0.777rem",
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    "@media (max-width: 640px)": {
                        fontSize: "0.777rem",
                    },
                },
                secondary: {
                    "@media (max-width: 640px)": {
                        fontSize: "0.777rem",
                    },
                },
            },
        },
    },
});

const getFinishDate = (data) => {
    let finishDate = '';
    let curFinish = '';
    let curIterator = '';
    data.forEach( itm =>{
        if (finishDate === ''){
            finishDate = new Date(itm.studyFinish);
        }else{
            curIterator = new Date(itm.studyFinish);
            if ( curIterator.getTime() > finishDate.getTime() ){
                finishDate = curIterator;
            }
        }
    })
    console.log("get Full year ", finishDate, data);
    console.log("get Full year ", finishDate.getFullYear(), finishDate.getMonth(), finishDate.getDate());
    finishDate = `${finishDate.getFullYear()}-${finishDate.getMonth()+1}-${finishDate.getDate()}`;
    return finishDate;
}

const SecondarylListInfo = (props) => {
    const { t } = useTranslation();
    return (
        <ThemeProvider theme={theme}>
            <List>
                <ListItem divider>
                    <Typography sx={{ fontSize: "primary.header", color: "primary.text", textAlign: 'center', width: '100%' }}>{t("profile.student_ticket")}</Typography>

                </ListItem>
                <ListItem divider>
                    <ListItemText
                        sx={{
                            width: {
                                xs: "100%",
                            },
                            color: "primary.text",
                        }}
                        primary={t("profile.student_ticket_id")}
                    />
                    {props.userData.studentTicketId?.length > 0 && (
                        <ListItemText
                            sx={{
                                width: {
                                    xs: "100%",
                                },
                                color: "primary.secondary_text",
                            }}
                            secondary={props.userData.studentTicketId??''}
                        />
                    )}
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        sx={{
                            width: {
                                xs: "100%",
                            },
                            color: "primary.text",
                        }}
                        primary={t("profile.student_ticket_date_issued")}
                    />
                    {props.userData.studentTicketDateGet?.length > 0 && (
                        <ListItemText
                            sx={{
                                width: {
                                    xs: "100%",
                                },
                                color: "primary.secondary_text",
                            }}
                            secondary={props.userData.studentTicketDateGet}
                        />
                    )}
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        sx={{
                            width: {
                                xs: "100%",
                            },
                            color: "primary.text",
                        }}
                        primary={t("profile.student_ticket_date_expired")}
                    />

                    {props.userData.studentTicketDateGet?.length > 0 && (
                        <ListItemText
                            sx={{
                                width: {
                                    xs: "100%",
                                },
                                color: "primary.secondary_text",
                            }}
                            secondary={getFinishDate(props.userData.UserToFaculties)}
                        />
                    )}
                </ListItem>
                {props.userData.UserToFaculties.map( item=>{
                    return <>
                        <ListItem divider sx={{flexWrap: 'wrap'}}>
                            <ListItemText
                                sx={{
                                    width: {
                                        xs: "100%",
                                    },
                                    color: "primary.text",
                                }}
                                primary={t("profile.student_ticket_facultet_spec")}
                            />
                            <ListItemText sx={{
                                width: {
                                    xs: "100%",
                                },
                                color: "primary.text",
                            }}
                             secondary={`${item.Faculty.name} / ${item.studyType===1?"Денна":"Заочна"}` }
                            />
                        </ListItem>
                        <ListItem divider>
                            <ListItemText
                                sx={{
                                    width: {
                                        xs: "100%",
                                    },
                                    color: "primary.text",
                                }}
                                primary={t("profile.student_ticket_group")}
                            />
                            <ListItemText
                                        sx={{
                                            width: {
                                                xs: "100%",
                                            },
                                            color: "primary.text",
                                        }}
                                        secondary={item?.Group?.name}
                            />
                        </ListItem>
                    </>
                } )}
                <ListItem>
                    <Barcode value={props.userData.studentTicketId} width={3} />
                </ListItem>
            </List>
        </ThemeProvider>
    );
};

export default SecondarylListInfo;