
import {axiosPromiseRun} from "../../../../utils/axiosWrap";

/**
 *
 * @param url
 * @return Promise
 */
export const fetcher = (url, method, body = {}) => {
  return  axiosPromiseRun(url, method, body);
};
