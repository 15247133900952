import React from "react";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { DataGrid } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';

export const  Finances = () =>{
    

return(
    <Container disableGutters={true}>
         <CssBaseline />
        <Typography variant="h4" component="h1" gutterBottom>
        Finances
      </Typography>
        <Box component="div" sx={{ height: '80vh', width: '100%', bgcolor: '#fff', '& .table-title': {
          fontWeight: '600',
        }, }}>
        <DataGrid
       
        columns={[{ field: 'Date', headerClassName: 'table-title' }, { field: 'Type', minWidth: 200, headerClassName: 'table-title' }, { field: 'Sum',  headerClassName: 'table-title' },  { field: 'Payment_date',headerName: 'Payment date',  headerClassName: 'table-title' }]}
        rows={[
          {
            id:1,
            Type: 'Оплата за навчання ',
            Payment_date: '01.02.2022',
            Date: '20.02.2022',
            Sum: 1015
          },
          {
            id:2,
            Type: 'Оплата за навчання ',
            Payment_date: '01.02.2022',
            Date: '20.02.2022',
            Sum: 10565
          },
          {
            id:3,
            Type: 'Оплата за навчання ',
            Payment_date: '01.02.2022',
            Date: '20.02.2022',
            Sum: 18787
          },
          {
            id:4,
            Type: 'Оплата за гуртожиток ',
            Payment_date: '01.02.2022',
            Date: '20.02.2022',
            Sum: 20
          },
          {
            id:5,
            Type: 'Оплата за навчання ',
            Payment_date: '01.02.2022',
            Date: '20.02.2022',
            Sum: 9595
          },
          {
            id:6,
            Type: 'Оплата за навчання ',
            Payment_date: '01.02.2022',
            Date: '20.02.2022',
            Sum: 7851
          },
          {
            id:7,
            Type: 'Оплата за навчання ',
            Payment_date: '01.02.2022',
            Date: '20.02.2022',
            Sum: 8000
          },
        ]}
      />
       </Box>
    </Container>
    
)
   
}