import React from 'react';
import { useTranslation } from "react-i18next";
import { Typography, Button, Link } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const Error401Page = () => {

    const Item = styled(Box)(({ theme }) => ({
        width: '100%',
        textAlign: 'center'
    }));

    const { t } = useTranslation();
    return (

        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center',
                flexWrap: 'wrap',
                height: '100vh',
            }}
        >

            <Typography variant="h2" component="div" sx={{ width: '100%', textAlign: 'center', fontSize: '10rem',fontWeight: 700 }}>
                500
            </Typography>
            <Item component="p">
                {t('error.server_error')}
            </Item>
            <Item>
                {t('error.page_500_description')}
            </Item>
            <Item sx={{mt:4}}>
                <Button

                    variant="contained"
                    color="success"
                    component={Link}
                    to="/"
                >
                    {t('text.go_home')}
                </Button>
            </Item>


        </Box>
    );
}

export default Error401Page;
