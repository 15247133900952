import React, {useState, useEffect} from 'react';
import {TextField, Autocomplete, TextareaAutosize, Button} from '@mui/material';
import {axiosRun} from '../utils/axiosWrap';
import {config} from '../config';
import {shallowEqual, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {useStylesGridDialog} from "./UI/base/GridTable/css/useStylesGridDialog";
import {useStylesGridTableActions} from "./UI/base/GridTable/css/useStylesGridTableActions";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const NewRequest = () => {
    const [facultiesGroupsList, setFacultiesGroupsList] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const [template, setTemplate] = useState(null);
    const [selectedResourceStudents, setSelectedResourceStudents] = useState(null);
    const [studentsByResources, setStudentsByResources] = useState([])
    const [studentId, setStudentId] = useState(null);
    const [message, setMessage] = useState(null);
    const user = useSelector(state => state.users.user, shallowEqual);
    const navigate = useNavigate();
    const [openAlert, setOpenAlert] = useState(false);
    const [msg, setMsg] = useState('');
    const classesDialog = useStylesGridDialog();
    const classes = useStylesGridTableActions();

    useEffect(() => {
        const fetchResources = async () => {
            const _facultiesGroupsList = await axiosRun(config.apiLinks.getFacultiesgroupList, 'GET');
            setFacultiesGroupsList(_facultiesGroupsList);
        }
        fetchResources();
    }, [])

    const handleSave = async (event) => {
        event.preventDefault();
        if (validateForm()){
            const result = await axiosRun(config.apiLinks.createNewRequest, "POST", {
                studentId: studentId.id,
                templateId: template.id,
                facultyId: selectedResourceStudents._facultyId,
                message,
                authorSnapshot: `${user.user.login}`
            })
            navigate(`/administration/chat/request/${result.id}`);
        }else{
            setOpenAlert(true);
        }
    }

    const validateForm = (  ) => {
        const validatioObj = {
            student:{val:studentId?.id,message:'виберіть студента'},
            template: {val:template, message: 'виберіть шаблон зверення'},
            faculty: {val:selectedResourceStudents?._facultyId, message: 'виберіть факультет'},
            message: {val:message, message: 'напишіть шось у зверенні'}};
        let warningMsg = [];
        Object.keys(validatioObj).map( inx =>{
            const item = validatioObj[inx];
            if ( !item.val){
                warningMsg.push(item.message);
            }
        })
        setMsg(warningMsg.join(" "));
        return !warningMsg.length;
    }

    const setResourceAndLoadStudents = async (event, value) => {
        setSelectedResourceStudents(value);
        setStudentId(null);
        if (value) {
            const _templateList = await axiosRun(config.apiLinks.getTemplateList, 'GET');
            setTemplateList([]);
            console.log( "TEMNPLATE LISTY ", _templateList, value  );
            if (_templateList.length) {
               const _filterd = _templateList.filter(itm => itm.facultyId === value._facultyId);
                setTemplateList(_filterd);
            }
            const list = await axiosRun(`${config.apiLinks.getUserList}?filtersValue={"faculty_sp_spec_group":${JSON.stringify(value)}}`, 'GET');
            console.log("list ", list, list.length);
            if (list.count) {
                setStudentsByResources(list.data);
            }
        } else {
            setStudentsByResources([]);
            setMessage('');
            setTemplate(null);
        }
    };


    const setChatStudent = (event, value) => {
        setStudentId(value);
    }
    const setTemplateRequest = (event, value) => {
        setTemplate(value);
        setMessage(value.description);
    }


    return (<>
        <Paper elevation={3} sx={{width: '100%', margin: '0 auto', marginTop: "10px", position: 'relative'}}>
            <Snackbar
                open={openAlert}
                autoHideDuration={10000}
                onClose={() => {
                    setOpenAlert(false);
                }}
            >
                <Alert
                    onClose={() => {
                        setOpenAlert(false);
                    }}
                    variant="filled" severity="error"
                    sx={{ width: "100%" }}
                >
                    {msg}
                </Alert>
            </Snackbar>
            <Box component={"div"}>
                <Box sx={{paddingLeft: '28px', paddingTop: '15px', paddingBottom: '20px'}} component={"div"}>
                    <Typography variant={'h4'} component={'div'}> Створення нового запиту до студента </Typography>
                </Box>
                <Box component="div">
                    <form key={"quick_form_add"} className={classesDialog.formColumn}>
                        <Autocomplete
                            options={facultiesGroupsList}
                            value={selectedResourceStudents}
                            onChange={setResourceAndLoadStudents}
                            className={classesDialog.formItemColumn}
                            renderInput={(params) => <TextField {...params}
                                                                label="Факультет спеціальність спеціалізація група"/>}
                            fullWidth
                        />
                        <Autocomplete
                            options={templateList}
                            value={template}
                            onChange={setTemplateRequest}
                            getOptionLabel={(option) =>
                                option ? option.name : ''
                            }
                            className={classesDialog.formItemColumn}
                            renderInput={(params) => <TextField {...params} label="Шаблон звернення"/>}
                            fullWidth
                        />
                        <Autocomplete
                            options={studentsByResources}
                            getOptionLabel={(option) =>
                                option ? option.firstName + ' ' + option.lastName + ' ІПН(' + option.ipnNumber + ')' : ''
                            }
                            value={studentId}
                            className={classesDialog.formItemColumn}
                            onChange={setChatStudent}
                            renderInput={(params) => <TextField {...params} label="Виберіть студента"/>}
                            fullWidth
                        />
                        <CKEditor editor={DecoupledEditor}
                                  config={{
                                      toolbar: [  'bold', 'italic',  'alignment' ],
                                      heading: {
                                          options: [
                                              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                              { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                              { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                                          ],

                                      },
                                  }}
                                  onReady={ editor => {
                                      console.log( 'Editor is ready to use!', editor.ui.getEditableElement() );
                                      // Insert the toolbar before the editable area.
                                      editor.ui.getEditableElement().parentElement.insertBefore(
                                          editor.ui.view.toolbar.element,
                                          editor.ui.getEditableElement()
                                      );
                                      editor.editing.view.change((writer) => {
                                          writer.setStyle(
                                              "width",
                                              "100%",
                                              editor.editing.view.document.getRoot()
                                          );
                                      });

                                  } }
                                  data={message} onChange={(event,editor) => {
                            setMessage(editor.getData())

                        }
                        } />
                        <Box sx={{paddingBottom: "30px", textAlign: 'right'}}>
                            <Button onClick={handleSave} className={classes.buttonMiddle} variant="contained"
                                    color="primary" type="submit" startIcon={<SaveIcon/>}>
                                Save
                            </Button>
                        </Box>
                    </form>
                </Box>

            </Box>
        </Paper>
    </>)

}


export default NewRequest;