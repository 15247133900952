import React from "react";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export const FormRegisterWithPhone = (props)=>{
    const {form, setForm,fields,errors} = props;
    const pushForm = (event)=>{
        if (errors[event.target.name] !== undefined){
           delete errors[event.target.name];
        }
        const newForm = Object.assign({},form,{[event.target.name]:event.target.value});
        setForm(newForm);
    }
    console.log("fields for step2 ", fields);

    return (
        <Box
            component="form"
            sx={{
                display: 'flex',
                flexDirection: 'column'
            }}
            noValidate
            autoComplete="off"
        >
            {Object.keys(fields).map((field, inx)=>{
                let item = fields[field];
                if (item['isRenderWithAll']){
                    if ( errors[field]!==undefined ){
                        return <FormControl error variant="standard">
                            <InputLabel key={inx+'label'} htmlFor={inx+'id'}>{item.label}</InputLabel>
                            <Input id={inx+'id'} key={inx+'input'} id="component-simple" name={field} value={form[field]?form[field]:''} onChange={pushForm}  />
                            <FormHelperText key={inx+'helper'} id="component-error-text">{errors[field]['error']}</FormHelperText>
                        </FormControl>
                    }
                    return <FormControl variant="standard">
                        <InputLabel htmlFor="component-simple">{item.label}</InputLabel>
                        <Input key={inx} id="component-simple" name={field} value={form[field]?form[field]:''} onChange={pushForm}  />
                    </FormControl>
                }
            })}
            {/*<Box component={'div'} sx={{mt:'10px'}}>*/}
            {/*    <FormControl error={ errors['phone']!==undefined?true:false} variant="standard">*/}
            {/*        <PhoneInput*/}
            {/*            country={'ua'}*/}
            {/*            value={form['phone']?form['phone']:''}*/}
            {/*            placeholder={'put phone number'}*/}
            {/*            onChange={ phone => {*/}
            {/*                delete errors['phone'];*/}
            {/*                const newForm = Object.assign({},form,{['phone']:phone});*/}
            {/*                setForm(newForm);*/}
            {/*            }}*/}
            {/*        />*/}
            {/*        {errors['phone']!==undefined&&<FormHelperText id="component-error-text">{errors['phone']['error']}</FormHelperText>}*/}
            {/*    </FormControl>*/}
            {/*</Box>*/}
        </Box>
    );

}