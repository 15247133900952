import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import {useStylesGridDialog} from "./UI/base/GridTable/css/useStylesGridDialog";
import {useStylesGridTableActions} from "./UI/base/GridTable/css/useStylesGridTableActions";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import {useTranslation} from "react-i18next";
import {OpenInfoDialog} from "./OpenInfoDialog";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

export const StatusRequest = (props) => {
    const [status, setStatus] = React.useState(props.dataRequest.status);
    const [isLoad, setIsLoad] = React.useState(false);
    const classesDialog = useStylesGridDialog();
    const classes = useStylesGridTableActions();
    const { t, i18n } = useTranslation();
    const [messageStatusWindow, setMessageStatusWindow] = useState(false);
    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    console.log("req data ", props.dataRequest.template.type);
    const reqStatuses = config.statusesRequest[props.dataRequest.template.type];

    const sendData = async (event) => {
       setIsLoad(true);
       try{
           const result = await axiosRun(config.apiLinks.updateRequestStatus, 'POST',
               {id:props.id, status:status, isRequest: props.dataRequest.template.type=== '1'}
           )
           console.log("result msg ", result.msg, setMessageStatusWindow);
           if (result.msg === 'ok'){
               setMessageStatusWindow("статус змінено");
           }else{
               setMessageStatusWindow("Виникла помилка");
           }
           setIsLoad(false);
       }catch (error){
           setIsLoad(false);
           setMessageStatusWindow("виникла помилка спробуйте пізніше!!!!");
       }

    }

    return (
        <Paper elevation={2} sx={{maxWidth: '650px', mb: "10px", paddingTop:"10px", position: 'left'}}>
            <Box sx={{paddingLeft: '28px'}} component={"div"}>
                <Typography variant={'h6'} component={'div'}>управління</Typography>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoad}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <OpenInfoDialog isOpen={messageStatusWindow} onClose={
                ()=>{
                    setMessageStatusWindow(false);
                }
            } onYes={async ()=>{
                                setMessageStatusWindow(false);
                            }}
                            text={messageStatusWindow}
            />

            <Box component="div">
                <form key={"quick_form_add"} className={classesDialog.form}>
                    <FormControl sx={{ marginTop: "10px", width: 300 }}>
                        <InputLabel id="demo-multiple-name-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            className={classesDialog.formItem}
                            onChange={handleChange}
                        >
                            {Object.keys(reqStatuses).map(key=>{
                                return <MenuItem value={key}>{t(reqStatuses[key])}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </form>
                <Box sx={{paddingBottom: "30px", paddingLeft: '30px', textAlign: 'left'}}>
                    <Button onClick={e => sendData()} variant="contained">Apply</Button>
                </Box>
            </Box>
        </Paper>
    );
}
