import {useEffect} from 'react';

import {instanceAxious} from "./axiosWrap";
import {useAsyncError} from "./errorHadler";

const AxiosErrorHandler = ({children}) => {
    const thrErr = useAsyncError();
    useEffect(() => {

        const requestInterceptor = instanceAxious.interceptors.request.use((request) => {
            console.log("request ", request);
            // Do something here with request if you need to
            return request;
        });

        // Response interceptor
        const responseInterceptor = instanceAxious.interceptors.response.use((response) => {
            // Handle response here
            console.log("response ", response);
            return response;
        }, (error) => {
            console.log("error 22 ", error);
            // Handle errors here
            if (error.response?.status === 403 || error.response?.status === 401 || error.response?.status === 500 ) {
                switch (error.response.status) {
                    case 401:
                        // thrErr(new Error(401));
                        localStorage.removeItem('userInfo');
                        window.location.reload();
                        break;
                    case 403:
                        thrErr(new Error(403));
                        // setTimeout( ()=>{
                        //     localStorage.removeItem('userInfo');
                        //     window.location = '/logout';
                        // }, [15000] )
                        // Handle Unauthorized here
                        break;
                    case 500:
                        thrErr(new Error(500))
                        break;

                    default:
                        return new Error(error);

                    // ... And so on
                }
            }
            console.log("error retyurn", error);
            throw error;
        });

        return () => {
            // Remove handlers here
            instanceAxious.interceptors.request.eject(requestInterceptor);
            instanceAxious.interceptors.response.eject(responseInterceptor);
        };
    }, [thrErr]);

    return children;
};

export default AxiosErrorHandler;