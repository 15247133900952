import React, { useRef ,useState } from "react";
import {config} from "../../../config";
import {FormUserWrapper} from "../FormUserWrapper";
import {useTranslation} from "react-i18next";

export const Reset = () => {

    const { t } = useTranslation();

        const fields = {
        'email': { 'label':t('reg.email'), 'isEmpty': false,'isRenderWithAll': true },
        // 'phone': {'label': t('reg.phone'), 'isEmpty': false, 'isRenderWithAll': false}
    }
   
    
    return (
        <>
           <FormUserWrapper fields={fields} logoText={t("reg.reset_password")} submitLink={config.apiLinks.resetUser} />
        </>
    )
}