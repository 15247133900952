import React, {useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import {useSelector, shallowEqual} from 'react-redux';
export const PrivateRoute = ({ children }) => {
    const user = useSelector(state=>state.users.user, shallowEqual);
    const gotUserInfo = useSelector(state=>state.users.gotUserInfo);
    if (gotUserInfo){
        if (  Object.keys(user).length > 0) {
            return children
        } else if (Object.keys(user).length === 0 ) {
            return <Navigate to={'/'}/>
        }
    }
}

export const PublicRoute = ({children}) =>{
    const user = useSelector(state=>state.users.user);
    const gotUserInfo = useSelector(state=>state.users.gotUserInfo);
    if (gotUserInfo){
        if (Object.keys(user).length === 0) {
            return children
        } else if (user.isAdmin === false){
            return <Navigate to={'/profile/info'}/>
        } else{
            return <Navigate to={'/administration/users/list/'}/>
        }
    }
}

export const SuperPrivateRoute = ({ children }) => {
    const user = useSelector(state=>state.users.user, shallowEqual);
    const gotUserInfo = useSelector(state=>state.users.gotUserInfo);
    console.log("got user info ", gotUserInfo, user.user);

    if (gotUserInfo&&user.user){
        if (  user.user.roles === 'SUPER_ADMIN' || user.user.roles === 'ADMIN_GROUP_LEAD'  ) {
            return children
        } else if (Object.keys(user).length === 0 ) {
            return <Navigate to={'/superadmin/login'}/>
        }else{
            return <Navigate to={'/superadmin/login'}/>
        }
    }
    else if (gotUserInfo&&user.user===undefined){
        return <Navigate to={'/superadmin/login'}/>
    }
}

export const AdminFacultyRoute = ( {children} )=>{
    const user = useSelector(state=>state.users.user, shallowEqual);
    const gotUserInfo = useSelector(state=>state.users.gotUserInfo);
    if (gotUserInfo&&user.user){
        if (
            user.user.roles === 'SUPER_ADMIN' ||
            user.user.roles === 'ADMIN_FACULTY' ||
            user.user.roles === 'ADMIN_DEPARTMENT' ||
            user.user.roles === 'ADMIN_GROUP_LEAD' ||
            user.user.roles === 'ADMIN_ALL_FACULTY'
        ){
            return children;
        } else if (Object.keys(user).length === 0 ) {
            return <Navigate to={'/superadmin/login'}/>
        }
    }else if (gotUserInfo&&user.user===undefined){
        return <Navigate to={'/superadmin/login'}/>
    }
}


