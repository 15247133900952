import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export const DialogWithPayments = ({paymentData,onOpen,open})=>{
    const handleClose = () => {
        onOpen(false);
    };
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Платіжка найближчого платежу
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <b>{paymentData.pDateText}</b>
                    </Typography>
                    <Typography align={"center"}>
                        {`РАХУНОК від ${paymentData.dateGenerate}`}
                    </Typography>
                    <Typography align={"center"}>
                        <b>{'Студент'}</b><br />
                    </Typography>
                    <Typography >
                        {`${paymentData.lastName} ${paymentData.firstName} ${paymentData.middleName}`}
                    </Typography>
                    <Typography align={"center"}>
                        <b>{'Призначення платежу'}</b>
                    </Typography>
                    <Typography gutterBottom>
                        Оплата за навчання <br />
                        {paymentData.numberDeal&&`${paymentData.numberDeal}`}<br />
                        Факультет{` ${paymentData.faculty}`}
                        {paymentData.codeFaculty&&<><br />{`Код факультету: ${paymentData.codeFaculty}`}</>}
                        <br />Спеціальність (напрям):{` ${paymentData.speciality}, ${paymentData.specialization} ${paymentData.course}`} <br />
                    </Typography>
                    <Typography align={"center"}>
                        <b>{'Загальна сума, що підлягає оплаті'}</b><br />
                        <b>{paymentData.sum}</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                            Закрити
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
