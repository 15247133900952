import { alpha, makeStyles } from '@mui/styles';

export const useStylesEnhancedTableToolbar = makeStyles((theme) => ({
    title: {
        flex: '0 0 auto',
    },
    toolbarTop: {
        // backgroundColor: "#e0e0e0"
    },

}));