import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { Item } from "./Item";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import EditProfile from "../EditProfile";
import GeneralListInfo from "../GeneralListInfo";
import SecondarylListInfo from "../SecondarylListInfo";
import FacultieslListInfo from "../FacultieslListInfo";
import {shallowEqual, useSelector} from "react-redux";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00A43C",
      text: "#000",
      secondary_text: "#121828",
    },
    
  },

  components: {
    MuiTabs: {
      styleOverrides: {
        scrollButtons:{
          "@media (min-width: 1140px)": {
            display:"none",
          },
        }
      }
    },

  },
});

export const ProfileTabs = (data) => {
  const [value, setValue] = useState(0);

  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };
  return (
    <>
      <Box sx={{ borderColor: "divider" }}>
        <ThemeProvider theme={theme}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons
          >
            <Tab
              label={t("profile.generalTabInfomation")}
              id="simple-tab-0"
              aria-controls="simple-tabpanel-0"
            />
            {!data.data.isApplicant&&<Tab
              label={t("profile.additionalInfo")}
              id="simple-tab-1"
              aria-controls="simple-tabpanel-1"
            />}
            <Tab
              label={t("profile.facultiesAndSpec")}
              id="simple-tab-2"
              aria-controls="simple-tabpanel-3"
            />
            <Tab
              label={t("profile.uploadNewPhoto")}
              id="simple-tab-2"
              aria-controls="simple-tabpanel-4"
            />
          </Tabs>
        </ThemeProvider>
      </Box>

      <Box sx={value===1?{maxWidth: '350px', margin: '0 auto'}: {}}>
        <Item elevation={3} sx={{ padding: 0 }}>
          <TabPanel value={value} index={0}>
            <GeneralListInfo  userData={data.data} />
          </TabPanel>
          {!data.data.isApplicant&&<TabPanel value={value} index={1}>
            <SecondarylListInfo userData={data.data} />
          </TabPanel>}
          <TabPanel value={value} index={!data.data.isApplicant?2:1}>
            <FacultieslListInfo userData={data.data} />
          </TabPanel>
          <TabPanel value={value} index={!data.data.isApplicant?3:2}>
            <EditProfile userData={data.data} setUserData={data.setUserData} />
          </TabPanel>
        </Item>
      </Box>
    </>
  );
};
