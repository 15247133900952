import React, {useEffect, useState} from "react";
import GridTable from "./UI/base/GridTable/components/GridTable";
import {config} from "../config";
import {axiosRun} from "../utils/axiosWrap";

export const AdminsList = ()=>{

    const [isLoad,setIsLoad] = useState(true);
    const [departments, setDepartments] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnsForQuick, setColumnsForQuick] = useState([]);
    useEffect( ()=>{
        const loadAllReq = async ()=> {
            const departments = await axiosRun(config.apiLinks.getDepartmentsList, 'GET');
            setDepartments(departments.map(itm => {itm.label = itm.name; return itm;}));
            setIsLoad(false);
        };
        if (isLoad){
            loadAllReq();
        }
        console.log("DEPARTMENTS", departments);
    }, [isLoad])

    useEffect( () => {
        if (!isLoad && Object.keys(departments).length>0 ){
            const _columns = [
                { id: 'login', numeric: false, disablePadding: false, label: 'Login', isLinkItem: true },
                { id: 'lastName', numeric: false, disablePadding: false, label: 'ПІБ', isLinkItem: true },
                { id: 'firstName', numeric: false, disablePadding: true, label: "відділ/посада", isLinkItem: true },
                { id: 'email', numeric: false, disablePadding: true, label: "пошта", isLinkItem: true },
                { id: 'roles', numeric: false, disablePadding: true, label: "Роль", isLinkItem: true },
            ];
            const _columnsFormQuick = [
            ];

            setColumns(_columns);
            setColumnsForQuick(_columnsFormQuick)
        }
    }, [isLoad,departments])

    const  triggerLinks = {
        editLinkSave: config.apiLinks.addOrEditStudent,
        createLinkSave: config.apiLinks.addOrEditStudent+"?isAdmin=1",
        refreshLink: 'products',
        linkItem: '/administration/administrator/item/%itemId%',
        deleteLink: 'products/remove',
        redirectAfterAdd: '/administration/admin/item/%itemId%'
    }

    return <>
        {!isLoad&&<GridTable
            linkGetData={config.apiLinks.getAdminList}
            columns={columns}
            triggerLinks={triggerLinks}
            columnsFormQuick={columnsForQuick}
            tableHeaderText={"Administrators"}
            rows4page={10}
            addLink={"/administration/administrator/item/new"}
            isAddLink={true}
            quickViewNotAllowed={true}
            filtersList={[
                { name: 'lastName', label: "Опис 1", type: 'text' },
                { name: 'firstName', label: "Опис 2", type: 'text' },
                { name: 'email', label: 'пошта', type: 'text' },
                { name: 'roles', label: 'Роль', type: 'select', options: [
                        {value:'SUPER_ADMIN', label: "Супер адмін"},
                        {value:'ADMIN_FACULTY', label: "Адмін факультету"},
                        {value:'ADMIN_DEPARTMENT', label: "Адмін відділу"}
                    ]
                },
                { name: "departmentId", label: "Відділ(тільки для адміна відділу)", type: 'autocomplete', options: departments }
            ]}
        />}
        </>

}