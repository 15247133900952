import React, {useEffect, useState} from "react";
import GridTable from "./UI/base/GridTable/components/GridTable";
import {config} from "../config";
import {axiosRun} from "../utils/axiosWrap";

export const RequestsList = ()=>{

    const [isLoad,setIsLoad] = useState(true);
    const [faculties, setFaculties] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [students, setStudents] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnsForQuick, setColumnsForQuick] = useState([]);
    const [facultiesGroupsList, setFacultiesGroupsList] = useState([]);

    useEffect( ()=>{
        const loadAllReq = async ()=> {
            const faculties = await axiosRun(config.apiLinks.getFacultiesListClear, 'GET');
            let converted = {};
            faculties.forEach( (itm,inx) =>{
                converted[itm.id] = itm;
            })
            setFaculties(converted);

            const _templates = await axiosRun(config.apiLinks.getTemplateList, 'GET');
            converted = {};
            _templates.forEach( (itm,inx) =>{
                converted[itm.id] = itm;
            })
            setTemplates(converted);

            const _facultiesGroupsList = await axiosRun(config.apiLinks.getFacultiesgroupList, 'GET');
            setFacultiesGroupsList(_facultiesGroupsList);
            setIsLoad(false);
        };
        if (isLoad===true){
            console.log("1", isLoad);
            loadAllReq();
        }
    }, [isLoad])

    useEffect( () => {
        if (!isLoad && Object.keys(faculties).length>0 ){
            const _columns = [
                { id: 'User.firstName', numeric: false, disablePadding: false, label: "Ім'я", isLinkItem: true },
                { id: 'User.lastName', numeric: false, disablePadding: false, label: 'Прізвище', isLinkItem: true },
                { id: 'User.middleName', numeric: false, disablePadding: false, label: 'По батькові', isLinkItem: true },
                { id: 'templateId', numeric: false,type:'entityList', entityList: templates, disablePadding: false, label: 'Шаблон', isLinkItem: true },
                { id: 'facultyId', numeric: false, type:'entityList', entityList: faculties, disablePadding: true, label: "факультет", isLinkItem: true },
                { id: 'createdAt', numeric: false, disablePadding: true, label: "Дата створення", isLinkItem: true },
                { id: 'status', numeric: false, disablePadding: true, label: "статус", isLinkItem: true },
            ];
            const _columnsFormQuick = [
                { id: 'name', numeric: false, disablePadding: false, label: 'Назва шаблону', isLinkItem: true, type: "text" },
                { id: 'description', numeric: false, disablePadding: true, label: "Опис для студента", isLinkItem: true, type: "textMultiline" },
                {id: 'adminDescription', numeric: false, disablePadding: true, label: "Інструкція для деканата", isLinkItem: true, type: "textMultiline"},
                { id: 'facultyId', label: "Факультет", isRequired: true, entityName: "facultyId",
                    entityIdentify: "facultyId",
                    displayType: "Select",
                    type: 'entityList',
                    entityList:faculties
                }
            ];
            setColumns(_columns);
            setColumnsForQuick(_columnsFormQuick)
        }
    }, [isLoad,faculties,students,templates])

    const  triggerLinks = {
        createLinkSave: config.apiLinks.createTemplate,
        editLinkSave: config.apiLinks.editTemplatesItem,
        linkItem: '/administration/chat/request/%itemId%',
        deleteLink: 'products/remove',
        redirectAfterAdd: '/administration/templates/list'
    }

    return <>
        {!isLoad&&<GridTable
            linkGetData={config.apiLinks.getRequestsListClear}
            columns={columns}
            triggerLinks={triggerLinks}
            columnsFormQuick={columnsForQuick}
            tableHeaderText={"Запити від/до студентів"}
            rows4page={10}
            addLink={"/administration/chat/request/new"}
            isAddLink={true}
            quickViewNotAllowed={true}
            filtersList={[
                { name: '$User.firstName$', label: "Ім`я", type: 'text' },
                { name: '$User.lastName$', label: "Прізвище", type: 'text' },
                { name: '$User.middleName$', label: 'По батькові', type: 'text' },
                { name: '$User.ipnNumber$', label: 'IПН', type: 'text' },
                { name: "faculty_sp_spec_group", label: "Факультет/спеціальність/спеціалізація/група", type: 'autocomplete', options: facultiesGroupsList },
                { name: '$template.type$', label: 'Тип', type: 'autocomplete', options: [{id: '1', label: "Заява"},{id:'0', label: "Звернення"}]}]}
        />}
        </>

}