import React, { useState, useEffect } from 'react';
import {List, ListItem, Link, ListItemText, ListItemIcon, CircularProgress, Paper} from '@mui/material';
import {shallowEqual, useSelector} from "react-redux";
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";

export const LinkList = () => {
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    useEffect(() => {
        (async ()=>{
           const links =  await axiosRun( config.apiLinks.profileGetLinks, 'GET' );
           setLinks(links);
           setLoading(false);
        })();
    }, []);

    return (
        <div>
            {loading ? (
                <CircularProgress />
            ) : (
                <Paper>
                    <Box sx={{display:"flex", pt: "10px", pl: "10px", flexDirection: "column", gap:"5px",justifyContent: "flex-start", alignItems:"flex-start"}}>
                        <Typography variant="h4">{t("profile.Links")}</Typography>
                        <List>
                            {links.map((link, index) => (
                                <ListItem key={index}>
                                    <Link href={`${link.link}`} sx={{color:'#424242'}} target="_blank" rel="noopener noreferrer">
                                        {link.name}
                                    </Link>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Paper>
            )}
        </div>
    );
}
