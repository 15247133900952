import React from 'react';

const ErrorPage = (props) => {
    return (
        <div>
            {/*<h1>Oops! Something went wrong.</h1>*/}
            {/*<p>Please try again later.</p>*/}
            {/*<p>{props.message}</p>*/}
        </div>
    );
}

export default ErrorPage;
