import React from "react";
import { Box, Container } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import dayjs from "dayjs";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
export const Calendar = () => {
  const [date, setDate] = React.useState(dayjs("2022-04-07"));
  return (
    <>
    
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CssBaseline />
        <Typography variant="h4" component="h1" gutterBottom>
        Calendar
      </Typography>
        <Container disableGutters={true} sx={{ bgcolor: "#fff" }}>
          <CalendarPicker
            date={date}
            onChange={(newDate) => setDate(newDate)}
          />
        </Container>
      </LocalizationProvider>
      <Box disableGutters={true} sx={{ mt: 2 }}>
        <List sx={{bgcolor: "#fff"}} component="nav" aria-label="mailbox folders">
          <ListItem button>
            <ListItemText primary="Inbox" />
          </ListItem>
          <Divider />
          <ListItem button divider>
            <ListItemText primary="Task 70" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Payment 20.02.2023" />
          </ListItem>
          <Divider light />
          <ListItem button>
            <ListItemText primary="Task 01" />
          </ListItem>
        </List>
      </Box>
    </>
  );
};
