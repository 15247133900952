import React, { memo, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useStylesGridDialog } from "../css/useStylesGridDialog";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import { useFormItem } from "../hooks/FormItem.hook";
import Checkbox from "@mui/material/Checkbox";
import Input from "@mui/material/Input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box } from "@mui/system";
import {EntityListManyValues} from "./customItems/EntityListManyValues";
import { FormHelperText } from "@mui/material";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";


const FormItem = (props) => {
  const classesDialog = useStylesGridDialog();
  const { itemColumn, lockedProps, entitiesList, isDiaolog } = props;
  const {
    onChangeText,
    onChangeSelect,
    onChangeRadio,
    onChangeCheckBox,
    value,
    onChangePhone,
    onChangeDate,
    onChooseMultipleSelect,
    externalFormSetter,
    onChangeCK
  } = useFormItem(props);
  return (
    <>
      {itemColumn.type === "entityList" &&
        itemColumn.displayType === "Select" && (
          <FormControl error={props.errorsFromServer[itemColumn.id]?true:false}  sx={{mb:2}} className={ !isDiaolog && classesDialog.formItem}>
            <InputLabel id="demo-simple-select-helper-label">
              {itemColumn.label}
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              label={itemColumn.label}
              id="demo-simple-select"
              name={itemColumn.entityName}
              value={value}
              variant={'outlined'}
              disabled={
                lockedProps && lockedProps.entityName === itemColumn.entityName
              }
              onChange={onChangeSelect}
              MenuProps={{
               sx:{maxHeight: '200px', maxWidth: '300px'}
              }}
              
            >
              {itemColumn.trigger
                ? entitiesList[itemColumn["entityName"]].map((entityItem) => {
                    return (
                      <MenuItem value={entityItem.id} sx={{whiteSpace: 'break-spaces'}}>
                        {entityItem.name}
                      </MenuItem>
                    );
                  })
                : Object.keys(itemColumn.entityList).map((entityItemInx) => {
                    const entityItem = itemColumn.entityList[entityItemInx];
                    return (
                      <MenuItem value={entityItem.id} sx={{whiteSpace: 'break-spaces'}}>
                        {entityItem.name}
                      </MenuItem>
                    );
                  })}
            </Select>
            
            {props.errorsFromServer[itemColumn.id]?(<FormHelperText>{props.errorsFromServer[itemColumn.id]}</FormHelperText>):false}
          </FormControl>
        )}
      {itemColumn.type === "entityList" &&
        itemColumn.displayType === "Radio" && (
          <FormControl error={props.errorsFromServer[itemColumn.id]?true:false} component="fieldset" className={ !isDiaolog && classesDialog.formItem}>
            <FormLabel component="legend">{itemColumn.label}</FormLabel>
            <RadioGroup
              disabled={
                lockedProps && lockedProps.entityName === itemColumn.entityName
              }
              aria-label={itemColumn.label}
              name={itemColumn.entityName}
              value={value}
              onChange={onChangeRadio}
            >
              {entitiesList[itemColumn["entityName"]].map((entityItem) => {
                return (
                  <FormControlLabel
                    value={entityItem.id}
                    control={<Radio />}
                    label={entityItem.name}
                  />
                );
              })}
            </RadioGroup>
            {props.errorsFromServer[itemColumn.id]?(<FormHelperText>{props.errorsFromServer[itemColumn.id]}</FormHelperText>):false}
          </FormControl>
        )}

      {itemColumn.type === "bool" && itemColumn.displayType === "Checkbox" && (
        <FormControl error={props.errorsFromServer[itemColumn.id]?true:false} component="fieldset" className={ !isDiaolog && classesDialog.formItem}>
          <FormLabel component="legend">{itemColumn.label}</FormLabel>
          <Checkbox
            checked={value}
            name={itemColumn.id}
            onChange={onChangeCheckBox}
          />
          {props.errorsFromServer[itemColumn.id]?(<FormHelperText>{props.errorsFromServer[itemColumn.id]}</FormHelperText>):false}
        </FormControl>
      )}

      {
          itemColumn.type === "textMultiline" && (
              <>
                <TextField
                    error={props.errorsFromServer[itemColumn.id]?true:false}
                    helperText={props.errorsFromServer[itemColumn.id]?props.errorsFromServer[itemColumn.id]:false}
                    key={itemColumn.id}
                    id="component-simple"
                    label={itemColumn.label}
                    variant="outlined"
                    name={itemColumn.id}
                    value={value}
                    onChange={onChangeText}
                    sx={{ mb: 2 }}
                    multiline={true}
                    rows={5}
                    className={ !isDiaolog && classesDialog.formItem}
                />
              </>
          )
        // <FormControl variant="standard">
        //     <InputLabel htmlFor="component-simple">{itemColumn.label}</InputLabel>
        //     <Input key={itemColumn.id} id="component-simple" name={itemColumn.id} value={value} onChange={onChangeText}  />
        // </FormControl>
      }

      {
        itemColumn.type === "text" && (
          <>
          <TextField
          error={props.errorsFromServer[itemColumn.id]?true:false}
          helperText={props.errorsFromServer[itemColumn.id]?props.errorsFromServer[itemColumn.id]:false}
            key={itemColumn.id}
            id="component-simple"
            label={itemColumn.label}
            variant="outlined"
            name={itemColumn.id}
            value={value}
            onChange={onChangeText}
            sx={{ mb: 2 }}
            className={ !isDiaolog && classesDialog.formItem}
          />
          </>
        )
        // <FormControl variant="standard">
        //     <InputLabel htmlFor="component-simple">{itemColumn.label}</InputLabel>
        //     <Input key={itemColumn.id} id="component-simple" name={itemColumn.id} value={value} onChange={onChangeText}  />
        // </FormControl>
      }

      {itemColumn.type === "phone" && (
        <Box component={'div'} sx={{mb: 2, width:'100%'}} className={ !isDiaolog && classesDialog.formItem}>
      
          <PhoneInput
            country={"ua"}
            value={value}
            specialLabel={itemColumn.label}      
            onChange={(phone) => {
              onChangePhone(itemColumn.id, phone);
            }}
            inputStyle={{
                'padding': '27px 10px 27px 60px',
                'width':'100%'
            }}
            buttonStyle={{
                background: 'none',
                borderRight: 0
            }}
          />
        </Box>
      )}

      {itemColumn.type === "date" && (
        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <DatePicker
          className={ !isDiaolog && classesDialog.formItem}
            placeholder="01.01.2000"
            value={value}
            label={itemColumn.label}
            onChange={(newValue) => {
              onChangeDate(itemColumn.id, newValue);
            }}
            renderInput={(params) => <TextField {...params} sx={{ mb: 2 }} />}
          />
        </LocalizationProvider>
      )}
      {itemColumn.type === 'Component' && <>
        {React.createElement(
            itemColumn.component,
            {values: value,
              formData: props.formData,
              conditions: itemColumn.conditionProps,
              externalFormSetter: externalFormSetter,
              itemWithData: props.itemWithData
            }
        )}
      </> }
      {console.log("itemColumn", itemColumn)}
      { itemColumn.type === 'entityListManyValues'&&<>
          <EntityListManyValues
              specialLabel={itemColumn.label}
              entityName={itemColumn.id}
              values={value}
              entityList={itemColumn.entityList}
              valuesSetter={onChooseMultipleSelect}
              dialogField = {!isDiaolog?false:true}
          />
      </>}
      { itemColumn.type === 'ckeditor'&&<div id={`ckeditor-field-${itemColumn.id}`}>
        <>
        <CKEditor editor={DecoupledEditor}
                  config={{
                    toolbar: [ 'heading', 'fontSize','fontFamily' , '|', 'bold', 'italic', 'link', 'alignment' ,'bulletedList', 'numberedList', 'blockQuote' ],
                    heading: {
                      options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                      ],
                      pagination: {
                        // A4
                        pageWidth: '21cm',
                        pageHeight: '29.7cm',

                        pageMargins: {
                          top: '20mm',
                          bottom: '20mm',
                          right: '12mm',
                          left: '12mm'
                        }
                      },
                    }}}
                  onReady={ editor => {
                    console.log( 'Editor is ready to use!', editor.ui.getEditableElement() );
                    // Insert the toolbar before the editable area.
                    editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                    );
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                          "width",
                          "600px",
                          editor.editing.view.document.getRoot()
                      );
                    });
                  } }
                  data={value} onChange={(event,editor) => onChangeCK(itemColumn.id,editor.getData())} />
        </>
      </div>}
    </>
  );
};

export default memo(FormItem);
