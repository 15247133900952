import React, {useEffect,useState} from "react";
import { alpha, makeStyles } from '@mui/styles';
import { Paper } from "@mui/material";
import { TextInput } from "./TextInput.js";
import { MessageRight } from "./messagesChat";
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import LinearProgress from '@mui/material/LinearProgress';
import {io} from 'socket.io-client';

import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {restoreAuthData, setRestoredAuth} from "../slices/usersSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
    ({
        paper: {
            height: "80vh",
            maxHeight: "700px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "relative"
        },
        paper2: {
            width: "80vw",
            maxWidth: "500px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column-revers",
            position: "relative"
        },
        container: {
            maxWidth: "650px",
            height: "100%",
        },
        messagesBody: {
            display: "flex",
            flexDirection: 'column-reverse',
            width: "calc( 100% - 20px )",
            margin: 10,
            overflowY: "auto",
            paddingRight: '10px',
            paddingLeft: '10px',
            height: "calc( 100% - 80px )"
        }
    }));

export const ChatMessages= ({id, isStudent})=>{
    const classes = useStyles();
    const [messages, setMessages] = useState([]);
    const user = useSelector(state=>state.users.user, shallowEqual);
    const socket = useSelector(state=>state.users.socket, shallowEqual);
    const [isLoad, setIsLoad] = useState(true);
    const dispatch = useDispatch();
    const [isSocketDisconnected, setIsSocketDisconnected] = useState(null);
    const [showConnected, setShowConnected] = useState(false);
    const [showDisconnected, setShowDisconnected] = useState(false);
    const _setterMessage = (data) =>{
        console.log("data 70", data);
        setMessages(data);
        setIsLoad(false);
        socket.emit("igotAll",{id});
    }

    const loadMessages = ()=>{
        socket.connect();
        console.log("connect ", socket);
        socket.emit("joinRoom", {id} );
        socket.on( 'gotAllRoomMsg', _setterMessage);
        socket.on('IhaveSeen', (data)=>{setMessages(data);})
    }

    useEffect( ()=>{
        loadMessages();
        setIsLoad(false);
        socket.on('disconnect', ()=>{
            setIsSocketDisconnected(true);
            setShowDisconnected(true);
            setShowConnected(false);
            dispatch(restoreAuthData());
        });
        socket.on("connect",()=>{
            setIsSocketDisconnected(false);
            setShowDisconnected(false);
            setShowConnected(true);
        })
        return ()=>{
             socket.off('gotAllRoomMsg', _setterMessage )
             socket.disconnect();
        }
    }, [socket] )

    const getIcon = (role,messageType) =>{
        if (role) {
          if ( messageType === 'right' ){
                return '/favicon.ico'
          }else{
                return '/student.png'
          }
        }else{
           if (messageType === 'right'){
               return '/student.png'
           }else{
               return '/favicon.ico'
           }
        }
    }

    return (
        <div className={classes.container}>
            <Snackbar open={showConnected} anchorOrigin={{ vertical:"top", horizontal: "center" }} autoHideDuration={1000} onClose={()=>setShowConnected(false)}>
                <Alert onClose={()=>setShowConnected(false)} severity="success" sx={{ width: '100%' }}>
                    Connected
                </Alert>
            </Snackbar>
            <Snackbar open={showDisconnected} anchorOrigin={{ vertical:"top", horizontal: "center" }} autoHideDuration={10000} onClose={()=>setShowDisconnected(false)}>
                <Alert onClose={()=>setShowDisconnected(false)} severity="error" sx={{ width: '100%' }}>
                    Перевірте інтернет з'єднання та оновіть сторінку
                </Alert>
            </Snackbar>
            <Paper className={classes.paper} zDepth={2}>
                <Box sx={{ width: '100%', height:'5px' }}>
                    {isLoad&&<LinearProgress />}
                </Box>
                <Paper id="style-1" className={classes.messagesBody}>
                    {messages.map(itVal=>{
                        console.log(itVal);
                        const item = itVal.dataValues??itVal;
                        const messageType = (item.authorSnapshot === user.user.displayName || item.authorSnapshot == `${user.user.firstName} ${user.user.lastName}`)?'right':'left';
                            const icon =  getIcon(user.user.roles, messageType);
                            return  <MessageRight
                                requestId={id}
                                messageId={item.id}
                                message={item.textMessage}
                                fileName={item.fileName}
                                file={item.file}
                                timestamp={item.createdAt}
                                photoURL={icon}
                                messageType={messageType}
                                displayName={item.authorSnapshot}
                                avatarDisp={true}
                                isViewByStudent={itVal.isViewByStudent}
                                isStudent={isStudent}
                                seterLoader={(val)=>{setIsLoad(val)}}
                            />
                        })}

                </Paper>
                <TextInput id={id} callback={()=>{}} seterLoader={setIsLoad} socket={socket} user={user} />
                <Box sx={{ width: '100%', height:'5px' }}>
                    {isLoad&&<LinearProgress />}
                </Box>
            </Paper>
        </div>
    );
}
