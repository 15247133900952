import React, { useEffect, useState,useCallback } from "react";
import GridTable from "./UI/base/GridTable/components/GridTable";
import { config } from "../config";
import { axiosRun } from "../utils/axiosWrap";
import CircularProgress from "@mui/material/CircularProgress";
import Box from '@mui/material/Box';
import {shallowEqual, useSelector} from "react-redux";


export const UsersList = () => {
    const [isLoad, setIsLoad] = useState(true);
    const [countries, setCountries] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnsForQuick, setColumnsForQuick] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [facultiesGroupsList, setFacultiesGroupsList] = useState([]);
    const [degreesList, setDegreesList ] = useState([]);
    const roles = useSelector((state) => state.users.user, shallowEqual).user.roles;

    useEffect(() => {

        const loadAllReq = async () => {
                const faculties = await axiosRun(
                    roles!=='ADMIN_GROUP_LEAD'?config.apiLinks.getFacultiesList:config.apiLinks.getFacultiesListGroupLead,
                    'GET'
                );
                setFaculties(faculties);

                const _facultiesGroupsList = await axiosRun(
                    roles!=='ADMIN_GROUP_LEAD'?config.apiLinks.getFacultiesgroupList:config.apiLinks.getFacultiesgroupListGroupLead,
                    'GET'
                );
                setFacultiesGroupsList(_facultiesGroupsList);

                const _degreesList = await axiosRun(config.apiLinks.getDegreesList, 'GET');
                setDegreesList(_degreesList.map(item => { return {...item, 'label':item.name, 'value': item.id} }));
                setIsLoad(false);
        };
        if (isLoad === true) {
                loadAllReq();
        }
    }, [isLoad])

    useEffect(() => {
        if (!isLoad && Object.keys(faculties).length > 0) {
            const columns = [
                { id: 'lastName', numeric: false, disablePadding: false, label: 'Прізвище', isLinkItem: true },
                { id: 'firstName', numeric: false, disablePadding: true, label: "Ім'я", isLinkItem: true },
                { id: 'middleName', numeric: false, disablePadding: false, label: 'По батькові', isLinkItem: true },
                { id: 'ipnNumber', numeric: true, disablePadding: false, label: "ІПН", isLinkItem: true },
                { id: 'importHash', numeric: true, disablePadding: false, label: 'ідентифікатор імпорту', isLinkItem: true }
            ];
            const columnsFormQuick = [
                { id: 'firstName', numeric: false, disablePadding: true, label: "Ім'я", isLinkItem: true, type: "text" },
                { id: 'firstNameEn', numeric: false, disablePadding: true, label: "Ім'я лат", isLinkItem: true, type: "text" },
                { id: 'lastName', numeric: false, disablePadding: false, label: 'Прізвище', type: "text" },
                { id: 'lastNameEn', numeric: false, disablePadding: false, label: 'Прізвище лат', type: "text" },
                { id: 'middleName', numeric: false, disablePadding: false, label: 'По батькові', type: "text" },
                { id: 'middleNameEn', numeric: false, disablePadding: false, label: 'По батькові лат', type: "text" },
                { id: 'email', numeric: true, disablePadding: false, label: "пошта", type: "text" },
                { id: 'ipnNumber', numeric: true, disablePadding: false, label: "ІПН", type: "text" },
                {
                    id: 'isApplicant', label: "Абітурієнт?", isRequired: true,
                    entityName: "isApplicant",
                    entityIdentify: "isApplicant",
                    displayType: "Select",
                    type: 'entityList',
                    entityList: [
                        { id: 0, name: "ні" },
                        { id: 1, name: "так" }
                    ]
                },
                { id: 'contactPhone', numeric: true, disablePadding: false, label: "телефон", type: "phone" },
                { id: 'birthday', numeric: true, disablePadding: false, label: "дата народження", type: "date" },
                {
                    id: 'id_PersonSex', label: "стать", isRequired: true, entityName: "id_PersonSex",
                    entityIdentify: "id_PersonSex",
                    displayType: "Select",
                    type: 'entityList',
                    entityList: [
                        { id: 1, name: "Чол" },
                        { id: 2, name: "Жін" }
                    ]
                },

                // {id: 'UserToFaculties',
                //     entityIdentify: "userToFaculties",
                //     label: 'Факультет-Спеціальність-Спеціалізація',
                //     isRequired: true,
                //     entityName: 'UserToFaculties',
                //     type: 'entityListManyValues',
                //     entityList: faculties
                // },
            ];
            setColumns(columns);
            setColumnsForQuick(columnsFormQuick)
        }
    }, [isLoad, countries, documents, faculties])

    const triggerLinks = {
        editLinkSave: config.apiLinks.addOrEditStudent,
        createLinkSave: config.apiLinks.addOrEditStudent,
        refreshLink: 'products',
        linkItem: '/administration/users/item/%itemId%',
        deleteLink: 'products/remove',
        redirectAfterAdd: '/administration/users/item/%itemId%'
    }
    console.log("roles ", roles);
    return <>
        {!isLoad && roles ? <GridTable
            linkGetData={roles!=='ADMIN_GROUP_LEAD'?config.apiLinks.getUserList:config.apiLinks.getUserListGroupLead}
            isLazy={true}
            columns={columns}
            triggerLinks={triggerLinks}
            columnsFormQuick={columnsForQuick}
            tableHeaderText={"Студенти"}
            rows4page={10}
            filtersList={[
                { name: 'firstName', label: "Ім`я", type: 'text' },
                { name: 'lastName', label: "Прізвище", type: 'text' },
                { name: 'middleName', label: 'По батькові', type: 'text' },
                { name: 'ipnNumber', label: 'IПН', type: 'text' },
                { name: '$UserToFaculties.Group.Degree.id$', label: 'Освітній рівень', type: 'select', default: -1, options: degreesList},
                { name: '$UserToFaculties.degreesId$', label: 'Освітній рівень(в базі без групи)', type: 'select', default: -1, options: degreesList},
                { name: '$UserToFaculties.isActive$', label: 'Чи активний', type: 'select', default: -1, options: [{value: -1,label: 'Не вибрано'},{value:0, label: 'Ні'},{value:1, label: 'Так'}] },
                { name: 'isApplicant', label: 'Абітурієнт?', type: 'select', default: -1, options: [{value: -1,label: 'Не вибрано'},{value:1, label: 'так'},{value:0, label: 'Ні'}] },
                { name: 'userSetPasswordHimself', label: 'зареєструвались', type: 'select', default: -1, options: [{value: -1,label: 'Не вибрано'},{value:0, label: 'Ні'},{value:1, label: 'Так'}]},
                { name: 'isSend', label: 'розіслано welcome email', type: 'select', default: -1, options: [{value: -1,label: 'Не вибрано'},{value:0, label: 'Ні'},{value:1, label: 'Так'}]},
                { name: "faculty_sp_spec_group", label: "Факультет/спеціальність/спеціалізація/група", type: 'autocomplete', options: facultiesGroupsList }
            ]}
        /> : <Box component="div" sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: '#ffffffab', zIndex: 9 }}>
            <CircularProgress color="success" size={70} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
        </Box>}
    </>

}