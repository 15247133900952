import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, FormControl, InputLabel, MenuItem, Box, Paper,Dialog,  Select, TextField, Typography, List, ListItem, ListItemText } from '@mui/material';
import {AddField} from './AddField'
import {RenderFields} from "./RenderFields";
import {useParams} from "react-router-dom";
import {axiosRun} from "../../utils/axiosWrap";
import {config} from "../../config";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {useNavigate} from "react-router-dom";

export const FieldsList = () => {
    const [fields, setFields] = useState([]);
    const [bypassName, setBypassName] = useState('');
    const [bypassFaculty, setBypassFaculty] = useState('');
    const [departmentList, setDepartmentList] = useState([]);
    const [facultyList, setFacultyList] = useState([]);
    const [open, setOpen] = useState(false);
    const [isLoad, setIsLoad] = useState(true);
    const [checked, setChecked] = useState([]);
    const [clickedForEdit, setClickedForEdit] = useState(null);
    const [validationError, setValidationError] = useState({});
    const {id} = useParams();
    const navigate = useNavigate();
    console.log("ID ID ID ", id);
    const conv = (itm,inx) => {
        itm.name = `${itm.fieldName} (${itm.Department.name})`
        return itm;
    };

    useEffect( ()=>{
        if (clickedForEdit!==null){
            setOpen(true);
        }
    }, [clickedForEdit] )

    useEffect(() => {
        // Make a GET request to retrieve the list of fields
        const fetchFields = async () => {
            try {
                if (id!='new'){
                    const data = await axiosRun( config.apiLinks.getBypassFields.replace("%id%", id), "GET" )
                    data?.BypassFields?.map(conv)
                    setFields(data?.BypassFields);
                    setBypassName(data?.name);
                    setBypassFaculty(data?.facultyId)
                    const _dList = await axiosRun(config.apiLinks.getDepartmentsList, 'GET');
                    setDepartmentList(_dList);
                }
                const _fList = await axiosRun(config.apiLinks.getFacultiesListClear, 'GET');
                setFacultyList(_fList);
                setIsLoad(false);
            } catch (error) {
                console.error('Error fetching fields:', error);
            }
        };
        fetchFields();
    }, [id]);

    const handleFieldAdded = (fields) => {
        setFields(fields?.BypassFields?.map(conv));
        setClickedForEdit(null);
    };

    const handleSaveBaseInfo = async ()=>{
        setIsLoad(true);
        try{
            const data = await axiosRun(config.apiLinks.saveBaseInfo, "POST", {faculty_id:bypassFaculty, name:bypassName, id });
            setValidationError({});
            setBypassName(data?.name);
            setBypassFaculty(data?.facultyId)
            if (id === 'new'){
                navigate(`/administration/bypass/templates/item/${data.id}`);
            }
        }catch (e){
            setValidationError(e.data.errors);
        }
        setIsLoad(false);

    }

    const removeField = async (fieldId) =>{
        setIsLoad(true);
       const data = await axiosRun(config.apiLinks.removeField, "POST", {fieldId:checked, id})
        setChecked([]);
        setFields(data?.BypassFields?.map(conv));
        setIsLoad(false);
    }

    return (
        <Paper >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoad}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box display={"div"} sx={{display:'flex', flexDirection: 'column'}}>
                <Typography sx={{pl: "5%", mt:"1%"}} variant="h4">Обхідний лист</Typography>
                <Box sx={{width: '40%', pl: '5%', mb: "2%"}}>
                    <Box display={"div"} sx={{display: 'flex', flexDirection: 'column', border: 1,
                        borderRadius: '2%',
                        mt: '3%',
                        pl: "2%",
                        borderColor: "gray",
                        pr: "2%",
                        pb: "3%"}}>
                        <Typography variant="h6">Назва і факультет</Typography>
                        <FormControl fullWidth>
                            <TextField
                                error={validationError['name']?true:false}
                                helperText={validationError['name']??''}
                                label="Bypass name"
                                value={bypassName}
                                onChange={(e) => setBypassName(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="faculty">{"Faculty"}</InputLabel>
                            <Select
                                error={validationError['faculty_id']?true:false}
                                helperText={validationError['faculty_id']??''}
                                labelId="faculty"
                                label={"Faculty"}
                                value={bypassFaculty}
                                onChange={(e) => setBypassFaculty(e.target.value)}
                                fullWidth
                                margin="normal"
                            >
                                <MenuItem value={0}>{"не вибрано"}</MenuItem>
                                {facultyList.map(itm =>{
                                    return <MenuItem value={itm.id}>{itm.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <Button sx={{mt: "2%", width: "30%"}} variant="contained" startIcon={<SaveIcon/>} onClick={()=>{
                            handleSaveBaseInfo();
                        }}>
                            Save
                        </Button>
                    </Box>

                    {id!='new'&&<Box display={"div"} sx={{pt: "10px", border: 1,
                        borderRadius: '2%',
                        mt: '3%',
                        pl: "2%",
                        borderColor: "gray",
                        pr: "2%",
                        pb: "3%", mt: "3%"}}>
                        <Typography variant="h6">Поля обхідного листа</Typography>
                        <Box display={"div"} sx={{display: 'flex', pt: "2%" ,flexDirection: 'row', gap: "5px" }}>
                            <Button variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={()=>{
                                setOpen(true);
                                setClickedForEdit(null);
                            }}>
                                Add Field
                            </Button>
                            <Button variant="contained" startIcon={<DeleteForeverIcon />} onClick={()=>{
                                removeField()
                            }} disabled={checked.length === 0} >
                                Remove selected
                            </Button>
                        </Box>
                        <RenderFields fields={fields} checked={checked} setChecked={setChecked} setClickedForEdit={setClickedForEdit} />
                    </Box>}
                    <Dialog open={open} onClose={()=>{
                        setOpen(false);
                        setClickedForEdit(null);
                    }}>
                        <DialogTitle>Add Field to bypass</DialogTitle>
                        <DialogContent>
                            <AddField
                                onFieldAdded={handleFieldAdded}
                                clickedForEdit={clickedForEdit}
                                fields={fields}
                                departmentList={departmentList}
                                open={open}
                                setOpen={setOpen}
                                id={id}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{setOpen(false); setClickedForEdit(null)}}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>
        </Paper>
    );
};
