import React, { useEffect, useState } from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import { axiosRun } from "../utils/axiosWrap";
import { config } from "../config";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  Container,
  Box,
  IconButton,
  Typography,
  List,
  Breadcrumbs,
  styled,
  ListItemText,
  ListItemIcon,
  ListItem,
  Toolbar,
  Badge,
  Divider,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { Outlet, Link, useLocation } from "react-router-dom";
import { Drawer } from "./UI/Drawer";
import { AppBar } from "./UI/AppBar";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SchoolIcon from '@mui/icons-material/School';
import { useTranslation } from "react-i18next";
import LinkIcon from '@mui/icons-material/Link';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const MainWrapper = () => {
  const user = useSelector(state=>state.users, shallowEqual);
  const showAllMenus = user.gotUserInfo&&!user.user.user?.isApplicant;
  console.log("show all menus ", user.user ,user.gotUserInfo, user.user.user?.isApplicant, showAllMenus );
  const [open, setOpen] = useState(window.innerWidth <= 768 ? false : true);
  const { t, i18n } = useTranslation();

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const location = useLocation();
  const MenuListItem = ({ linkTo, name, icon }) => (
    <ListItem
      selected={location.pathname === linkTo}
      component={Link}
      to={linkTo}
      button={true}
      sx={{
        "&.Mui-selected": {
          backgroundColor: "#F1FFF6",
        },
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );

    const theme = createTheme({
        status: {
            danger: "#fff",
        },
        components: {
            MuiListItem: {
                styleOverrides: {
                    button: {
                        color: "#212b36",
                    },

                },
            },
            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        fontSize: "14px",
                    },
                },
            },
        },
    });


    return (
      <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
              backgroundColor: "#00A43C",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                marginLeft: "-9px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              // color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {t("mainWrapMenu.HeaderProfile")}
            </Typography>
            {/*<BreadcrumbsAdmin location={location.pathname} />*/}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav" sx={{pt: 0}}>
            <MenuListItem
              linkTo={"/profile/info"}
              name={t("mainWrapMenu.Profile")}
              icon={<AccountBoxOutlinedIcon />}
            />
              {showAllMenus&&<>
              <MenuListItem
                linkTo={"/profile/requests"}
                name={t("mainWrapMenu.requests")}
                icon={<DashboardOutlinedIcon />}
              />
              <MenuListItem
                  linkTo={"/profile/request/chat/new"}
                  name={t("mainWrapMenu.create")}
                  icon={<DashboardOutlinedIcon />}
              />
              <MenuListItem
                  linkTo={"/profile/financial/study/reports"}
                  name={t("mainWrapMenu.studyReport")}
                  icon={<SchoolIcon />}
              />
              <MenuListItem
                  linkTo={"/profile/financial/rent/reports"}
                  name={t("mainWrapMenu.financialReport")}
                  icon={<AccountBalanceIcon />}
              />
              <MenuListItem
                  linkTo={"/profile/bypass/lists"}
                  name={t("mainWrapMenu.CheckList")}
                  icon={<FormatListBulletedIcon />}
              />
              </>}
              <MenuListItem
                  linkTo={"/profile/links"}
                  name={t("mainWrapMenu.Links")}
                  icon={<LinkIcon />}
              />
            <MenuListItem
              linkTo={"/logout/"}
              name={t("mainWrapMenu.Logout")}
              icon={<LogoutIcon />}
            />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            minWidth: '250px'
          }}
        >
          <Toolbar />
          <Container maxWidth="xl"  sx={{ mt: 4, mb: 4 }}>
            <Outlet />
          </Container>
        </Box>
      </Box>
      </ThemeProvider>
  );
};
