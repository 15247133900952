import React, { memo, useEffect, useState } from "react";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useStylesGridDialog } from "../../css/useStylesGridDialog";
import { Box } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {axiosRun} from "../../../../../../utils/axiosWrap";
import {config} from "../../../../../../config";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 18;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "200px",
      maxWidth: "300px",
    },
  },
};
const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        outlined: {
          maxWidth: "300px",
          whiteSpace: "break-spaces"
        },
      },
    },
  },
});

export const EntityListManyValues = ({
  specialLabel,
  entityName,
  values,
  entityList,
  valuesSetter,
  dialogField,
}) => {
  console.log("handle change value ", values);
  const currentValue = values
    ? values.map((itm) => {
        if (itm.spid) {
          return `${itm.fid}-${itm.sid}-${itm.spid}`;
        } else if (itm.fid) {
          return `${itm.fid}-${itm.sid}`;
        } else {
          return itm;
        }
      })
    : [];


  const currentGroupValue = {};
  values.forEach(itm =>{
     if (itm.spid){
       currentGroupValue[itm.spid] = itm.groupId;
     }else{
       currentGroupValue[itm.sid] = itm.groupId;
     }
  })

  console.log("handle change value current value ", currentValue);
  //valuesSetter(entityName,currentValue);
  const [valueSelected, setValueSelected] = useState(currentValue);
  const [groupsData, setGroupsData] = useState([]);
  const [currentGroups, setCurrentGroups] = useState(currentGroupValue);

  const [isLoadGroupsData,setIsLoadGroupsData] = useState(true);


  useEffect( ()=>{
    const load = async () => {
      const _groupsData = await axiosRun(
          config.apiLinks.loadGroupsList.replace("%data%",JSON.stringify(valueSelected)),
          'GET'
      );
      setGroupsData( _groupsData );
    }
    load();
    setIsLoadGroupsData(false);
  }, [valueSelected] );


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log("handle change value", value);
    setValueSelected(value);
    valuesSetter(entityName, value);
  };

  const classesDialog = useStylesGridDialog();
  return (
    <ThemeProvider theme={theme}>
      <Box
        component={"div"}
        sx={{ mb: 2, maxWidth: "100%", order: 10 }}
        className={!dialogField && classesDialog.formItem}
      >
        <FormControl sx={{ width: "100%" }}>
          <InputLabel
            id="demo-multiple-checkbox-label"
            sx={{ whiteSpace: "break-spaces" }}
          >
            {specialLabel}
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            label={specialLabel}
            multiple
            variant={"outlined"}
            value={valueSelected}
            onChange={handleChange}
            renderValue={(curValue) => {
              const rend = entityList.filter(
                (itef) => curValue.indexOf(itef.value) > -1
              );
              const r = rend.map((itrend) => itrend.name);
              return r.join(", ");
            }}
            MenuProps={MenuProps}
          >
            {entityList.map((itmF, inxF) => (
              <MenuItem
                key={inxF}
                value={itmF.value}
                sx={{ whiteSpace: "break-spaces" }}
              >
                <Checkbox checked={valueSelected.indexOf(itmF.value) > -1} />
                <ListItemText primary={itmF.name} />
              </MenuItem>
            ))}
          </Select>

          {/* {props.errorsFromServer[itemColumn.id]?(<FormHelperText>{props.errorsFromServer[itemColumn.id]}</FormHelperText>):false} */}
        </FormControl>

      </Box>
    </ThemeProvider>
  );
};
