import React, {useState} from 'react'
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';

import { alpha, makeStyles } from '@mui/styles';
import { Paper } from "@mui/material";
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import CircularProgress from "@mui/material/CircularProgress";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';

const useStyles = makeStyles((theme) => ({
        wrapForm : {
            display: "flex",
            justifyContent: "center",
            margin: '0 10px'
        },
        wrapText  : {
            width: "100%"  
        },
        fileBtn:{
            position: 'absolute',
            right: '45px',
            bottom: '2px',
            height: 'calc(100% - 4px)',
            border: '0 !important',
            maxHeight: '45px',
            maxWidth: '35px',
            minWidth: 'auto',
            backgroundColor: '#fff !important'
        },
        submitBtn:{
            position: 'absolute',
            bottom: '1px',
            right: '11px',
            maxHeight: '45px',
            height: 'calc(100% - 2px)',
            border: '0 !important',
            maxWidth: '35px',
            minWidth: 'auto',
            backgroundColor: '#fff !important',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px'
        }
    })
);
const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    padding-right:80px;
    border-radius: 5px;
    color: #000;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );



export const TextInput = (props) => {
    const {id, callback, user,socket,seterLoader} = props;

    const classes = useStyles();
    const [newMessage,setNewMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState([]);
    console.log("seter Loader ", props.seterLoader);
    const sendMessage = async ()=>{
        props.seterLoader(true);
        socket.emit("sendMsg",{requestId:id,textMessage:newMessage,authorSnapshot:user.user.login??user.user.email, selectedFile:selectedFile?.length>0?selectedFile:null, fileName: selectedFile?.length>0?selectedFile[0].name:''})
        setNewMessage('');
        setSelectedFile(null);
        //props.seterLoader(false);
    }

    const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedFile(files);
    };

    const handleFileRemove = (event) =>{
        setSelectedFile(null);
    }

    return (
        <>
            <Grid container direction={"column"} justifyContent={"center"}>
                <Grid item sx={{position:'relative'}}>
                    <form className={classes.wrapForm} onSubmit={e=>{e.preventDefault();sendMessage();}}  noValidate autoComplete="off">
                        <StyledTextarea aria-label="empty textarea"
                            id="standard-text"
                            placeholder="Напишіть повідомлення"
                            value={newMessage}
                            className={classes.wrapText}
                            onChange={e=>setNewMessage(e.target.value.replace(/[\r\n]/gm,''))}
                            onKeyPress={e=>{
                                if (e.key === 'Enter'){
                                    sendMessage();
                                }else {
                                    return true;
                                }
                            }}
                            size="small"
                            
                            //margin="normal"
                        />
                        <Button className={classes.fileBtn} variant="outlined" component="label">
                            <input hidden type='file' onClick={e=>{e.target.value = ''}} onChange={handleFileChange} />
                            <AttachFileIcon sx={{fontSize: 'xlarge'}} />
                        </Button>
                        <Button variant="outlined" color="primary" className={classes.submitBtn} onClick={sendMessage}>
                            <SendIcon />
                        </Button>
                    </form>
                </Grid>
                <Grid item>
                        {console.log("degbu debuig", selectedFile)}
                        {selectedFile?.length>0&&<Box sx={{display: 'flex', alignItems: 'center', justifyContent:'space-between'}}  m={2}>
                                <Box component='div' sx={{maxWidth: '75%',wordBreak: 'break-all'}}>{selectedFile[0]?.name}</Box>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size="small"

                                    onClick={() => handleFileRemove()}
                                    sx={{ml: 3}}
                                    title='Видалити'
                                >
                                    <DeleteIcon fontSize='small' />
                                </Button>
                            </Box>}
                    </Grid>
            </Grid>

            {/*<form className={classes.wrapForm}  noValidate autoComplete="off">*/}
            {/*    <Grid container direction="column" justifyContent="center" alignItems={"center"}>*/}
            {/*        <Grid item>*/}
            {/*            <Grid container sx={{width: '100%'}} direction={"row"} spacing={0} justifyContent={"space-between"}>*/}
            {/*                <Grid item>*/}
            {/*                    <TextField*/}
            {/*                        id="standard-text"*/}
            {/*                        label="напишіть повідомлення"*/}
            {/*                        value={newMessage}*/}
            {/*                        className={classes.wrapText}*/}
            {/*                        onChange={e=>setNewMessage(e.target.value)}*/}
            {/*                        //margin="normal"*/}
            {/*                    />*/}
            {/*                </Grid>*/}
            {/*                <Grid item>*/}
            {/*                    <Button variant="outlined" component="label">*/}
            {/*                        <input hidden type='file' onChange={handleFileChange} />*/}
            {/*                        <AttachFileIcon sx={{fontSize: 'large'}} />*/}
            {/*                    </Button>*/}
            {/*                </Grid>*/}
            {/*                <Grid item>*/}
            {/*                    <Button variant="contained" color="primary" className={classes.button} onClick={sendMessage}>*/}
            {/*                        <SendIcon />*/}
            {/*                    </Button>*/}
            {/*                </Grid>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}

            {/*        <Grid item>*/}
            {/*            <Grid container>*/}
            {/*                <Grid item>*/}
            {/*                    {selectedFile&&<Box mt={2}>*/}
            {/*                        <Box display="flex" alignItems="center"  mt={1}>*/}
            {/*                            <span>{selectedFile[0].name}</span>*/}
            {/*                            <Button*/}
            {/*                                variant="contained"*/}
            {/*                                color="error"*/}
            {/*                                size="small"*/}

            {/*                                onClick={() => handleFileRemove()}*/}
            {/*                                sx={{ml: 3}}*/}
            {/*                                title='Видалити'*/}
            {/*                            >*/}
            {/*                                <DeleteIcon fontSize='small' />*/}
            {/*                            </Button>*/}
            {/*                        </Box>*/}
            {/*                    </Box>}*/}
            {/*                </Grid>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</form>*/}
        </>
    )
}



