import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@mui/material';
import { axiosRun } from '../utils/axiosWrap';
import { config } from '../config';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import { useTranslation } from "react-i18next";
import {StatusRequest} from "./StatusRequest";

const RequestFormEdit = ({id,isStudent}) => {

    const [dataRequest, setDataRequest] = useState(null);
    const [isLoad, setIsLoad] = useState(true);
    const { t } = useTranslation();

    useEffect( ()=>{
        const fetchData = async () => {

            const data =  await axiosRun( config.apiLinks.getChatRequest.replace('%id%', id), 'GET');
            // Update state variables with fetched data
            console.log("data 1", data);
            setDataRequest(data);
        }
        fetchData();
    },[id])

    useEffect(()=>{
        if (dataRequest){
            setIsLoad(false);
        }
    }, [dataRequest])

    const handleSave = async (event)=>{
        event.preventDefault();
        // const result = await axiosRun( config.apiLinks.createNewRequest, "POST",{
        //     studentId:studentId.id,
        //     templateId:template.id,
        //     facultyId:selectedResourceStudents._facultyId,
        //     message,
        //     authorSnapshot: `${user.user.login}`
        // })
    }

 
    return (<>

    {isLoad&&<CircularProgress />}

            {!isLoad&&!isStudent&&<StatusRequest isStundent={isStudent} dataRequest={dataRequest} id={id} />}
            <List sx={{maxWidth:'650px'}}>
            <ListItem divider>
                <ListItemText
                    sx={{
                        width: {
                            xs: "40%",
                            md: "25%",
                        },
                        color: "primary.text",
                    }}
                    primary={t("profile.ID")}
                />
                <ListItemText
                    sx={{
                        width: {
                            xs: "60%",
                            md: "75%",
                        },
                        color: "primary.secondary_text",
                    }}
                    secondary={dataRequest?.id}
                />
            </ListItem>
                {!isStudent&&<ListItem divider>
                <ListItemText
                    sx={{
                        width: {
                            xs: "40%",
                            md: "25%",
                        },
                        color: "primary.text",
                    }}
                    primary={t("profile.FLMN")}
                />
                <ListItemText
                    sx={{
                        width: {
                            xs: "60%",
                            md: "75%",
                        },
                        color: "primary.secondary_text",
                    }}
                    secondary={`${dataRequest?.User?.firstName} ${dataRequest?.User?.lastName}`}
                />
            </ListItem>}
                {!isStudent&&<ListItem divider>
                <ListItemText
                    sx={{
                        width: {
                            xs: "40%",
                            md: "25%",
                        },
                        color: "primary.text",
                    }}
                    primary={t("profile.facultyName")}
                />
                <ListItemText
                    sx={{
                        width: {
                            xs: "60%",
                            md: "75%",
                        },
                        color: "primary.secondary_text",
                    }}
                    secondary={`${dataRequest?.template?.facultyTemplate?.name}`}
                />
            </ListItem>}
            {!isStudent&&<ListItem divider>
                <ListItemText
                    sx={{
                        width: {
                            xs: "40%",
                            md: "25%",
                        },
                        color: "primary.text",
                    }}
                    primary={t("profile.adminDescription")}
                />
                <ListItemText
                    sx={{
                        width: {
                            xs: "60%",
                            md: "75%",
                        },
                        color: "primary.secondary_text",
                    }}
                    secondary={`${dataRequest?.template?.adminDescription}`}
                />
            </ListItem>}
            <ListItem divider>
                <ListItemText
                    sx={{
                        width: {
                            xs: "40%",
                            md: "25%",
                        },
                        color: "primary.text",
                    }}
                    primary={t("profile.templateName")}
                />
                <ListItemText
                    sx={{
                        width: {
                            xs: "60%",
                            md: "75%",
                        },
                        color: "primary.secondary_text",
                    }}
                    secondary={`${dataRequest?.template?.name}`}
                />
            </ListItem>
                <ListItem divider>
                    <ListItemText
                        sx={{
                            width: {
                                xs: "40%",
                                md: "25%",
                            },
                            color: "primary.text",
                        }}
                        primary={t("profile.templateType")}
                    />
                    <ListItemText
                        sx={{
                            width: {
                                xs: "60%",
                                md: "75%",
                            },
                            color: "primary.secondary_text",
                        }}
                        secondary={`${dataRequest?.template?.type==='0'?t('profile.typeMessage'):t('profile.typeRequest')}`}
                    />
                </ListItem>
            <ListItem divider>
                <ListItemText
                    sx={{
                        width: {
                            xs: "40%",
                            md: "25%",
                        },
                        color: "primary.text",
                    }}
                    primary={t("profile.status")}
                />
                <ListItemText
                    sx={{
                        width: {
                            xs: "60%",
                            md: "75%",
                        },
                        color: "primary.secondary_text",
                    }}
                    secondary={!isLoad&&t(config.statusesRequest[dataRequest?.template?.type][dataRequest?.status])}
                />
            </ListItem>
        </List>

        </>
    )
}

export default RequestFormEdit;