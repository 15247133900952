import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {config} from "../config";
import {io} from "socket.io-client";

export const userLogin = createAsyncThunk( 'user/userLogin', async ( payload, {rejectWithValue} )=>{
        const {login,password,isAdmin,token} = payload;
        const response = await fetch(config.apiUrl + config.apiLinks.loginUser, {
            method: 'POST',
            body: JSON.stringify({login,password,isAdmin,token}),
            headers: {
                'Content-Type': 'application/json'
            }}
        );
         if (response.status !== 200){
             const value = await response.json();
             console.log("value when login failed", value);
             return rejectWithValue( value );
         }else{
             const value = await response.json();
             console.log("value when login not failed", value);
             return value;
         }
})

//****got user
const usersSlice = createSlice({
    name: "users",
    initialState: {
        user: {},
        status: 'idle',
        error: null,
        gotUserInfo: false
    },
    reducers: {
        logout: (state) =>{
            state.user = {};
            state.status = 'idle';
            state.gotUserInfo = true;
        },
        setRestoredAuth: (state, action) =>{
            const query = action.payload?.token!==undefined?{token:action.payload.token}:null;
            console.log("CONFIG SOCKET URL", config.socketUrl);
            const socket = action.payload?io(config.socketUrl,{
                'reconnectionDelay': 100,
                withCredentials: true,
                query
            }):{};
            console.log('socket 474747', socket);
            state.user = action.payload??{};
            state.socket = socket;
            state.status = 'idle';
            state.gotUserInfo = true;
        }
    },
    extraReducers: builder => {
        builder.addCase( userLogin.pending, (state,action) =>{
            state.status = 'loading'
        }).addCase( userLogin.fulfilled, (state,action) =>{
            const query = action.payload.token?{token:action.payload.token}:null;
            const socket = io(config.socketUrl,{
                reconnectionDelay: 10000,
                withCredentials: true,
                query
            });
            state.user = action.payload;
            state.socket = socket;
            state.status = 'idle';
            state.gotUserInfo = true;
            saveAuthData(action.payload);
        }).addCase(userLogin.rejected, (state,action)=>{
            console.log("reject ", action.payload);
            state.error = action.payload;
            state.gotUserInfo = true;
            state.user = {};
            state.status = 'rejected';
        })
    }
});


export const saveAuthData = (userInfo)=>{
    console.log("user Info ", userInfo);
    localStorage.setItem('userInfo',JSON.stringify(userInfo));
}



const { actions, reducer } = usersSlice;
export const {logout,setRestoredAuth} = actions;
export default reducer;

export const logoutUser = ()=>{
    return (dispatch) =>{
        localStorage.removeItem('userInfo');
        dispatch(logout());
    }
}

export const restoreAuthData = ()=>{
    return (dispatch) =>{
        const userItem = JSON.parse(localStorage.getItem('userInfo'));

        dispatch(setRestoredAuth(userItem));
    }
}