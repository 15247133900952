export const getPropByString = (obj, propString)=>{
    const id = propString.entityIdentify!==undefined?propString.entityIdentify:propString.id
    if (!id)
        return obj;
    var prop, props = id.split('.');
    var data = null;
    if ( props.length === 1 ){
        data = obj[id];
    }else{
        for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
            prop = props[i];
            let candidate = obj[prop];
            if (candidate !== undefined) {
                obj = candidate;
            } else {
                break;
            }
        }
        console.log("", obj, props, i);
        data = obj?obj[props[i]]:'';
    }
    if (propString.type==='entityList' && propString.trigger===undefined) {
        console.log("prop type ",propString.entityList,data);
        if ( data ){
            data = propString.entityList[data]['name'];
        }
    }
    if (propString.type === 'bool'){
        if (propString.reverse){
            data = data===true?"Ні":"Так";
        }else{
            data = data===true?"Так":"Hі";
        }
    }

    if ( propString.id === 'rolesAdmin' )  {
        console.log("propString.entityIdentify ", propString.id,obj[propString.id], data);
    }
    return  data;
}
